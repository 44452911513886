import { BOOKING_COMMON_FORM_TEXT } from 'pages/Booking/constants';
import { BookingCommonFormType } from 'pages/Booking/types';
import { useFormContext } from 'react-hook-form';
import ApiBoundary from 'sharedComponents/Boundaries/ApiBoundary';
import FormFieldLinkButton from 'sharedComponents/FormField/FormFieldLinkButton';

import CheckRoomUsable from './CheckRoomUsable';
import RoomName from './RoomName';

const Room = () => {
  const { getValues } = useFormContext<BookingCommonFormType>();
  const hasValue = !!getValues('selectedRoom') && getValues('selectedRoom.id') > 0;

  return (
    <ApiBoundary
      fallback={
        <FormFieldLinkButton iconName="room" hasValue showArrow ellipsis={1}>
          {hasValue ? (
            <RoomName label={getValues('selectedRoom.name')} roomId={getValues('roomId')} showRoomText />
          ) : (
            BOOKING_COMMON_FORM_TEXT.room.notSelected.label
          )}
        </FormFieldLinkButton>
      }>
      <CheckRoomUsable />
    </ApiBoundary>
  );
};

export default Room;
