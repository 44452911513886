import { PERMISSION } from 'constants/permission';
import dayjs from 'dayjs';

/** 00:00 ~ 23:30 */
export const DEFAULT_SETTING_TIMES: [Date, Date] = [
  dayjs().set('hour', 0).set('minute', 0).toDate(),
  dayjs().set('hour', 23).set('minute', 30).toDate(),
];

export const permissionsMap = {
  group: PERMISSION.lecture.others.view.group,
  private: PERMISSION.lecture.others.view.private,
  counsel: PERMISSION.counsel.view,
} as const;
