import { BookingFailResponse } from 'hooks/service/mutations/usePostBookingPrivate';

import { BOOKING_ERROR_DATA } from '../constants';

export type FailItemType = {
  code: string[];
  title: string;
  id: number;
  room: string | null;
  staff: string;
  start_on: string;
  end_on: string;
};

export const formatFailList = (fails: Array<BookingFailResponse>) => {
  const result = Object.values(
    fails.reduce((acc: { [key: number]: FailItemType }, curr) => {
      if (!acc[curr.id]) {
        // 처음 등장하는 id인 경우 기본 구조로 초기화
        acc[curr.id] = {
          id: curr.id,
          room: curr.room,
          staff: curr.staff,
          title: curr.title,
          start_on: curr.start_on,
          end_on: curr.end_on,
          code: [curr.code],
        };
      } else {
        // 이미 존재하는 id인 경우 code를 배열에 추가하고 중복 제거
        acc[curr.id].code = [...new Set([...acc[curr.id].code, curr.code])];
      }
      return acc;
    }, {}),
  ).sort((a, b) => new Date(a.start_on).getTime() - new Date(b.start_on).getTime());
  return result;
};

export const getBookingErrorString = (codeArray: FailItemType['code']) => {
  // 강사중복, 일정중복 등 중복 에러코드
  const duplicateCodes = ['10016', '10017', '10901', '10902', '10904', '13001'];
  const duplicateCodesSet = new Set(duplicateCodes);

  const resultArray = codeArray.filter(code => BOOKING_ERROR_DATA[code]).map(code => BOOKING_ERROR_DATA[code]);
  const duplicateReasons = resultArray.filter((reason, index) => duplicateCodesSet.has(codeArray[index]));

  // 중복 코드가 1개인 경우, 바로 '중복'을 붙임
  if (duplicateReasons.length === 1) {
    const nonDuplicateReasons = resultArray.filter(reason => !duplicateReasons.includes(reason));
    return [...nonDuplicateReasons, `${duplicateReasons[0]}중복`].join(', ');
  }

  // 중복 코드가 2개 이상인 경우, 마지막에 '중복' 추가
  if (duplicateReasons.length > 1) {
    const uniqueDuplicateReasons = Array.from(new Set(duplicateReasons));
    const nonDuplicateReasons = resultArray.filter(reason => !duplicateReasons.includes(reason));
    return [...nonDuplicateReasons, `${uniqueDuplicateReasons.join(', ')}중복`].join(', ');
  }

  // 중복 코드가 없을 경우 단순히 나열
  return resultArray.join(', ');
};
