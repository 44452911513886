import { ComponentProps } from 'react';
import filters from 'utils/filters';
import getExpiredAtByAvailablePeriod from 'utils/getExpiredAtByAvailablePeriod';

import ProductCreateUpdateForm from '../components/ProductCreateUpdateForm';
import { SelectTicketFormType } from '../types';

export type CreateTicketDefaultValueType = {
  ticket: Exclude<SelectTicketFormType['selectedTicket'], null>;
  currentMemberPoint: number;
};

type ResponseType = ComponentProps<typeof ProductCreateUpdateForm>['ticketWithCurrentPoint'];

const getCreateTicketDefaultValues = ({ ticket, currentMemberPoint }: CreateTicketDefaultValueType): ResponseType => {
  const { max_coupon, max_cancel, available_period, type } = ticket;

  let expiredAt = '';
  switch (type) {
    case 'S':
    case 'RT':
      expiredAt = '2099-12-31';
      break;
    default:
      expiredAt = getExpiredAtByAvailablePeriod(available_period);
  }

  return {
    ...ticket,
    max_coupon: max_coupon ?? 999,
    remaining_coupon: max_coupon ?? 999,
    remaining_cancel: max_cancel ?? 999,
    availability_start_at: filters.dateDash(),
    expire_at: expiredAt,
    currentMemberPoint,
  };
};

export default getCreateTicketDefaultValues;
