import dayjs from 'dayjs';

import getDaysDiff from './getDaysDiff';

/**
 * 잔여일 문구 생성
 * @return 오늘만료, N일 지남/기간 만료, N일 남음
 */
const getRemainingDaysText = (expire_on: string, start_on: string, expiredType = 'default') => {
  const start = dayjs(start_on).format('YYYYMMDD') <= dayjs().format('YYYYMMDD') ? dayjs() : dayjs(start_on).subtract(1, 'd');
  const diff = getDaysDiff(expire_on, start);
  let expiredText = `${(diff * -1).toLocaleString()}일 지남`;
  switch (expiredType) {
    case 'expired':
      expiredText = '기간 만료';
      break;
  }

  return diff === 0 ? '오늘 만료' : diff < 0 ? expiredText : `${diff.toLocaleString()}일 남음`;
};

export default getRemainingDaysText;
