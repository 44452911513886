import styled from '@emotion/styled';
import { useIsFetching } from '@tanstack/react-query';
import Button from 'components/Button';
import Drawer from 'designedComponents/Drawers';
import { ReactNode, useState } from 'react';
import ApiBoundary from 'sharedComponents/Boundaries/ApiBoundary';
import FullScreenLoading from 'sharedComponents/FullScreenLoading';

type Props = {
  children: ReactNode;
};

const TargetsDetailButton = ({ children }: Props) => {
  const [isFetchNeeded, setIsFetchNeeded] = useState(false); // drawer가 열리지도 않았는데 api 요청이 되는 경우 방지
  const [isDrawerOpened, setIsDrawerOpened] = useState(false);

  const openDrawer = () => {
    setIsFetchNeeded(true);
    setIsDrawerOpened(true);
  };

  const closeDrawer = () => {
    setIsDrawerOpened(false);
  };

  const isFetching = useIsFetching() > 0;
  return (
    <>
      <DetailButton textColor="primary" fontSize={12} fontWeight={500} onClick={openDrawer}>
        상세보기
      </DetailButton>
      {isFetchNeeded && (
        <ApiBoundary fallback={<FullScreenLoading loop={isFetching} />}>
          <Drawer isOpen={isDrawerOpened} onClose={closeDrawer}>
            {children}
          </Drawer>
        </ApiBoundary>
      )}
    </>
  );
};

export default TargetsDetailButton;

const DetailButton = styled(Button)`
  padding-top: 6px;
  span {
    text-decoration: underline;
    letter-spacing: -0.25px;
  }
`;
