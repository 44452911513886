import dayjs from 'dayjs';
import { BookingHistoryResponse } from 'hooks/service/queries/useInfinityHistory';
import { ArrayWholeType, BookingInfoType } from 'pages/MemberDetail/types';
import filters from 'utils/filters';

/**
 * 배열로 들어오는 리스트 데이터들 날짜 별로 분리하여 그룹화 하는 함수
 */
const splitUserTicketHistoryByDate = (data: BookingHistoryResponse[]) => {
  if (!data) return [];

  const arrWhole: ArrayWholeType = [];
  let arrayElement: BookingInfoType[] = [];
  let currentDate: string;

  data.forEach(history => {
    const {
      id: bookingId,
      status,
      lecture: {
        id: lectureId,
        title,
        type,
        start_on,
        deleted_at,
        staff: { id, name, staff_avatar },
      },
      updated_at,
      user_ticket: { ticket },
    } = history;

    const groupingDate = history.lecture ? start_on : updated_at;
    const date = dayjs(groupingDate).format('M월 D일 ddd요일');

    if (currentDate !== date) {
      currentDate = date;
      arrayElement = [];
      const dateElement = { date, arrayElement };
      arrWhole.push(dateElement);
    }

    const lecture = {
      bookingId,
      lectureId,
      title,
      status,
      staff: { id, name, avatar: staff_avatar },
      userTicketTitle: ticket.title,
      classType: type,
      classStartTime: filters.dateTimeWithWeekday(start_on),
      bookingCompletedTime: filters.dateTimeWithWeekday(updated_at),
      waitBookingCount: 1,
      isDeletedLecture: !!deleted_at,
    };
    arrayElement.push(lecture);
  });

  return arrWhole;
};

export default splitUserTicketHistoryByDate;
