import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import Form from 'components/Form';
import { PRODUCT_TYPE_TEXT } from 'constants/text';
import usePatchUserTicketUpdate, { UserTicketUpdateParams } from 'hooks/service/mutations/usePatchUserTicketUpdate';
import { UserTicketResponse } from 'hooks/service/queries/useInfinityUserTicketProduct';
import useGetMinHeight from 'hooks/useGetMinHeight';
import useToast from 'hooks/useToast';
import { TicketEditFormType, TicketEditHasBookingsErrorType } from 'pages/TicketDetail/types';
import { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { isKeyboardShowAtom } from 'recoil/keyboard';
import { SelectStaffs, ShareTicketSwitch, TicketCountForm, TicketPeriodForm } from 'sharedComponents/TicketForm';

import ConfirmBookingCancelDrawer from './ConfirmBookingCancelDrawer';
import SubmitButton from './SubmitButton';

type Props = {
  userTicket: UserTicketResponse;
};

const TicketEditForm = ({ userTicket }: Props) => {
  const {
    id,
    is_show_cancel_count,
    lower_limit_max_coupon,
    lower_limit_remaining_coupon,
    ticket: { type, available_class_type },
  } = userTicket;
  const [includedBookings, setIncludedBookings] = useState<TicketEditHasBookingsErrorType[] | null>(null);
  const fullHeight = useGetMinHeight();
  const minHeight = fullHeight - 56 - 86 - 8 + 40; // 전체 - form 상단 영역 + 푸터버튼과 폼 여백
  const isKeyboardShow = useRecoilValue(isKeyboardShowAtom);
  const { handleSubmit } = useFormContext<TicketEditFormType>();
  const { setToast } = useToast();
  const navigate = useNavigate();
  const { mutate: updateTicketMutate } = usePatchUserTicketUpdate();

  const formatParams = (values: TicketEditFormType) => {
    const {
      detail: { staffs: staffsState, tempStaffs, ...rest },
      count,
      period,
    } = values;

    return {
      ...rest,
      ...count,
      ...period,
      staffs: staffsState.map(({ id }) => id),
      user_ticket_id: id,
    };
  };

  const updateTicket = (params: UserTicketUpdateParams, is_force?: boolean) => {
    updateTicketMutate(
      { user_tickets: [params], is_force },
      {
        onSuccess: () => {
          setToast({
            type: 'success',
            message: `${PRODUCT_TYPE_TEXT[available_class_type]} 정보가 수정되었습니다.`,
          });
          navigate(-1);
        },
        onError: error => {
          if (!error.response?.data) return;
          const errorData = error.response.data;
          const noPermission = error.response?.data?.code === 35001;
          if (noPermission) {
            return false;
          }
          if (errorData.bookings) {
            setIncludedBookings(errorData.bookings);
            return false;
          }
        },
      },
    );
  };

  const submit = (values: TicketEditFormType) => {
    const params = formatParams(values);
    updateTicket(params);
  };

  const clickPassBooking = (values: TicketEditFormType) => {
    const params = formatParams(values);
    updateTicket(params, true);
  };

  return (
    <>
      <StyleForm onSubmit={handleSubmit(submit)} isKeyboardShow={isKeyboardShow} minHeight={minHeight}>
        <TicketPeriodForm ticketType={type} />
        <TicketCountForm
          ticketType={type}
          isShowCancelCount={!!is_show_cancel_count}
          minMaxCoupon={lower_limit_max_coupon}
          minRemainingCoupon={lower_limit_remaining_coupon}
        />
        {available_class_type !== 'I' && <ShareTicketSwitch />}
        <SelectStaffs />
        <SubmitButton />
      </StyleForm>
      <ConfirmBookingCancelDrawer
        isOpen={!!includedBookings}
        onClose={() => setIncludedBookings(null)}
        includedBookings={includedBookings ?? []}
        onSubmit={clickPassBooking}
      />
    </>
  );
};

export default TicketEditForm;

const StyleForm = styled(Form)<{ isKeyboardShow: boolean; minHeight: number }>`
  ${theme.flex('column', '', '')};
  ${({ minHeight }) => minHeight && `min-height: ${minHeight}px;`}
  padding-bottom: ${({ isKeyboardShow }) => (isKeyboardShow ? '0px' : '116px')};

  footer {
    margin-top: ${({ isKeyboardShow }) => (isKeyboardShow ? 'auto' : 0)};
  }
`;
