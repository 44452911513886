import useGetRoom from 'hooks/service/queries/useGetRoom';
import useGetStudioInfo from 'hooks/service/queries/useGetStudioInfo';
import { BOOKING_COMMON_FORM_TEXT } from 'pages/Booking/constants';
import { BookingCommonFormType } from 'pages/Booking/types';
import { convertRepeatWeekdayNum } from 'pages/Booking/utils';
import { getWeekdayNumbers } from 'pages/Booking/utils/formatLectureCommonParams';
import { useEffect, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { useLocation } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { studioIdAtom } from 'recoil/common';
import FormFieldLinkButton from 'sharedComponents/FormField/FormFieldLinkButton';
import filters from 'utils/filters';

import RoomSetting from './RoomSetting';

/** 룸 설정 기능을 사용하는 시설인지 아닌지 판단 */
const CheckRoomUsable = () => {
  const { setValue, getValues, watch } = useFormContext<BookingCommonFormType>();
  const currentStudioId = useRecoilValue(studioIdAtom);
  const { pathname } = useLocation();

  const {
    data: { policy },
  } = useGetStudioInfo(currentStudioId);
  const { startDate, startTime, endDate, endTime, repeat, repeatCustomWeekday } = watch();

  const isEnabled = !!policy.is_use_rooms;
  /**
   * 생성 폼에서 기간선택한 수업이거나 생성폼 아닌 경우 모든 룸 목록 노출
   * (생성 폼에서 하루짜리 수업인 경우에만 선택 가능한 룸목록 반환됨)
   */
  const isCreateForm = pathname.includes('create'); // 수업 생성 폼
  const isRequestAllRoomList = !isCreateForm || (isCreateForm && startDate !== endDate);
  const lectureType = pathname.includes('private') ? 'P' : 'G';

  // 수업 생성 때 보내는 room 목록 파라미터
  const createRoomParams = useMemo(() => {
    if (isRequestAllRoomList) return {};

    const selectedWeekday = convertRepeatWeekdayNum(repeat, repeatCustomWeekday);
    const weekday =
      startDate === endDate ? undefined : selectedWeekday.length ? selectedWeekday : getWeekdayNumbers(startDate, endDate);
    return {
      start_date: startDate,
      end_date: endDate,
      start_time: filters.timeSecondZero(startTime),
      end_time: filters.timeSecondZero(endTime),
      weekday,
    };
  }, [endDate, endTime, isRequestAllRoomList, repeat, repeatCustomWeekday, startDate, startTime]);

  // 모든 필수 파라미터가 있는지 확인
  const hasRequiredParams = useMemo(() => {
    if (isRequestAllRoomList) return true;
    return Boolean(startDate && endDate && startTime && endTime);
  }, [isRequestAllRoomList, startDate, endDate, startTime, endTime]);

  const { data } = useGetRoom({
    isEnabled: isEnabled && hasRequiredParams,
    ...createRoomParams,
    type: lectureType,
    is_update: isRequestAllRoomList ? 1 : 0,
  });

  useEffect(() => {
    if (!isEnabled) {
      setValue('selectedRoom', { id: -1, name: BOOKING_COMMON_FORM_TEXT.room.notSelected.label });
    }
  }, [isEnabled, setValue, getValues]);

  /**
   * 생성 폼에서 수업일을 기간으로 선택하여 모든 룸 목록 반환되어 특정 룸 선택했는데,
   * 하루짜리로 수업 날짜를 변경하여 선택한 룸이 룸목록으로 반환되지 않을 때, 룸 선택 안함으로 세팅
   */
  useEffect(() => {
    const hasSelectedUnselectableItem = !!data && !data.some(room => room.id === getValues('selectedRoom.id'));
    if (isCreateForm && hasSelectedUnselectableItem) {
      setValue('roomId', BOOKING_COMMON_FORM_TEXT.room.notSelected.value);
      setValue('selectedRoom', undefined);
    }
  }, [data, getValues, isCreateForm, setValue]);

  if (!isEnabled || !data) {
    return (
      <FormFieldLinkButton iconName="room" disabled showArrow={false}>
        {BOOKING_COMMON_FORM_TEXT.room.noSetting}
      </FormFieldLinkButton>
    );
  }
  return <RoomSetting roomData={data} isUseRooms={isEnabled} />;
};

export default CheckRoomUsable;
