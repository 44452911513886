import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import Skeleton from 'components/Skeleton';

const ScheduleSkeleton = () => {
  return (
    <ContentSection>
      {Array.from({ length: 3 }).map((_, groupIndex) => (
        <ListContainer key={groupIndex}>
          <Skeleton type="round" width={100} height={16} className="date-text" />
          <CardContainer>
            {Array.from({ length: 2 }).map((_, scheduleIndex) => (
              <CardWrapper key={scheduleIndex}>
                <Card>
                  <div className="class-info">
                    <div className="class-info-lecture">
                      <Skeleton width={118} height={24} className="skeleton-text" />
                      <Skeleton width={173} height={24} className="skeleton-subtext" />
                    </div>
                    <div className="class-info-staff">
                      <Skeleton width={58} height={14} className="skeleton-text" />
                    </div>
                  </div>
                </Card>
                {Array.from({ length: Math.random() * 3 }).map((_, bookingIndex) => (
                  <Card key={bookingIndex}>
                    <button>
                      <div className="booked-person">
                        <div className="person-info">
                          <Skeleton width={100} height={14} className="skeleton-text" />
                          <Skeleton width={80} height={12} className="skeleton-subtext" />
                        </div>
                        <Skeleton width={57} height={20} className="skeleton-label" />
                      </div>
                    </button>
                  </Card>
                ))}
              </CardWrapper>
            ))}
          </CardContainer>
        </ListContainer>
      ))}
    </ContentSection>
  );
};

export default ScheduleSkeleton;

const ContentSection = styled.section`
  padding: 24px 20px;
  background-color: ${theme.color.gray6};
`;

const ListContainer = styled.div`
  .date-text {
    margin-bottom: 20px;

    :not(:first-of-type) {
      margin-top: 48px;
    }
  }
`;

const CardContainer = styled.div`
  ${theme.flex('column', '', '', 12)};
`;

const CardWrapper = styled.div`
  padding: 18px 20px;
  border-radius: 16px;
  background-color: ${theme.color.white};
  box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.08);

  > div {
    &:last-of-type {
      button {
        padding-top: 14px;
        padding-bottom: 0px;
      }
    }
  }
`;

const Card = styled.div`
  button {
    width: 100%;
    padding: 14px 0px;
  }

  .class-info {
    padding-bottom: 16px;

    &-lecture {
      ${theme.flex('row', 'center', '', 4)}
      padding-bottom: 2px;
    }

    &-staff {
      ${theme.flex('', '', '', 2)}
    }
  }

  .booked-person {
    ${theme.flex('', 'center', 'space-between', 8)}

    .person-info {
      text-align: left;
    }
  }

  &:not(:last-child) {
    border-bottom: 1px solid ${theme.color.gray7};
  }
`;
