import FloatingActionButton, { ActionsType } from 'components/FloatingActionButton';
import { PERMISSION } from 'constants/permission';
import useGetPermissionDoHavePermission from 'hooks/service/queries/useGetPermissionDoHavePermission';
import usePermission from 'hooks/usePermission';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { currentDateAtom } from 'recoil/schedule';
import { PermissionType } from 'types/permissionTypes';

const ScheduleFloatingButton = () => {
  const navigate = useNavigate();
  const { hasPermission } = usePermission();
  const { checkPermission } = useGetPermissionDoHavePermission();

  const currentDate = useRecoilValue(currentDateAtom);

  const goLink = (link: string) => {
    navigate(link, { state: { from: 'floating', selectedDate: currentDate } });
  };

  const { etcSchedule, counsel, lecture } = PERMISSION;

  const actions: Array<ActionsType & { permissions: PermissionType[] }> = [
    {
      label: '기타 일정',
      icon: 'calendar',
      onClick: () => {
        const onSuccess = () => goLink('/schedule/etc/form/create');
        checkPermission(etcSchedule.mine.create.id, {
          onSuccess,
        });
      },
      permissions: [etcSchedule.mine.create],
    },
    {
      label: '상담',
      icon: 'consulting',
      onClick: () => {
        checkPermission(counsel.create.id, {
          onSuccess: () => goLink('/counsel/form'),
        });
      },
      permissions: [counsel.create],
    },
    {
      label: '그룹 수업',
      icon: 'groupClass',
      onClick: () => {
        const onSuccess = () => goLink('/booking/group/form/create');
        checkPermission(lecture.mine.create.group.id, {
          onSuccess,
          onError: () => {
            checkPermission(lecture.others.create.group.id, {
              onSuccess,
            });
            return true;
          },
        });
      },
      permissions: [lecture.mine.create.group, lecture.others.create.group],
    },
    {
      label: '프라이빗 수업',
      icon: 'privateClass',
      onClick: () => {
        const onSuccess = () => goLink('/booking/private/form/create');
        checkPermission(lecture.mine.create.private.id, {
          onSuccess,
          onError: () => {
            checkPermission(lecture.others.create.private.id, {
              onSuccess,
            });
            return true;
          },
        });
      },
      permissions: [lecture.mine.create.private, lecture.others.create.private],
    },
  ];

  const allowedActions = actions.filter(action => {
    return action.permissions.some(permission => hasPermission(permission));
  });

  if (!allowedActions.length) {
    return null;
  }
  return <FloatingActionButton actions={allowedActions} />;
};

export default ScheduleFloatingButton;
