import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import Button from 'components/Button';
import dayjs from 'dayjs';
import { UserTicketResponse } from 'hooks/service/queries/useInfinityUserTicketProduct';
import { useMemo } from 'react';
import { InfoFlexRow, InfoTypography } from 'sharedComponents/InfoTypography';
import UserTicketCard from 'sharedComponents/UserTicketCard';
import filters from 'utils/filters';
import formatUserTicketCard from 'utils/formatUserTicketCard';

type Props = {
  userTicket: UserTicketResponse;
  goToHoldingPage: () => void;
};

const MainTopInfo = ({ userTicket, goToHoldingPage }: Props) => {
  const ticket = formatUserTicketCard(userTicket);

  const { ticketStatus } = ticket;

  const additionalInfo = useMemo(() => {
    const { expire_at, created_at, updated_at, inactive_reason, remaining_coupon } = userTicket;

    const expiredAt = !remaining_coupon ? updated_at : dayjs(expire_at).add(1, 'day');

    switch (ticketStatus) {
      case 'expired':
        return {
          label: '이용만료일시',
          value: filters.dateTimeWithWeekday(expiredAt),
        };
      case 'refund':
        return {
          label: '환불일시',
          value: filters.dateTimeWithWeekday(updated_at),
        };
      case 'sell':
        return {
          label: '판매일시',
          value: filters.dateTimeWithWeekday(created_at),
        };
      case 'holding':
        return {
          label: '정지기간',
          value: filters.period(userTicket?.holdings?.start_on, userTicket?.holdings?.end_on),
        };
      case 'not_usable':
        return {
          label: '사용불가 사유',
          value: inactive_reason,
        };
      default:
        return null;
    }
  }, [ticketStatus, userTicket]);

  return (
    <Container>
      <UserTicketCard ticket={ticket} />
      {additionalInfo && (
        <DateWrapper>
          <InfoFlexRow>
            <InfoTypography size={14}>{additionalInfo.label}</InfoTypography>
            <InfoTypography size={14} weight={500}>
              {additionalInfo.value}
            </InfoTypography>
          </InfoFlexRow>
          {ticketStatus === 'holding' && (
            <Button textUnderline textColor="primary" fontWeight={500} onClick={goToHoldingPage}>
              기간변경
            </Button>
          )}
        </DateWrapper>
      )}
    </Container>
  );
};

export default MainTopInfo;

const Container = styled.div`
  padding: 16px 20px 24px;

  .user-ticket-card {
    margin: 0 auto;
  }
`;

const DateWrapper = styled.div`
  margin-top: 32px;
  padding: 19px 16px;
  background-color: ${theme.color.gray7};
  border-radius: 12px;

  > div {
    align-items: flex-start;
  }

  .text-button {
    margin-top: 4px;
    margin-left: auto;
  }
`;
