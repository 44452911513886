import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import dayjs from 'dayjs';
import useInfinityReportPoint, { ReportPointParams } from 'hooks/service/queries/useInfinityReportPoint';
import useScrollRestoration from 'hooks/useScrollRestoration';
import { useErrorBoundary } from 'react-error-boundary';
import { useRecoilValue } from 'recoil';
import { reportPointParamAtom, reportPointPeriodAtom } from 'recoil/report/atom';
import CenterLineLoading from 'sharedComponents/CenterLineLoading';
import InfiniteScroll from 'sharedComponents/InfiniteScroll';
import filters from 'utils/filters';

import NoData from '../NoData';
import PointListCard from './PointListCard';
import ReportPointFilterOptions from './ReportPointFilterOptions';

const ReportPointList = () => {
  useScrollRestoration();
  const reportPointPeriod = useRecoilValue(reportPointPeriodAtom);
  const reportPoint = useRecoilValue(reportPointParamAtom);
  const params: ReportPointParams = {
    start_date: reportPointPeriod.period?.start || filters.dateDash(dayjs().startOf('month')),
    end_date: reportPointPeriod.period?.end || filters.dateDash(dayjs().endOf('month')),
    staff_ids:
      reportPoint.staffs && reportPoint.staffs.some(item => item.name === 'all')
        ? []
        : reportPoint.staffs?.map(item => Number(item.id)) ?? [],
    types: reportPoint.type,
    status: reportPoint.status,
    paginate_type: 'detail',
    order_by: reportPoint.sort,
  };
  const { showBoundary } = useErrorBoundary();

  const {
    data = { points: [], total: 0 },
    hasNextPage,
    isFetchingNextPage,
    fetchNextPage,
    isLoading,
    isError,
    error,
  } = useInfinityReportPoint(params);

  if (isError) {
    showBoundary(error);
  }

  return (
    <Container>
      <ReportPointFilterOptions total={data.total} />
      {isLoading ? (
        <CenterLineLoading />
      ) : data.total === 0 ? (
        <NoData noDataText="포인트 내역이 없습니다." />
      ) : (
        <InfiniteScroll loadMore={fetchNextPage} hasMore={hasNextPage} isLoading={isFetchingNextPage}>
          <PointListCard points={data.points} />
        </InfiniteScroll>
      )}
    </Container>
  );
};

export default ReportPointList;

const Container = styled.div`
  background-color: ${theme.color.gray6};
`;
