import styled from '@emotion/styled';
import { LectureDetailResponse } from 'hooks/service/queries/useGetLectureDetail';
import useQueryString from 'hooks/useQueryString';
import BookingRangeDate from 'pages/Booking/components/BookingRangeDate';
import BookingRangeTime from 'pages/Booking/components/BookingRangeTime';
import ClassTitle from 'pages/Booking/components/ClassTitle';
import RepeatField from 'pages/Booking/components/Repeat/RepeatField';
import ResetConfirmDialog from 'pages/Booking/components/ResetConfirmDialog';
import Room from 'pages/Booking/components/Room';
import ConfirmNoRoomDialog from 'pages/Booking/components/Room/ConfirmNoRoomDialog';
import SelectStaff from 'pages/Booking/components/SelectStaff';
import { BOOKING_COMMON_FORM_TEXT } from 'pages/Booking/constants';
import { AllPageModeType } from 'pages/Booking/types';
import { getPrivateTitle } from 'pages/Booking/utils/private/booking';
import { useState } from 'react';
import { useFormContext } from 'react-hook-form';

import { BOOKING_PRIVATE_FORM_TEXT } from '../../constants';
import { BookingPrivateFormType } from '../../types';
import LectureSize from '../components/LectureSize';
import SelectMembersField from '../components/SelectMembers/SelectMembersField';
import NextButton from './NextButton';
import StyledPrivateForm from './StyledPrivateForm';

type Props = {
  pageMode: Extract<AllPageModeType, 'create' | 'copy'>;
  currentLecture?: LectureDetailResponse;
};

const PrivateCommonCreateForm = ({ pageMode, currentLecture }: Props) => {
  const { getValues, setValue } = useFormContext<BookingPrivateFormType>();
  const [isOpenNoRoomDialog, setIsOpenNoRoomDialog] = useState(false);

  const { setSearchParams, getAllSearchParams } = useQueryString();

  const goNextPage = () => {
    /** 수업명 입력하지 않았을 때, 수강회원 이름으로 세팅 */
    if (!getValues('title')) {
      const title = getPrivateTitle(getValues('selectedUserTickets'));
      setValue('title', title, { shouldDirty: true });
    }

    setSearchParams({
      ...getAllSearchParams(),
      step: '2',
    });
  };

  const clickNext = () => {
    const { roomId, selectedRoom } = getValues();

    /** 룸 선택 안함을 수동 클릭한 경우는 다음 버튼 누를 경우 확인 팝업 노출하지 않음 */
    const noSelectedRoom = !selectedRoom && roomId === BOOKING_COMMON_FORM_TEXT.room.notSelected.value;

    if (pageMode === 'create' && noSelectedRoom) {
      setIsOpenNoRoomDialog(true);
      return;
    }
    if (pageMode === 'copy' && noSelectedRoom) {
      setValue('selectedRoom', { id: -1, name: BOOKING_COMMON_FORM_TEXT.room.notSelected.label }, { shouldDirty: true });
    }
    goNextPage();
  };

  /** 수업 생성 폼에서 날짜 변경 시 수강회원 초기화 */
  const resetMember = () => {
    const hasSelectedMember = !!getValues('selectedUserTickets').length;
    // 현재 선택된 수강회원 있는 경우에만 초기화 팝업 노출
    if (hasSelectedMember) {
      setValue('resetChangeTarget', 'date');
    }
    setValue('selectedUserTickets', []);
    setValue('prevSelectedUserTickets', []);
    setValue('title', '');
  };

  return (
    <>
      <FormContainer>
        <SelectStaff lectureStaff={currentLecture?.staff} />
        <BookingRangeDate
          isRange
          isInitialOpen={pageMode === 'copy'}
          resetMember={pageMode === 'create' ? resetMember : undefined}
        />
        <BookingRangeTime pageMode={pageMode} />
        <RepeatField />
        <Room />
        <LectureSize />
        <SelectMembersField />
        <ClassTitle description={BOOKING_PRIVATE_FORM_TEXT.title.description} />
        <NextButton onClick={clickNext} />
      </FormContainer>

      {isOpenNoRoomDialog && (
        <ConfirmNoRoomDialog
          onClick={() => {
            setIsOpenNoRoomDialog(false);
            goNextPage();
          }}
          onClose={() => setIsOpenNoRoomDialog(false)}
        />
      )}

      {getValues('resetChangeTarget') === 'date' && <ResetConfirmDialog resetTarget="member" />}
    </>
  );
};

export default PrivateCommonCreateForm;

const FormContainer = styled(StyledPrivateForm)`
  .underline-textarea {
    border-bottom: none;
  }
`;
