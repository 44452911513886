import { CalendarViewType, MarkedType } from 'pages/Schedule/ScheduleMain/types';
import { atom, selector } from 'recoil';
import localStorage from 'utils/localStorage';
import localStorageEffect from 'utils/localStorageEffect';

import { SelectedFiltersAtomType } from './types';

const calendarSetting = localStorage.get('calendar_setting');

export const calendarViewTypeAtom = atom<CalendarViewType>({
  key: 'calendarViewTypeAtom',
  default: calendarSetting ? calendarSetting.defaultViewType : 'list',
});

export const currentDateAtom = atom({
  key: 'currentDateAtom',
  default: new Date(),
});

export const selectedFiltersAtom = atom<SelectedFiltersAtomType>({
  key: 'selectedFiltersAtom',
  default: {
    schedules: ['all'],
    staffs: ['all'],
  },
  effects: [localStorageEffect('filterSettings')],
});

export const tempApplyStaffsAtom = atom<SelectedFiltersAtomType['staffs']>({
  key: 'tempApplyStaffsAtom',
  default: selector({
    key: 'tempApplyStaffsSelector',
    get: ({ get }) => {
      return get(selectedFiltersAtom).staffs;
    },
  }),
});

export const tempCheckedStaffsAtom = atom<SelectedFiltersAtomType['staffs']>({
  key: 'tempCheckedStaffsAtom',
  default: selector({
    key: 'tempCheckedStaffsSelectorAtom',
    get: ({ get }) => {
      return get(selectedFiltersAtom).staffs;
    },
  }),
});

export const selectedTempSchedulesAtom = atom<SelectedFiltersAtomType['schedules']>({
  key: 'selectedTempSchedulesAtom',
  default: selector({
    key: 'selectedTempSchedulesSelector',
    get: ({ get }) => {
      return get(selectedFiltersAtom).schedules;
    },
  }),
});

export const isDirtyFilterSelector = selector({
  key: 'isDirtyFilterSelector',
  get: ({ get }) => {
    return true;
  },
});

export const headerDatePickerOpenAtom = atom({
  key: 'headerDatePickerOpenAtom',
  default: false,
});

export const markedAtom = atom<MarkedType>({
  key: 'markedAtom',
  default: [],
});

export const listCalendarTypeAtom = atom<'week' | 'month'>({
  key: 'listCalendarTypeAtom',
  default: 'month',
});
