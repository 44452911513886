import styled from '@emotion/styled';
import { Datepicker, localeKo, MbscDatepickerOptions } from '@mobiscroll/react';
import { CommonWheelStyles } from 'sharedComponents/mobiscroll/CommonWheelStyles';

export type Props = MbscDatepickerOptions;

const DateTimePickerWithTodayText = ({ ...props }: Props) => {
  return (
    <Container className="date-time-picker-with-today-text">
      <Datepicker
        {...props}
        theme="none"
        touchUi={true}
        locale={localeKo}
        display="inline"
        controls={['datetime']}
        rows={3}
        timeFormat="HH:mm"
        itemHeight={32}
        minWheelWidth={32}
        dateWheels="|MM.DD (DDD)|"
      />
    </Container>
  );
};

export default DateTimePickerWithTodayText;

const Container = styled(CommonWheelStyles)`
  .mbsc-datepicker-tab-wrapper .mbsc-datepicker-tab {
    padding: 32px 0 40px;
  }

  /** 날짜, 시, 분 전체 flex 된 wrapper */
  .mbsc-scroller-wheel-group {
    gap: 16px;
  }

  /** 날짜 wrapper */
  .mbsc-datetime-date-wheel {
    .mbsc-scroller-wheel {
      min-width: 145px;
    }
  }

  /** selected bar */
  .mbsc-scroller-wheel-line {
    height: 32px !important;
    line-height: 32px !important;
  }

  /** 분 wrapper */
  .mbsc-datetime-hour-wheel::before {
    content: ':';
    color: #aaabaf;
    font-size: 1.9rem;
    font-weight: 500;
    line-height: 26px;
    position: absolute;
    top: 50%;
    right: -50%;
    transform: translate(calc(-50% - 2px), calc(-50% - 1px));
  }
`;
