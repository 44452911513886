import { TabDataElementType } from 'components/Tabs';
import useGetFilterStaffs from 'hooks/service/queries/useGetFilterStaffs';
import usePermission from 'hooks/usePermission';
import useQueryString from 'hooks/useQueryString';
import { useEffect, useMemo } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import {
  currentPermissionMoreDetailScheduleAtom,
  isEveryPermissionMoreDetailScheduleAtom,
  staffListForScheduleFilter,
} from 'recoil/moreDetail/atoms';
import ApiBoundary from 'sharedComponents/Boundaries/ApiBoundary';

import BookingScheduleList from './components/BookingScheduleList';
import CheckinScheduleList from './components/CheckinScheduleList';
import DeletedLectureScheduleList from './components/DeletedLectureScheduleList';
import ScheduleLayout from './components/ScheduleLayout';
import { SCHEDULE_TABS } from './constant';
import ScheduleSkeleton from './ScheduleSkeleton';

const ScheduleTemplate = () => {
  const { getSearchParams, setSearchParams } = useQueryString();
  const { everyPermissions } = usePermission();
  const currentPermissions = useRecoilValue(currentPermissionMoreDetailScheduleAtom);
  const setIsEveryPermissionMoreDetailSchedule = useSetRecoilState(isEveryPermissionMoreDetailScheduleAtom);
  const setStaffListForScheduleFilter = useSetRecoilState(staffListForScheduleFilter);
  const { data: staffs } = useGetFilterStaffs();

  /** 다른 스태프의 일정 조회 권한이 모두 있어야 true */
  const memoizedPermission = useMemo(() => everyPermissions(currentPermissions), [currentPermissions, everyPermissions]);

  const tab = getSearchParams('tab');
  const currentTab = SCHEDULE_TABS.find(tabData => tabData.value === tab) || SCHEDULE_TABS[0];

  const changeTab = (tabData: TabDataElementType) => {
    setSearchParams({ tab: tabData.value }, { replace: true });
  };

  useEffect(() => {
    if (memoizedPermission !== undefined) {
      setIsEveryPermissionMoreDetailSchedule(memoizedPermission);
    }
  }, [memoizedPermission]);

  useEffect(() => {
    if (staffs) {
      setStaffListForScheduleFilter(staffs);
    }
  }, [setStaffListForScheduleFilter, staffs]);

  return (
    <ScheduleLayout currentTab={currentTab.value} changeTab={changeTab}>
      <ApiBoundary fallback={<ScheduleSkeleton />}>
        {currentTab.value === 'booking' && <BookingScheduleList currentTab={currentTab.value} />}
      </ApiBoundary>
      <ApiBoundary fallback={<ScheduleSkeleton />}>
        {currentTab.value === 'checkin' && <CheckinScheduleList currentTab={currentTab.value} />}
      </ApiBoundary>
      <ApiBoundary fallback={<ScheduleSkeleton />}>
        {currentTab.value === 'deletedLecture' && <DeletedLectureScheduleList currentTab={currentTab.value} />}
      </ApiBoundary>
    </ScheduleLayout>
  );
};

export default ScheduleTemplate;
