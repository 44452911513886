import Icon from 'components/Icon';
import UnderlinedTextareaController from 'components/Textarea/UnderlinedTextareaController';
import useErrorDialog from 'hooks/useErrorDialog';
import useToast from 'hooks/useToast';
import { AllPageModeType } from 'pages/Booking/types';
import { FocusEvent, useCallback, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';

import { BOOKING_GROUP_FORM_TEXT } from '../../constants';
import { BookingGroupCreateFormType } from '../../types';

type Props = {
  pageMode: AllPageModeType;
};

/** 수업 소개 */
const Description = ({ pageMode }: Props) => {
  const { control, formState, getValues, setValue, setFocus } = useFormContext<BookingGroupCreateFormType>();
  const { setToast } = useToast();
  const errorDialog = useErrorDialog();

  /** 이후 모든 수업 수정에서 최초 선택 될 때, 에러 문구 노출 */
  const handleFocus = useCallback(
    (e: FocusEvent<HTMLTextAreaElement, Element>) => {
      if (getValues('showDescriptionErrorUpdateAll')) return;

      e.currentTarget.blur(); // 에러 다이얼로그 노출될 때는 focus 되어서 키보드 올라오지 않도록
      errorDialog.open(BOOKING_GROUP_FORM_TEXT.description.updateAllError, () => {
        setValue('showDescriptionErrorUpdateAll', true);
        setFocus('description');
      });
    },
    [errorDialog, getValues, setFocus, setValue],
  );

  useEffect(() => {
    if (formState.errors.title || !formState.errors.description) return;
    setToast({ type: 'error', message: formState.errors.description.message ?? null, bottom: 92 });
  }, [formState.errors.description, formState.errors.title, setToast]);

  return (
    <UnderlinedTextareaController
      controllerProps={{ control, name: 'description' }}
      placeholder={BOOKING_GROUP_FORM_TEXT.description.placeholder}
      left={<Icon name="explain" color="gray2" />}
      readOnly={pageMode === 'update'}
      onFocus={handleFocus}
      showBottom={false}
      maxLength={200}
    />
  );
};

export default Description;
