import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import Button from 'components/Button';
import Divider from 'components/Divider';
import Typography from 'components/Typography';
import useGetPermissionDoHavePermission from 'hooks/service/queries/useGetPermissionDoHavePermission';
import { ReportPointResponse } from 'hooks/service/queries/useInfinityReportPoint';
import { MEMBER_PERMISSION } from 'pages/MemberDetail/constants';
import { useNavigate } from 'react-router-dom';
import { ColorType } from 'types/colorTypes';
import filters from 'utils/filters';

import { POINT_TYPE_LABEL } from '../../constants';
import { getPointStatusLabel } from '../../util';

type Props = {
  points: ReportPointResponse[];
};

const PointListCard = ({ points }: Props) => {
  const navigate = useNavigate();
  const { checkPermission } = useGetPermissionDoHavePermission();

  const goToTicketDetail = (ticketId: number, memberId: number) => {
    checkPermission(MEMBER_PERMISSION.memberTicketEdit.id, {
      onSuccess: () => {
        navigate(`/product/detail/${ticketId}?memberId=${memberId}`);
      },
      onError: () => {
        return true;
      },
    });
  };

  return (
    <PointListWrapper>
      {points.map(point => {
        const statusLabel = getPointStatusLabel(point.payment_status);

        // 포인트 값을 포맷팅하는 로직을 함수로 분리
        const formatPoint = (point: ReportPointResponse) => {
          if (point.type === 'reward' || point.type === 'batch_reward') {
            return `+${filters.numberComma(point.reward_point)}P`;
          } else if (point.type === 'mix') {
            return `${filters.numberComma(point.paid_point)} / +${filters.numberComma(point.reward_point)}P`;
          } else {
            return `${filters.numberComma(Number(point.paid_point))}P`;
          }
        };

        return (
          <ItemWrapper key={point.id}>
            <TopWrapper>
              <div className="item-text-wrapper">
                <div className="member-name">
                  <Typography span ellipsis={1} size={15} weight={600} textColor="gray1">
                    {point.member.name}
                  </Typography>
                  <Typography span size={15} weight={600} textColor="gray1">
                    회원
                  </Typography>
                </div>
                <Typography span size={15} weight={700} textColor="gray1">
                  {formatPoint(point)}
                </Typography>
              </div>
              <div className="item-text-wrapper">
                <Typography
                  textColor="gray2"
                  opacity={
                    0.8
                  }>{`${filters.dateWithWeekday(point.settlement_at)} ${filters.time(point.settlement_at)}`}</Typography>
                <Typography size={15} weight={500} textColor={POINT_TYPE_LABEL[point.type].color as ColorType}>
                  {POINT_TYPE_LABEL[point.type].label}
                </Typography>
              </div>
            </TopWrapper>
            <Divider thin thinColor="gray6" />
            <BottomWrapper>
              <div className="item-text-wrapper">
                <Typography textColor="gray2">분류</Typography>
                <Typography textColor="gray2" weight={500}>
                  {statusLabel}
                </Typography>
              </div>
              <div className="item-text-wrapper">
                <Typography textColor="gray2">강사</Typography>
                <Typography ellipsis={1} textColor="gray2" weight={500}>
                  {point.staff.name}
                </Typography>
              </div>
              {point.updated_for && (
                <div className="item-text-wrapper">
                  <Typography textColor="gray2">사유</Typography>
                  <Typography ellipsis={1} textColor="gray2">
                    {point.updated_for}
                  </Typography>
                </div>
              )}
            </BottomWrapper>
            {!(point.type === 'batch_reward' || point.type === 'batch_deduct') && (
              <Button
                className="point-card-button"
                fontWeight={500}
                fullWidth
                size="medium40"
                variant="outlined"
                onClick={() => goToTicketDetail(point.ref_id, point.member.id)}>
                수강권 정보
              </Button>
            )}
          </ItemWrapper>
        );
      })}
    </PointListWrapper>
  );
};

export default PointListCard;

const PointListWrapper = styled.div`
  background-color: ${theme.color.gray6};

  padding: 20px;
`;

const ItemWrapper = styled.div`
  padding: 18px 20px;
  background: ${theme.color.white};
  border-radius: 16px;
  box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.08);

  :not(:last-of-type) {
    margin-bottom: 12px;
  }

  .item-text-wrapper {
    ${theme.flex('', 'center', 'space-between', 8)}

    .member-name {
      ${theme.flex('', '', '', 3)}
      >span:last-of-type {
        flex-shrink: 0;
      }
    }
  }

  .reason-text-wrapper {
    margin-top: 12px;
    padding: 12px;
    background: ${theme.color.gray6};
    border-radius: 6px;
  }

  .point-card-button {
    margin-top: 8px;
  }
`;

const TopWrapper = styled.div`
  padding-bottom: 10px;
`;

const BottomWrapper = styled.div`
  padding: 12px 0;

  .item-text-wrapper:not(:last-child) {
    padding-bottom: 8px;
  }
`;
