import styled from '@emotion/styled';
import { queryClient } from 'api/queryClient';
import { theme } from 'assets/styles';
import Dialog from 'components/Dialog';
import { DialogDescription } from 'components/Dialog/DialogContents';
import { PERMISSION } from 'constants/permission';
import usePatchBookingGroup from 'hooks/service/mutations/usePatchBookingGroup';
import usePatchBookingGroupCancelWaiting from 'hooks/service/mutations/usePatchBookingGroupCancelWaiting';
import usePatchBookingPrivate, { ChangePrivateBookingStatusParams } from 'hooks/service/mutations/usePatchBookingPrivate';
import { LectureDetailResponse } from 'hooks/service/queries/useGetLectureDetail';
import useGetPermissionDoHavePermission from 'hooks/service/queries/useGetPermissionDoHavePermission';
import { LectureBookingListResponse } from 'hooks/service/queries/useInfinityBookingList';
import useErrorDialog from 'hooks/useErrorDialog';
import useToast from 'hooks/useToast';
import { BOOKING_COMMON_FORM_TEXT } from 'pages/Booking/constants';
import { BookingStatusType, BookingType } from 'pages/Booking/types';
import { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';
import { errorBackStepAtom } from 'recoil/common';
import { CustomError } from 'types/errorTypes';

import CancelBookingDialog from './CancelBookingDialog';
import ChangeBookingStatusDrawer from './ChangeBookingStatusDrawer';
import MemberCard from './MemberCard';

type Props = {
  isMe: boolean;
  currentLecture: LectureDetailResponse;
  booking: LectureBookingListResponse;
  bookingType: BookingType;
  currentBookingTraineeCount: number;
  waitingOrder?: number;
  canCancelBooking: boolean;
  canChangeBooking: boolean;
};

const BookingListItem = ({
  isMe,
  currentLecture,
  booking,
  bookingType,
  currentBookingTraineeCount,
  waitingOrder,
  canCancelBooking,
  canChangeBooking,
}: Props) => {
  const { pathname } = useLocation();

  const [isOpenDrawer, setIsOpenDrawer] = useState(false);
  const [isOpenCancelDialog, setIsOpenCancelDialog] = useState(false);
  const [isOpenExceedDialog, setIsOpenExceedDialog] = useState(false);
  const [currentMemberBooking, setCurrentMemberBooking] = useState<LectureBookingListResponse | null>(null);
  const setErrorBackStep = useSetRecoilState(errorBackStepAtom);

  const { mutate: changePrivateBookingStatusMutate } = usePatchBookingPrivate(booking);
  const { mutate: changeGroupBookingStatusMutate, isPending: isPendingGroup } = usePatchBookingGroup(booking);
  const { mutate: cancelGroupWaitingMutate } = usePatchBookingGroupCancelWaiting(booking.id);
  const errorDialog = useErrorDialog();
  const { setToast } = useToast();

  const { checkPermission } = useGetPermissionDoHavePermission();

  const staffType = isMe ? 'mine' : 'others';

  const clickBookingStatusButton = (booking: LectureBookingListResponse) => {
    const onSuccess = () => {
      setCurrentMemberBooking(booking);
      setIsOpenDrawer(true);
    };

    checkPermission(PERMISSION.lecture[staffType].editBookings[bookingType].id, {
      onSuccess,
      onError: () => {
        checkPermission(PERMISSION.lecture[staffType].cancelBookings[bookingType].id, {
          onSuccess,
          onError: () => {
            if (isMe) {
              setErrorBackStep({
                to: pathname,
                options: { replace: true },
              });
            } else {
              checkPermission(PERMISSION.lecture.others.view[bookingType].id, {
                onSuccess: () => {
                  setErrorBackStep({
                    to: '/schedule',
                    options: { replace: true },
                  });
                },
              });
            }
          },
        });
        return true;
      },
    });
  };

  const refresh = () => {
    queryClient.invalidateQueries({ queryKey: ['lecture', 'detail', currentLecture.id] });
  };

  const mutateCallback = (message?: string) => {
    return {
      onSettled: () => {
        setIsOpenDrawer(false);
        setIsOpenExceedDialog(false);
      },
      onSuccess: () => {
        refresh();
        setToast({ type: 'success', message: message ?? '예약 상태가 변경되었습니다.' });
      },
      onError: (error: CustomError) => {
        if (error.response?.data?.code === 10207 || error.response?.data?.code === 10208) {
          const message = `${error.response?.data?.message}${BOOKING_COMMON_FORM_TEXT.refresh}`;
          errorDialog.open(message, refresh, '새로고침');
          return false;
        }
      },
    };
  };

  const changeBookingStatus = (params: ChangePrivateBookingStatusParams) => {
    if (bookingType === 'private') {
      changePrivateBookingStatusMutate(params, mutateCallback());
    } else {
      changeGroupBookingStatusMutate(params, mutateCallback());
    }
  };

  const clickDrawerItem = (value: BookingStatusType['value']) => {
    const needExceedBooking = currentBookingTraineeCount >= currentLecture.max_trainee;
    if (value === 'cancel') {
      setIsOpenCancelDialog(true);
    } else if (value === 'waiting_cancel') {
      cancelGroupWaitingMutate(undefined, mutateCallback('예약대기가 취소되었습니다.'));
    } else if (needExceedBooking && value === 'booked' && booking.status === 'booking_waiting') {
      setIsOpenExceedDialog(true);
    } else {
      changeBookingStatus({ status: value });
    }
  };

  return (
    <Container>
      <MemberCard
        booking={booking}
        waitingOrder={waitingOrder}
        canCancelBooking={canCancelBooking}
        canChangeBooking={canChangeBooking}
        onClickStatusChange={clickBookingStatusButton}
      />

      {currentMemberBooking && (
        <ChangeBookingStatusDrawer
          isOpen={isOpenDrawer}
          onClose={() => setIsOpenDrawer(false)}
          booking={currentMemberBooking}
          canCancelBooking={canCancelBooking}
          canChangeBooking={canChangeBooking}
          onClick={clickDrawerItem}
        />
      )}

      {isOpenCancelDialog && currentMemberBooking && (
        <CancelBookingDialog
          bookingType={bookingType}
          currentMemberBooking={currentMemberBooking}
          currentBookingTraineeCount={currentBookingTraineeCount}
          onClose={() => setIsOpenCancelDialog(false)}
        />
      )}
      {isOpenExceedDialog && (
        <Dialog
          onClose={() => setIsOpenExceedDialog(false)}
          negativeAction={{ text: '취소', style: 'outlined', disabled: isPendingGroup }}
          positiveAction={{
            text: '초과예약',
            onClick: () => changeBookingStatus({ status: 'booked' }),
            disabled: isPendingGroup,
          }}>
          <DialogDescription>
            예약 정원이 마감되었습니다.
            <br />
            <b>[초과예약]</b>을 하시겠습니까?
          </DialogDescription>
        </Dialog>
      )}
    </Container>
  );
};

export default BookingListItem;

const Container = styled.li`
  &:not(:last-of-type) {
    border-bottom: 1px solid ${theme.color.gray8};
  }
`;
