import useQueryString from 'hooks/useQueryString';
import useSearchKeywords from 'hooks/useSearchKeywords';
import useToast from 'hooks/useToast';
import { useCallback, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';
import { customBackHandlersAtom } from 'recoil/common';
import ApiBoundary from 'sharedComponents/Boundaries/ApiBoundary';

import { MessageType } from '../types';
import { MESSAGE_TARGET_IDENTIFIER } from './constants';
import TargetCounsel from './Counsel';
import TargetMember from './Member';

const MoreDetailsMessageTarget = () => {
  const navigate = useNavigate();
  const { state } = useLocation() as { state: MessageType };
  const { getSearchParams } = useQueryString();

  const type = getSearchParams('type') === 'member' ? 'member' : 'counsel';

  useEffect(() => {
    // 작성중인 메시지가 없으면 접근 제한
    if (!state || !state.mode || !state.type) {
      navigate('/more-details/message', { replace: true });
    }
  }, [navigate, state]);

  const { setToast } = useToast();
  const { setSearchKeyword } = useSearchKeywords(MESSAGE_TARGET_IDENTIFIER);
  const goToForm = useCallback(
    (updater?: (previousState: MessageType) => MessageType) => {
      const message = state.type === 'push' ? 'push' : 'mobile';
      navigate(`/more-details/message/${message}/form`, {
        state: updater?.(state) || state,
        replace: true,
      });
      if (updater) {
        setToast({ message: '목록이 변경되었습니다. ', type: 'success', bottom: 16 + (state.isReserve ? 120 : 76) });
      }
      setSearchKeyword(''); // 받는대상 페이지 이탈시 검색어 초기화
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const setBackHandler = useSetRecoilState(customBackHandlersAtom);
  useEffect(() => {
    setBackHandler(prev => [...prev, { key: 'message-target', handler: () => goToForm() }]);

    return () => {
      setBackHandler(prev => prev.filter(({ key }) => key !== 'message-target'));
    };
  }, [goToForm, setBackHandler]);

  if (type === 'member') {
    return (
      <ApiBoundary>
        <TargetMember goToForm={goToForm} />
      </ApiBoundary>
    );
  }
  return (
    <ApiBoundary>
      <TargetCounsel goToForm={goToForm} />
    </ApiBoundary>
  );
};

export default MoreDetailsMessageTarget;
