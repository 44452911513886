import { yupResolver } from '@hookform/resolvers/yup';
import { useIsFetching } from '@tanstack/react-query';
import useGetStaff from 'hooks/service/queries/useGetStaff';
import useParamsId from 'hooks/useParamsId';
import useQueryString from 'hooks/useQueryString';
import { FormProvider, useForm } from 'react-hook-form';
import ApiBoundary from 'sharedComponents/Boundaries/ApiBoundary';
import DialogBeforeLeave from 'sharedComponents/DialogBeforeLeave';
import FullScreenLoading from 'sharedComponents/FullScreenLoading';
import MainLayout from 'sharedComponents/MainLayout';
import SelectRepresentativeColor from 'sharedComponents/ProfileField/components/RepresentativeColor/SelectRepresentativeColor';
import filters from 'utils/filters';
import getColor, { Color } from 'utils/getColor';

import { STAFF_PROFILE_FORM_TEXT, staffInfoYupSchema } from '../constants';
import { InfoUpdateFormType } from '../types';
import StaffUpdateForm from './components/StaffUpdateForm';

const MoreDetailsStaffInfoUpdateForm = () => {
  const { getSearchParams } = useQueryString();

  const targetStaffId = useParamsId();
  const { data: staff } = useGetStaff({ targetStaffId });

  const pageType = getSearchParams('type');
  const isRepresentativeColor = pageType === 'representativeColor';
  const isFromMyInfoPage = pageType === 'my-info';

  const methods = useForm<InfoUpdateFormType>({
    resolver: yupResolver(staffInfoYupSchema),
    reValidateMode: 'onSubmit',
    shouldFocusError: false,
    defaultValues: {
      mobile: staff.mobile ? staff.mobile.replaceAll('-', '') : '',
      role_id: staff.roles[0].id.toString(),
      profile: {
        name: staff.name,
        registered_at: filters.date(staff.profile.registered_at),
        representative_color: getColor(staff.profile.representative_color) as Color,
        gender: staff.profile.gender || 'F',
        birthday: staff.profile.birthday ? filters.date(staff.profile.birthday) : undefined,
        get_noti_all: !!staff.profile.get_noti_all,
      },
    },
  });

  const isFetching = useIsFetching();
  const {
    formState: { isDirty, isSubmitSuccessful },
  } = methods;

  return (
    <MainLayout
      header={{
        title: isFromMyInfoPage
          ? STAFF_PROFILE_FORM_TEXT.headerTitle.myInfoUpdate
          : STAFF_PROFILE_FORM_TEXT.headerTitle.staffInfoUpdate,
      }}>
      <ApiBoundary fallback={<FullScreenLoading loop={!!isFetching} />}>
        <FormProvider {...methods}>
          {isRepresentativeColor ? <SelectRepresentativeColor /> : <StaffUpdateForm isFromMyInfoPage={isFromMyInfoPage} />}
        </FormProvider>
      </ApiBoundary>

      <DialogBeforeLeave isBlocked={isDirty && !isSubmitSuccessful} />
    </MainLayout>
  );
};

export default MoreDetailsStaffInfoUpdateForm;
