import { useQueryClient } from '@tanstack/react-query';

import useCustomMutation from '../common/useCustomMutation';
import useMutationFnWithCommonParams from '../common/useMutationFnWithCommonParams';
import { LectureRuleType } from '../queries/useGetLectureCourse';
import { LectureDetailResponse } from '../queries/useGetLectureDetail';
import { LecturePrivatePatchParams } from './usePatchLecturePrivate';
import { BookingPrivateParams, BookingPrivateResponse } from './usePostBookingPrivate';

/** 프라이빗 이후 모든 수업 수정 (코스 수정) */
const usePatchLecturePrivateBulk = (lecture: LectureDetailResponse) => {
  const { course_id } = lecture;
  const queryClient = useQueryClient();

  const { mutationFn } = useMutationFnWithCommonParams<BookingPrivateBulkParams, BookingPrivateBulkResponse>({
    method: `patch`,
    url: `/api/lecture/private/bulk/${course_id}`,
  });

  return useCustomMutation({
    mutationFn,
    onSuccess: () => {
      queryClient.removeQueries({
        queryKey: ['lecture', 'detail', lecture.id],
      });
    },
  });
};

export default usePatchLecturePrivateBulk;

export type BookingPrivateBulkParams = Pick<LecturePrivatePatchParams, 'is_force' | 'is_pass_limit'> &
  BookingPrivateParams & {
    start: string;
    private_booking_rule_type: LectureRuleType;
    private_booking_cancel_rule_type: LectureRuleType;
    private_booking_cancel_startline: number;
    private_booking_cancel_deadline: number;
    private_booking_cancel_start_days: number;
    private_booking_cancel_end_days: number;
    private_booking_cancel_start_time: number;
    private_booking_cancel_end_time: number;
    private_booking_startline: number;
    private_booking_deadline: number;
    private_booking_start_days: number;
    private_booking_end_days: number;
    private_booking_start_time: number;
    private_booking_end_time: number;
    enter_start_deadline: number;
    enter_end_deadline: number;
  };

export type BookingPrivateBulkResponse = BookingPrivateResponse;
