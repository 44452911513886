import instance from 'api/axios';
import { isArray } from 'lodash';
import { useCallback, useState } from 'react';
import { useErrorBoundary } from 'react-error-boundary';
import { useRecoilValue } from 'recoil';
import { staffIdAtom, studioIdAtom } from 'recoil/common';
import { CustomError } from 'types/errorTypes';

export const CLICK_CHECK_PERMISSION_BASE_URL = '/api/permission/do-have-permission/permission';

const useGetPermissionDoHavePermission = () => {
  const [isLoading, setIsLoading] = useState(false);
  const studio_id = useRecoilValue(studioIdAtom);
  const staff_id = useRecoilValue(staffIdAtom);
  const { showBoundary } = useErrorBoundary();

  const checkPermission = useCallback(
    async (
      permission_id: number | Array<number>,
      { onSuccess, onError }: { onSuccess?: () => void; onError?: (error?: CustomError) => void | boolean },
    ) => {
      if (isLoading) return;
      try {
        setIsLoading(true);
        const permissionId = isArray(permission_id) ? permission_id : [permission_id];

        await instance({
          method: 'get',
          url: CLICK_CHECK_PERMISSION_BASE_URL,
          params: {
            studio_id,
            staff_id,
            permission_id: permissionId,
          },
        });
        onSuccess?.();
      } catch (error: unknown) {
        const dialog = onError?.(error as CustomError) ?? false;
        if (!dialog) {
          showBoundary(error);
        }
      } finally {
        setIsLoading(false);
      }
    },
    [isLoading, showBoundary, staff_id, studio_id],
  );

  return { checkPermission };
};

export default useGetPermissionDoHavePermission;
