import styled from '@emotion/styled';
import Dialog from 'components/Dialog';
import { DialogDescription } from 'components/Dialog/DialogContents';
import Divider from 'components/Divider';
import Form from 'components/Form';
import Typography from 'components/Typography';
import { LectureDetailResponse } from 'hooks/service/queries/useGetLectureDetail';
import ClassTitle from 'pages/Booking/components/ClassTitle';
import RepeatField from 'pages/Booking/components/Repeat/RepeatField';
import Room from 'pages/Booking/components/Room';
import SelectStaff from 'pages/Booking/components/SelectStaff';
import { AllPageModeType } from 'pages/Booking/types';
import { ComponentProps, FormEvent, useState } from 'react';
import { FieldPath, PathValue, useFormContext } from 'react-hook-form';

import { BookingGroupCommonFormType } from '../../types';
import BookingRequiredSwitch from './BookingRequiredSwitch';
import Description from './Description';
import DivisionField from './Division/DivisionField';
import MaxTrainee from './MaxTrainee';
import MinTrainee from './MinTrainee';
import OperationAccordion from './Operation/OperationAccordion';
import SubmitButton from './SubmitButton';
import WaitingTrainee from './WaitingTrainee';

type Props<T extends BookingGroupCommonFormType> = Omit<ComponentProps<typeof Form>, 'onSubmit'> & {
  pageMode: AllPageModeType;
  currentLecture?: LectureDetailResponse;
  onSubmit: (values: T) => void;
};

/**
 * 그룹 수업 폼은 등록, 수정, 복사 폼 디자인이 날짜, 시간 선택 외 모두 같음
 * children 에 날짜, 시간 선택 폼
 */
const CommonGroupForm = <T extends BookingGroupCommonFormType>({
  children,
  pageMode,
  currentLecture,
  onSubmit,
  ...props
}: Props<T>) => {
  const { getValues, setValue, handleSubmit } = useFormContext<T>();
  const [isOpen, setIsOpen] = useState(false);

  const submit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (getValues('minTrainee' as FieldPath<T>)) {
      setIsOpen(true);
    } else {
      handleSubmit(onSubmit)();
    }
  };

  return (
    <>
      <StyledForm onSubmit={submit} showEnterField={!!getValues('showEnterField' as FieldPath<T>)} {...props}>
        <BookingRequiredSwitch />
        <SelectStaff lectureStaff={currentLecture ? currentLecture.staff : undefined} />
        {children}
        {pageMode !== 'update' && (
          <RepeatField
            isLectureUpdateAllForm={pageMode === 'updateAll'}
            onClick={() => setValue('isOpenOperationAccordion' as FieldPath<T>, false as PathValue<T, FieldPath<T>>)}
          />
        )}
        <Room />
        <Divider />
        <StyledTypography size={15} weight={600}>
          상세정보
        </StyledTypography>
        <ClassTitle />
        <Description pageMode={pageMode} />
        <MaxTrainee />
        <MinTrainee />
        {getValues('showWaitingField' as FieldPath<T>) && <WaitingTrainee />}
        <DivisionField />
        <Divider />
        <OperationAccordion pageMode={pageMode} />
        <SubmitButton pageMode={pageMode} />
      </StyledForm>

      {isOpen && (
        <Dialog
          isError
          onClose={() => setIsOpen(false)}
          positiveAction={{
            text: '확인',
            onClick: () => {
              setIsOpen(false);
              handleSubmit(onSubmit)();
            },
          }}>
          <DialogDescription>
            최소 수강 인원 <b>[{getValues('minTrainee' as FieldPath<T>)}명]</b>이
            <br />
            채워지지 않으면 수업은 자동 삭제,
            <br />
            예약은 자동 취소됩니다.
          </DialogDescription>
        </Dialog>
      )}
    </>
  );
};

export default CommonGroupForm;

const StyledForm = styled(Form)<{ showEnterField?: boolean }>`
  .operation-field-button:last-of-type {
    ${({ showEnterField }) => !showEnterField && `border: none`};
  }
`;

const StyledTypography = styled(Typography)`
  padding: 20px 20px 8px;
`;
