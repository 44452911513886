import styled from '@emotion/styled';
import Dialog from 'components/Dialog';
import { DialogDescription } from 'components/Dialog/DialogContents';
import Typography from 'components/Typography';
import { PERMISSION } from 'constants/permission';
import usePermission from 'hooks/usePermission';
import usePopup from 'hooks/usePopup';
import {
  ArrayWholeType,
  HistoryFilterFormType,
  LectureCardCancelParamsType,
  LectureCardNavigateParamsType,
} from 'pages/MemberDetail/types';
import { useCallback, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { staffIdAtom } from 'recoil/common';
import { CardWrapper } from 'sharedComponents/InfoTypography';

import LectureCard from '../LectureCard';
import CancelBooking from './CancelBooking';
import DeleteHistory from './DeleteHistory';
import PastHistory from './PastHistory';

type Props = {
  data: ArrayWholeType;
};

const HistoryDividedByDate = ({ data }: Props) => {
  const { setPopup } = usePopup();
  const navigate = useNavigate();
  const { hasPermission } = usePermission();
  const currentStaffId = useRecoilValue(staffIdAtom);

  const { setValue } = useFormContext<HistoryFilterFormType>();

  const [isOpenCancelConfirm, setIsOpenCancelConfirm] = useState(false);
  const [isOpenDeleteConfirm, setIsOpenDeleteConfirm] = useState(false);
  const [isOpenDeleted, setIsOpenDeleted] = useState(false);

  const handleClickPastHistory = (id: number) => {
    setPopup(<PastHistory onClose={() => setPopup(null)} selectedBookingId={id} />);
  };

  const handleCancel = useCallback(
    ({ bookingId, status, classType }: LectureCardCancelParamsType) => {
      setValue('bookingInfo', { id: bookingId, status, classType });
      if (status === 'cancel') {
        setIsOpenDeleteConfirm(true);
      } else {
        setIsOpenCancelConfirm(true);
      }
    },
    [setValue],
  );

  const goToLectureDetail = ({ lectureId, isDeletedLecture }: LectureCardNavigateParamsType) => {
    if (isDeletedLecture) {
      setIsOpenDeleted(true);
      return;
    }
    navigate(`/booking/detail/${lectureId}`);
  };

  return (
    <>
      <Container>
        {data.map(el => {
          return (
            <div key={el.date} className="history-date-wrapper">
              <Typography className="el-date" size={13} weight={500} textColor="gray2">
                {el.date}
              </Typography>
              <CardWrapper>
                {el.arrayElement.map((item, index) => {
                  const { staff, classType, status } = item;
                  // 이미 취소된 예약에 대한 '삭제' 버튼 노출, 취소 권한 있는 경우에만 취소 버튼 노출
                  const canCancelBooking =
                    status === 'cancel' ||
                    hasPermission(
                      PERMISSION.lecture[staff.id === currentStaffId ? 'mine' : 'others'].cancelBookings[
                        classType === 'G' ? 'group' : 'private'
                      ],
                    );
                  return (
                    <LectureCard
                      key={index}
                      data={item}
                      onClickNavigateBookingDetail={goToLectureDetail}
                      onClickPastHistory={handleClickPastHistory}
                      onCancel={canCancelBooking ? handleCancel : undefined}
                    />
                  );
                })}
              </CardWrapper>
            </div>
          );
        })}
      </Container>

      {isOpenCancelConfirm && <CancelBooking onClose={() => setIsOpenCancelConfirm(false)} />}

      {isOpenDeleteConfirm && <DeleteHistory onClose={() => setIsOpenDeleteConfirm(false)} />}

      {isOpenDeleted && (
        <Dialog
          isError
          onClose={() => setIsOpenDeleted(false)}
          positiveAction={{
            text: '확인',
            onClick: () => setIsOpenDeleted(false),
          }}>
          <DialogDescription>수업이 존재하지 않습니다.</DialogDescription>
        </Dialog>
      )}
    </>
  );
};

export default HistoryDividedByDate;

const Container = styled.div`
  flex: 1;

  .history-date-wrapper {
    :not(:first-of-type) {
      padding-top: 48px;
    }

    .el-date {
      margin-bottom: 20px;
    }
  }
`;
