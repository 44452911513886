import Dialog from 'components/Dialog';
import { DialogDescription } from 'components/Dialog/DialogContents';
import usePatchUserTicketRefund from 'hooks/service/mutations/usePatchUserTicketRefund';
import useToast from 'hooks/useToast';
import { TICKET_REFUND_TEXT } from 'pages/TicketDetail/constants';
import { UserTicketProps } from 'pages/TicketDetail/types';
import { useFormContext } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { TicketPaymentFormType } from 'sharedComponents/TicketForm/types';
import getPaymentFormParams from 'utils/getPaymentFormParams';

type Props = UserTicketProps & {
  onClose: () => void;
  productTypeText: string;
};

const RefundConfirmDialog = ({ userTicket, productTypeText, onClose }: Props) => {
  const { getValues } = useFormContext<TicketPaymentFormType>();
  const navigate = useNavigate();
  const { setToast } = useToast();
  const { mutate: refundMutate, isPending } = usePatchUserTicketRefund(userTicket.id);
  const isUserTicket = userTicket.ticket.available_class_type !== 'I';

  const clickRefund = () => {
    const values = getValues();
    const { amount, point_amount, ...rest } = getPaymentFormParams({ ...values });

    let amountParams = amount;
    if (point_amount) {
      amountParams = amount - point_amount;
    }

    const params = {
      ...rest,
      point_amount,
      amount: amountParams < 0 ? 0 : amountParams,
      user_id: userTicket.user_id,
    };

    refundMutate(params, {
      onSettled: onClose,
      onSuccess: () => {
        setToast({ type: 'success', message: `${productTypeText}${TICKET_REFUND_TEXT.successMessage}` });
        navigate(-1);
      },
    });
  };

  return (
    <Dialog
      onClose={onClose}
      title={isUserTicket ? `${TICKET_REFUND_TEXT.dialog.title}` : undefined}
      negativeAction={{ text: TICKET_REFUND_TEXT.dialog.button.negative, disabled: isPending }}
      positiveAction={{ text: TICKET_REFUND_TEXT.dialog.button.positive, onClick: clickRefund, disabled: isPending }}>
      <DialogDescription>{TICKET_REFUND_TEXT.dialog.description[isUserTicket ? 'userTicket' : 'item']}</DialogDescription>
    </Dialog>
  );
};

export default RefundConfirmDialog;
