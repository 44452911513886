import { useQuery } from '@tanstack/react-query';
import { AppPushTargetType } from 'pages/MoreDetails/Message/Push/Detail/types';

import useGetAllQueryFnWithCommonParams from '../common/useGetAllQueryFnWithCommonParams';

const useGetAllMessagePushTargets = (id: number, enabled: boolean) => {
  const { queryFn } = useGetAllQueryFnWithCommonParams<AllMessagePushTargetsResponse, AllMessagePushTargetsParams>({
    url: `/api/message/push/${id}/targets`,
    params: { limit: 50 },
  });

  return useQuery({
    queryKey: [`message/push`, id, `targets`, 'all', enabled],
    queryFn,
    enabled,
    select: data => data.data,
    gcTime: 0,
  });
};

export default useGetAllMessagePushTargets;

type AllMessagePushTargetsResponse = AppPushTargetType[];

type AllMessagePushTargetsParams = {
  limit: number;
};
