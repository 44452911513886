import useGetLecturePolicy from 'hooks/service/queries/useGetLecturePolicy';
import useCustomBookingForm from 'hooks/useCustomBookingForm';
import { BOOKING_COMMON_FORM_TEXT } from 'pages/Booking/constants';
import { BookingCreateStateType } from 'pages/Booking/types';
import { convertBookingDateCreateFormState, convertBookingGroupPolicyFormState } from 'pages/Booking/utils';
import { useLocation } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { staffIdAtom } from 'recoil/common';

import { BookingGroupCreateFormType } from '../../types';
import GroupCommonCreateForm from '../components/GroupCommonCreateForm';
import GroupFormMainLayout from '../components/GroupFormMainLayout';

const FormMain = () => {
  const { data: policy } = useGetLecturePolicy();

  const currentStaffId = useRecoilValue(staffIdAtom);
  const { state } = useLocation() as { state: BookingCreateStateType };

  const { startDate, endDate, startTime, endTime } = convertBookingDateCreateFormState(
    state ?? {
      selectedDate: new Date(),
      from: 'floating',
    },
  );

  const policyFormState = convertBookingGroupPolicyFormState(policy);
  const methods = useCustomBookingForm<BookingGroupCreateFormType>({
    defaultValues: {
      title: '',
      startDate,
      endDate,
      startTime,
      endTime,
      selectedStaffId: String(currentStaffId),
      roomId: BOOKING_COMMON_FORM_TEXT.room.notSelected.value,
      repeat: null,
      repeatCustomWeekday: [],
      tempRepeatCustomWeekday: [],
      is_booking_only: true,
      description: '',
      maxTrainee: 4,
      minTrainee: 0,
      waitingTrainee: 0,
      divisionId: null,
      policy: { ...policyFormState },
      showEnterField: policy.is_enter,
      showWaitingField: !!policy.weekly_waiting_limit,
      showDescriptionErrorUpdateAll: true,
    },
  });

  return (
    <GroupFormMainLayout {...methods} pageMode="create">
      <GroupCommonCreateForm pageMode="create" />
    </GroupFormMainLayout>
  );
};

export default FormMain;
