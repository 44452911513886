import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import Typography from 'components/Typography';
import { ROOM_ERROR_KEYS, STAFF_ERROR_KEYS } from 'pages/Booking/constants';
import { getBookingErrorString } from 'pages/Booking/utils/formatFailCard';
import { InfoTypography } from 'sharedComponents/InfoTypography';
import { ArrangeTypographyWrapper } from 'sharedComponents/StyledComponents';
import getEllipsisByLength from 'utils/getEllipsisByLength';

type Props = {
  date: string;
  title: string;
  staffName: string;
  roomName?: string | null;
  errorCode?: string[];
  className?: string;
  showReasonLabel?: boolean;
};

const DetailResultItem = ({ className, date, title, staffName, roomName, errorCode, showReasonLabel = true }: Props) => {
  const staffTextColor = errorCode && errorCode.some(code => STAFF_ERROR_KEYS.includes(code)) ? 'error' : 'gray2';
  const roomTextColor = errorCode && errorCode.some(code => ROOM_ERROR_KEYS.includes(code)) ? 'error' : 'gray2';

  return (
    <Container className={className}>
      <div className="date-wrapper">
        <Typography size={15} weight={600}>
          {date}
        </Typography>
        {showReasonLabel && errorCode && (
          <Typography size={13} weight={500} textColor="gray3">
            {getBookingErrorString(errorCode)}
          </Typography>
        )}
      </div>
      <Typography className="title" size={14} weight={500} textColor="gray2">
        {title}
      </Typography>
      <ArrangeTypographyWrapper>
        <StyledTypography size={13} textColor={staffTextColor}>
          {getEllipsisByLength({ text: staffName, max: 6 })}
          <span>강사</span>
        </StyledTypography>
        {roomName && (
          <StyledTypography size={13} textColor={roomTextColor}>
            {getEllipsisByLength({ text: roomName, max: 6 })}
            <span>룸</span>
          </StyledTypography>
        )}
      </ArrangeTypographyWrapper>
    </Container>
  );
};

export default DetailResultItem;

const Container = styled.li`
  padding: 12px 0;

  .date-wrapper {
    ${theme.flex('', 'center', 'space-between')};
    margin-bottom: 2px;
  }

  .title {
    margin-bottom: 4px;
  }

  &:not(:last-of-type) {
    border-bottom: 1px solid ${theme.color.gray7};
  }
`;

const StyledTypography = styled(InfoTypography)`
  span {
    display: inline-block;
    margin-left: 3px;
  }
`;
