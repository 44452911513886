import dayjs from 'dayjs';
import DateCalendarPicker from 'designedComponents/Pickers/DateCalendarPicker';
import { isArray } from 'lodash';
import { BookingCommonFormType } from 'pages/Booking/types';
import { useMemo, useState } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import FormFieldAccordion from 'sharedComponents/FormField/FormFieldAccordion';
import filters from 'utils/filters';

import BookingResetConfirmDialog from '../BookingResetConfirmDialog';

type Props = {
  isRange: boolean; // false 일 때 1개 수정 폼
  isInitialOpen?: boolean;
  /** 수업 생성 시에 룸(프라이빗은 수강회원 포함) 선택 후 날짜 변경하면 룸, 수강회원 초기화 로직 */
  resetMember?: () => void;
};

const BookingRangeDate = ({ isRange, isInitialOpen, resetMember }: Props) => {
  const {
    control,
    setValue,
    getValues,
    formState: { dirtyFields },
  } = useFormContext<BookingCommonFormType>();
  const startDate = useWatch({ control, name: 'startDate' });
  const endDate = useWatch({ control, name: 'endDate' });
  const startTime = useWatch({ control, name: 'startTime' });
  const isSelectedFutureDate = useWatch({ control, name: 'isSelectedFutureDate' });
  const [isOpen, setIsOpen] = useState(isInitialOpen && !getValues('isCloseDateAccordion'));
  const [prevEndDate, setPrevEndDate] = useState(endDate);
  const periodValue = !startDate && !endDate ? undefined : [startDate, endDate];

  const accordionHeader = useMemo(() => {
    if (!startDate && !endDate) return '날짜 선택';
    return isRange
      ? `${filters.dateWithWeekday(startDate)} ~ ${endDate ? filters.dateWithWeekday(endDate) : ''}`
      : filters.dateWithWeekday(endDate);
  }, [endDate, isRange, startDate]);

  /** 시작일 종료일 같은 날짜 선택했으면 반복설정 초기화 */
  const resetRepeat = () => {
    setValue('repeat', null);
    setValue('repeatCustomWeekday', []);
    setValue('tempRepeatCustomWeekday', []);
  };

  const changePeriod = (date: Array<string>) => {
    const start = date[0] ? filters.dateDash(date[0]) : '';
    const end = date[1] ? filters.dateDash(date[1]) : '';
    setValue('startDate', start, { shouldDirty: true });
    setValue('endDate', end, { shouldDirty: true });
    if (start && end && (startDate !== start || endDate !== end)) {
      resetMember?.();
    }
    if (startDate === endDate) {
      resetRepeat();
    }
  };

  const cancelDialog = () => {
    setValue('startDate', prevEndDate);
    setValue('endDate', prevEndDate);
    setValue('isSelectedFutureDate', false);
  };

  const continueDialog = () => {
    setValue('isSelectedFutureDate', false, { shouldDirty: true });
  };

  /**
   * 지난 수업 1개 수정 시 수정시점 보다 이후를 선택하는 경우,
   * 회원 예약 취소됨 다이얼로그 노출
   */
  const showResetBookingDialog = (value: string) => {
    setPrevEndDate(endDate);
    setValue('startDate', value);
    setValue('endDate', value);
    setValue('isSelectedFutureDate', true);
    return;
  };

  /** 1개 수정 폼에서는 종료일만 선택 (시작일 동일함) */
  const changeEndDate = (date: string) => {
    const selectedDate = filters.dateDash(date);
    const selectedDateTime = `${selectedDate} ${filters.timeSecondZero(startTime)}`;
    const currentTime = filters.dateDashTimeSecond();
    const currentLectureStartOn = getValues('currentLecture.start_on');
    if (
      !isRange &&
      dayjs(currentLectureStartOn).isBefore(currentTime) &&
      dayjs(selectedDateTime).isAfter(currentTime) &&
      !dirtyFields.isSelectedFutureDate
    ) {
      showResetBookingDialog(selectedDate);
    } else {
      setValue('startDate', selectedDate, { shouldDirty: true });
      setValue('endDate', selectedDate, { shouldDirty: true });
    }
  };

  const changeDate = (e: { value: Array<string> | string }) => {
    if (isArray(e.value)) {
      changePeriod(e.value);
    } else {
      changeEndDate(e.value);
    }
  };
  return (
    <>
      <FormFieldAccordion
        iconName="calendar"
        isOpen={isOpen}
        onChange={() => {
          setIsOpen(!isOpen);
          if (isInitialOpen && !getValues('isCloseDateAccordion')) {
            setValue('isCloseDateAccordion', true);
          }
        }}
        header={accordionHeader}
        hasValue={!!startDate}>
        <DateCalendarPicker
          select={isRange ? 'range' : undefined}
          value={isRange ? periodValue : endDate}
          onChange={changeDate}
        />
      </FormFieldAccordion>
      {isSelectedFutureDate && <BookingResetConfirmDialog target="날짜" onCancel={cancelDialog} onContinue={continueDialog} />}
    </>
  );
};

export default BookingRangeDate;
