import dayjs from 'dayjs';
import useToast from 'hooks/useToast';
import { MemberCounselSelectedFilterType } from 'pages/Member/types';
import { useState } from 'react';
import PeriodFilter from 'sharedComponents/Filters/PeriodFilter';
import { FilterOptionsType, FilterProps } from 'sharedComponents/Filters/types';
import filters from 'utils/filters';

const CounselPeriodFilter = ({ currentFilter, selectedOption, onClick: changeOptions }: FilterProps) => {
  const { setToast } = useToast();
  const previousOptions = (selectedOption as MemberCounselSelectedFilterType['period']) ?? {
    start: filters.dateDash(dayjs()),
    end: filters.dateDash(dayjs()),
  };

  const [date, setDate] = useState<string[]>([previousOptions.start, previousOptions.end]);

  const setFixedPeriod = (option: FilterOptionsType) => {
    const unit = option.value;
    let startDate: string;
    const endDate = filters.dateDash(dayjs());
    if (unit === 30) {
      startDate = filters.dateDash(dayjs().subtract(1, 'month'));
    } else if (unit === 90) {
      startDate = filters.dateDash(dayjs().subtract(3, 'month'));
    } else if (unit === 180) {
      startDate = filters.dateDash(dayjs().subtract(6, 'month'));
    } else {
      startDate = filters.dateDash(dayjs());
    }

    setDate([startDate, endDate]);
    changeOptions({ value: { type: option.value as string, start: startDate, end: endDate }, label: '' }, 'period');
  };

  const saveCustomPeriod = (period: string[]) => {
    if (dayjs(period[0]).isBefore(dayjs(period[1]).subtract(2, 'year'))) {
      setToast({ message: '최대 24개월까지 조회할 수 있습니다.', bottom: 92 });
      if (date.length === 2) {
        setDate([date[0], date[1]]); // 기간선택 초기화
      }
      return true;
    }
    const dateFormatted = period.map(filters.dateDash);
    setDate(dateFormatted);
    changeOptions({ value: { type: 'custom', start: dateFormatted[0], end: dateFormatted[1] }, label: '' }, 'period');
    return false;
  };
  const initialDate = selectedOption ? [previousOptions.start, previousOptions.end] : ['', ''];

  return (
    <PeriodFilter
      description="최대 24개월까지 조회할 수 있습니다."
      currentFilter={currentFilter}
      selectedOption={selectedOption}
      onClick={setFixedPeriod}
      initialDate={initialDate}
      onSave={saveCustomPeriod}
    />
  );
};

export default CounselPeriodFilter;
