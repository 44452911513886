import { useQueryClient } from '@tanstack/react-query';
import { resetRecoilState } from 'constants/resetRecoilState';
import { MORE_DETAILS_TEXT } from 'pages/MoreDetails/Main/constants';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { staffIdAtom, studioIdAtom } from 'recoil/common';

import useGetStudio, { Studio } from './service/queries/useGetStudio';
import useResetAllRecoilState from './useResetAllRecoilState';
import useToast from './useToast';

const useChangeStudio = () => {
  const { data } = useGetStudio();
  const [currentStudioId, setCurrentStudioId] = useRecoilState(studioIdAtom);
  const setCurrentStaffId = useSetRecoilState(staffIdAtom);
  const resetAllRecoilState = useResetAllRecoilState(resetRecoilState);
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { setToast } = useToast();
  const redirectStudioNotification = (studioName: string) => {
    const selectedStudio = data.studios.find(studio => studio.name === studioName);
    const state = selectedStudio && currentStudioId !== selectedStudio.id ? { studio: selectedStudio } : undefined;

    navigate('/notification', { state });
  };

  const changeStudio = useCallback(
    (selectedStudio: Studio) => {
      resetAllRecoilState();
      if (!selectedStudio.id || !selectedStudio.staff_id) return;
      setCurrentStudioId(selectedStudio.id);
      setCurrentStaffId(selectedStudio.staff_id);
      if (currentStudioId !== selectedStudio.id) {
        queryClient.clear();
        setToast({ message: MORE_DETAILS_TEXT.changed, type: 'success', bottom: 76 });
      }
    },
    [currentStudioId, queryClient, resetAllRecoilState, setCurrentStaffId, setCurrentStudioId, setToast],
  );

  return { redirectStudioNotification, changeStudio };
};

export default useChangeStudio;
