type Params1 = {
  userTicketIds: Array<number>;
  prevUserTicketIds: Array<number>;
};
/** 기존 선택된 회원과 달라진 점 있는 지 비교하여 뒤로가기 했을 때 dialog 노출 여부 */
export const isCompareArrayDifferent = ({ userTicketIds, prevUserTicketIds }: Params1) => {
  if (userTicketIds.length !== prevUserTicketIds.length) {
    return true;
  }

  const sortedIds = [...userTicketIds].sort();
  const sortedTempIds = [...prevUserTicketIds].sort();

  for (let i = 0; i < sortedIds.length; i++) {
    if (sortedIds[i] !== sortedTempIds[i]) {
      return true;
    }
  }

  // 모든 요소가 같음
  return false;
};
