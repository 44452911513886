import { LectureDetailResponse } from 'hooks/service/queries/useGetLectureDetail';
import { BOOKING_COMMON_FORM_TEXT } from 'pages/Booking/constants';

import { BookingGroupCommonFormType } from '../../Group/types';
import { BookingCommonFormType } from '../../types';
import convertBookingFormCommonState from '../convertBookingFormCommonState';

type Response = Omit<BookingCommonFormType, 'repeat' | 'repeatCustomWeekday' | 'tempRepeatCustomWeekday'> &
  BookingGroupCommonFormType;

/** 그룹 수업 수정 폼들에 필요한 공통 상태 초기화 */
const convertBookingGroupUpdateCommonFormState = (lectureData: LectureDetailResponse): Response => {
  const { is_booking_only, max_trainee, min_trainee, waiting_trainee_limit, division_id, policy, room } = lectureData;

  return {
    ...convertBookingFormCommonState(lectureData),
    selectedRoom: room ? { id: room.id, name: room.name } : { id: -1, name: BOOKING_COMMON_FORM_TEXT.room.notSelected.label },
    is_booking_only,
    maxTrainee: max_trainee,
    minTrainee: min_trainee,
    waitingTrainee: waiting_trainee_limit ?? 0,
    divisionId: division_id ? String(division_id) : null,
    showWaitingField: !!policy.weekly_waiting_limit,
    showEnterField: lectureData.policy.is_enter,
  };
};

export default convertBookingGroupUpdateCommonFormState;
