import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import Divider from 'components/Divider';
import NumberPicker from 'components/NumberPicker';
import Radio from 'components/Radio';
import Typography from 'components/Typography';
import { MemberTicketFilterType } from 'pages/Member/types';
import { useState } from 'react';
import FilterTitle from 'sharedComponents/Filters/FilterTitle';
import { FilterWrap } from 'sharedComponents/Filters/StyledComponents';
import { FilterProps } from 'sharedComponents/Filters/types';
import PeriodPicker from 'sharedComponents/PeriodPicker';
import filters from 'utils/filters';

const TicketPeriodFilter = ({ currentFilter, selectedOption, onClick: changeOption }: FilterProps) => {
  const previousOption = selectedOption as MemberTicketFilterType | undefined;

  const initialSelected = previousOption?.count ? 'count' : previousOption?.date ? 'noCount' : 'notApplied';
  const [selected, setSelected] = useState(initialSelected);
  const [count, setCount] = useState<number | undefined>(previousOption?.count || 1);
  const [period, setPeriod] = useState<string[]>(previousOption?.date || []);

  const changePeriod = (period: string[], countApplied?: number) => {
    changeOption({ value: { date: period.map(filters.dateDash), count: countApplied }, label: '' }, 'ticket_remaining');
    return false;
  };

  const unset = () => {
    setSelected('notApplied');
    setPeriod([]);
    setCount(1);
    changeOption({ value: undefined, label: '' }, 'ticket_remaining');
  };

  const setNoCount = () => {
    setSelected('noCount');
    setCount(undefined);
    if (previousOption?.date) {
      changePeriod(previousOption.date);
    }
  };

  const setCounted = (countApplied?: number) => {
    setSelected('count');
    setCount(countApplied || 1);
    if (previousOption?.date) {
      changePeriod(previousOption.date, countApplied || 1);
    }
  };

  const isNotApplied = selected === 'notApplied';
  const isCountSelected = selected === 'count';
  const isNoCountSelected = selected === 'noCount';

  return (
    <>
      <Container>
        <FilterTitle title={currentFilter.title} />
        <PeriodPicker
          initialDate={previousOption?.date ?? []}
          onSave={period => changePeriod(period, count)}
          value={period}
          onChange={setPeriod}
          startDateDisabled={isNotApplied}
          endDateDisabled={isNotApplied}
        />
        <Radio
          id="notApplied"
          name="notApplied"
          label={<Typography size={14}>{currentFilter.options[0].label}</Typography>}
          onChange={unset}
          checked={isNotApplied}
        />
        <Radio
          id="noCount"
          name="noCount"
          label={<Typography size={14}>{currentFilter.options[1].label}</Typography>}
          onChange={setNoCount}
          checked={isNoCountSelected}
        />
        <Radio
          id="count"
          name="count"
          label={
            <LabelWrapper>
              <Typography size={14}>기간 내</Typography>
              <Count size={14} weight={500} isOpen={isCountSelected} disabled={!isCountSelected}>
                {count || 1}
              </Count>
              <Typography size={14}>회 이하로 남은 회원만 보기</Typography>
            </LabelWrapper>
          }
          onChange={() => setCounted()}
          checked={isCountSelected}
        />
      </Container>
      {isCountSelected && (
        <>
          <Divider thin thinColor="gray6" />
          <NumberPicker value={count || 1} onChange={e => setCounted(e.value)} />
        </>
      )}
    </>
  );
};

export default TicketPeriodFilter;

const Container = styled(FilterWrap)`
  label {
    :not(:last-of-type) {
      margin-bottom: 8px;
    }
    :first-of-type {
      margin-bottom: 12px;
    }
  }

  .period-picker {
    margin-bottom: 16px;
  }

  .radio {
    gap: 8px;
  }

  :disabled {
    color: ${theme.color.gray4};
  }
`;

const LabelWrapper = styled.div`
  ${theme.flex('', 'center', '', 6)};
`;

const Count = styled(Typography)<{ isOpen: boolean; disabled: boolean }>(
  css`
    ${theme.flex('', 'center', 'flex-start')}
    width: 43px;
    height: 32px;
    padding: 0 8px;
    border-radius: 6px;
    box-shadow: 0 0 0 1px ${theme.color.gray5};
  `,
  ({ isOpen }) =>
    isOpen &&
    css`
      box-shadow: 0 0 0 1px ${theme.color.primary};
    `,
  ({ disabled }) =>
    disabled &&
    css`
      background-color: ${theme.color.gray6};
      border-color: ${theme.color.gray6};
      color: ${theme.color.gray3};
    `,
);
