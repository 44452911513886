import { useQuery } from '@tanstack/react-query';

import useQueryFnWithCommonParams from '../common/useQueryFnWithCommonParams';

const useGetReportLecturesDashboard = (params: ReportLecturesDashboardParams) => {
  const { queryFn } = useQueryFnWithCommonParams<ReportLecturesDashboardResponse, ReportLecturesDashboardParams>({
    url: `/api/report/lectures/dashboard`,
    params: { ...params },
  });

  return useQuery({
    queryKey: ['report-lecture-dashboard', { ...params }],
    queryFn,
    select: data => data.data,
  });
};

export default useGetReportLecturesDashboard;

export type ReportLecturesDashboardParams = {
  /** 검색 시작일 */
  start_date: string;
  /** 검색 종료일 */
  end_date: string;
  /** 회원이름 검색 */
  search?: string;
  /** 수업유형 */
  class_type?: 'G' | 'P';
  /** 수강권 유형 */
  type?: string;
  /** 강사 검색 */
  staff_ids?: number[];
  /** 수강권 검색 */
  ticket_ids?: number[];
  /** 페이지네이션 종류 */
  paginate_type: string;
  /** 리스트 솔트 기준 */
  order_by?: string;
};

export type ReportLecturesDashboardResponse = {
  /** 차감제 */
  A: number;
  /** 기간제 */
  P: number;
  /** 횟수제 */
  T: number;
};
