/**
 * 프로필, 메모 이미지 등 이미지 업로드 hook
 * */

import { ChangeEvent, useState } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { hasMediaPermissionSelector } from 'recoil/permission';
import { toastAtom } from 'recoil/toast';
import { postMessageToApp } from 'utils/communicateWithNative';

export type ImgUrlsType = { id: number; imgUrl: string };
export type ImgFileType = { id: number; file: File };

const allowedFileTypes = ['image/jpg', 'image/jpeg', 'image/png'];

const usePhoto = (ref: React.RefObject<HTMLInputElement>, isProfile?: { isProfile: boolean }, toastPosition?: number) => {
  const [files, setFiles] = useState<Array<ImgFileType>>([]);
  const [imgUrls, setImgUrls] = useState<Array<ImgUrlsType>>([]);

  const setToast = useSetRecoilState(toastAtom);
  const hasPermission = useRecoilValue(hasMediaPermissionSelector);

  const onAddPhoto = () => {
    if (!hasPermission) {
      postMessageToApp('REQUEST_MEDIA_PERMISSION');
      return;
    }
    ref.current?.click();
  };

  const changeRefsFile = (newFiles: Array<ImgFileType>) => {
    if (!ref.current) return;

    const dataTransfer = new DataTransfer();
    newFiles.forEach(file => dataTransfer.items.add(file.file));
    ref.current.files = dataTransfer.files; // input 갱신
  };

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    const inputFiles = e.target.files;
    if (!inputFiles || !inputFiles.length) return;

    const id = Date.now();
    const inputFileArray = Array.from(inputFiles);

    if (inputFileArray.some(file => !allowedFileTypes.includes(file.type))) {
      setToast({ type: 'error', message: '지원하는 파일이 아닙니다.\n파일 형식을 확인해 주세요.', bottom: toastPosition });
      changeRefsFile(files); // 변경사항 취소
      return false;
    }

    if (isProfile) {
      setFiles([{ id, file: inputFiles[0] }]);
      setImgUrls([{ id, imgUrl: URL.createObjectURL(inputFiles[0]) }]);
      return;
    }

    if (imgUrls.length + inputFiles.length > 3) {
      setToast({ type: 'error', message: '사진은 최대 3개까지 업로드 가능합니다.', bottom: toastPosition });
      changeRefsFile(files); // 변경사항 취소
      return;
    }

    const tempFiles = [];
    const tempImgUrls: Array<ImgUrlsType> = [];
    for (let i = 0; i < inputFiles.length; i++) {
      tempFiles.push({ id, file: inputFiles[i] });
      tempImgUrls.push({ id, imgUrl: URL.createObjectURL(inputFiles[i]) });
    }

    setImgUrls([...imgUrls, ...tempImgUrls]);
    setFiles([...files, ...tempFiles]);
  };

  const onRemove = (item: ImgUrlsType, index: number) => {
    URL.revokeObjectURL(imgUrls[index].imgUrl);
    setImgUrls(imgUrls.filter((_, i) => i !== index));

    const removedFiles = files.filter(file => item.id !== file.id);
    setFiles(removedFiles); // 상태 갱신
    changeRefsFile(removedFiles); // input 내부 파일 갱신
  };

  return { files, imgUrls, onAddPhoto, onChange, onRemove, setFiles, setImgUrls };
};

export default usePhoto;
