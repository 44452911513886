import styled from '@emotion/styled';
import { MbscCalendarEventData } from '@mobiscroll/react';
import PullToRefresh from 'sharedComponents/PullToRefresh';

import ListTypeAgendaCard from './ListTypeAgendaCard';

type Props = {
  events: MbscCalendarEventData[];
  onRefresh: () => void;
  isRefetching: boolean;
};

const ListTypeAgendaList = ({ events, onRefresh, isRefetching }: Props) => {
  return (
    <StyledPullToRefresh isRefetching={isRefetching} onRefresh={onRefresh}>
      {events.map(event => (
        <ListTypeAgendaCard key={event.id} event={event} />
      ))}
    </StyledPullToRefresh>
  );
};

export default ListTypeAgendaList;

const StyledPullToRefresh = styled(PullToRefresh)`
  .spinner {
    margin-bottom: 10px;
  }
`;
