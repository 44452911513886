import { useSuspenseQuery } from '@tanstack/react-query';
import { isAxiosError } from 'axios';
import useErrorDialog from 'hooks/useErrorDialog';

import useQueryFnWithCommonParams from '../common/useQueryFnWithCommonParams';
import { WeekdayNum } from '../mutations/usePostBookingPrivate';

/** 룸 목록 반환 */
const useGetRoom = ({ isEnabled, ...params }: Params) => {
  const errorDialog = useErrorDialog();

  const { queryFn } = useQueryFnWithCommonParams<Array<RoomResponse>, RoomParams>({
    url: `/api/room`,
    params: { ...params },
  });

  return useSuspenseQuery({
    queryKey: ['room', isEnabled, { ...params }],
    queryFn: async () => {
      if (!isEnabled) return null;

      try {
        return await queryFn();
      } catch (error: unknown) {
        if (isAxiosError(error) && (error?.response?.status === 400 || error?.response?.status === 422)) {
          errorDialog.open(error);
          return null;
        }
        throw error;
      }
    },
    select: data => data?.data,
    gcTime: 0,
  });
};

export default useGetRoom;

export type RoomResponse = {
  id: number;
  studio_id: number;
  name: string;
  updated_for: number;
  created_at: string;
  updated_at: string;
  deleted_at: string | null;
};

export type RoomParams = {
  type: 'G' | 'P';
  start_date?: string; // 2024-12-06
  end_date?: string;
  start_time?: string; // 18:00:00
  end_time?: string;
  weekday?: Array<WeekdayNum>;
  /**
   * 수업 생성 때는 선택한 날짜, 시간에 따라 사용 가능한 룸목록만 반환,
   * 수업 수정 때는 전체 룸목록 반환
   */
  is_update?: number; // true 면 전체 룸목록 반환
};

export type Params = RoomParams & {
  /** 수업 생성 시, 룸 설정 기능을 사용하는 시설일 때만 룸 목록 호출 */
  isEnabled: boolean;
};
