import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import Avatar from 'components/Avatar';
import Typography from 'components/Typography';
import { MessageTargetType } from 'pages/MoreDetails/Message/types';
import filters from 'utils/filters';
import getImageUrl from 'utils/getImageUrl';

import { MOBILE_DETAIL_TEXT } from '../constants';

type Props = {
  targets: MessageTargetType[];
  total: number;
};
const TargetsDetailList = ({ targets, total }: Props) => {
  return (
    <>
      <StyledTypography size={17} weight={700}>
        {MOBILE_DETAIL_TEXT.targetDrawer.members} <b>{filters.numberComma(total)}</b>
      </StyledTypography>
      <Container>
        {targets.map(target => (
          <Row key={target.id}>
            <Avatar imgUrl={target.avatar ? getImageUrl(target.avatar) : undefined} size={24} />
            <Typography span weight={500} textColor="gray2" opacity={0.8} className="name">
              {target.name}
            </Typography>
            <Typography span textColor="gray2" opacity={0.8}>
              ({filters.contact(target.mobile)})
            </Typography>
          </Row>
        ))}
      </Container>
    </>
  );
};

export default TargetsDetailList;

const Container = styled.div`
  border-radius: 10px;
  background-color: ${theme.color.gray6};
  padding: 4px 16px;
  margin: 12px 0 0;
`;

const Row = styled.div`
  ${theme.flex('', 'center', 'start')}
  margin: 12px 0;
  span {
    flex-shrink: 0;
  }
  .name {
    margin-left: 8px;
    margin-right: 2px;
    flex-shrink: 1;
    ${theme.textEllipsis()}
  }
`;

const StyledTypography = styled(Typography)`
  b {
    color: ${theme.color.primary};
  }
`;
