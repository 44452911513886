import styled from '@emotion/styled';
import { useIsMutating } from '@tanstack/react-query';
import Typography from 'components/Typography';
import TwoLinedTitle from 'designedComponents/Drawers/DrawerTitle/TwoLinedTitle';
import DoubleButtonDrawer from 'designedComponents/Drawers/DrawerWithButton/DoubleButtonDrawer';
import { TicketEditFormType, TicketEditHasBookingsErrorType } from 'pages/TicketDetail/types';
import { useFormContext } from 'react-hook-form';
import { InfoFlexRow, InfoFlexWrapper } from 'sharedComponents/InfoTypography';
import { CommonStyleCard } from 'sharedComponents/StyledComponents';

type Props = {
  includedBookings: TicketEditHasBookingsErrorType[];
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (values: TicketEditFormType) => void;
};

/** 수강권 기간 수정 시 예약 내역 있을 때 노출 */
const ConfirmBookingCancelDrawer = ({ includedBookings, isOpen, onClose, onSubmit }: Props) => {
  const { handleSubmit } = useFormContext<TicketEditFormType>();
  const isMutating = useIsMutating() > 0;

  const submit = () => {
    handleSubmit(onSubmit)();
    onClose();
  };

  return (
    <DoubleButtonDrawer
      type={3}
      isOpen={isOpen}
      onClose={onClose}
      leftButton={{
        text: '취소',
        onClick: onClose,
        disabled: isMutating,
      }}
      rightButton={{
        text: '계속',
        onClick: submit,
        disabled: isMutating,
      }}
      header={<TwoLinedTitle title={['수강권 이용일 변경 시', '아래의 예약은 자동 취소됩니다.']} />}>
      <GrayWrapper bgColor="gray7" showBoxShadow={false}>
        <InfoFlexWrapper gap={8}>
          {includedBookings.map(({ status, msg }, index) => (
            <InfoFlexRow gap={6} key={`${index}-${status}`} className="flex-row">
              <Typography className="booking-status" span weight={500} textColor="gray2">
                {status}
              </Typography>
              <Typography className="booking-info" span textColor="gray2" ellipsis={1}>
                {msg}
              </Typography>
            </InfoFlexRow>
          ))}
        </InfoFlexWrapper>
      </GrayWrapper>
      <Typography size={15} weight={400}>
        계속 하시겠습니까?
      </Typography>
    </DoubleButtonDrawer>
  );
};

export default ConfirmBookingCancelDrawer;

const GrayWrapper = styled(CommonStyleCard)`
  padding: 16px;
  margin-bottom: 20px;
  .flex-row {
    .typography:last-of-type {
      text-align: left;
    }
  }
`;
