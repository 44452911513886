import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import LinkButton from 'components/Button/LinkButton';
import Typography from 'components/Typography';
import { PERMISSION } from 'constants/permission';
import Drawer from 'designedComponents/Drawers';
import OneLinedTitle from 'designedComponents/Drawers/DrawerTitle/OneLinedTitle';
import useGetPermissionDoHavePermission from 'hooks/service/queries/useGetPermissionDoHavePermission';
import { useNavigate } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';
import { errorBackStepAtom } from 'recoil/common';

import { CALENDAR_SETTING_DRAWER } from './constants';

type Props = {
  isOpen: boolean;
  onClose: () => void;
};

const CalendarSettingDrawer = ({ isOpen, onClose }: Props) => {
  const navigate = useNavigate();

  const { checkPermission } = useGetPermissionDoHavePermission();
  const setBackStep = useSetRecoilState(errorBackStepAtom);

  const goToSetting = (link: string) => {
    checkPermission(PERMISSION.facility.edit.id, {
      onSuccess: () => {
        navigate(link);
      },
      onError: () => {
        setBackStep({ to: '/', options: { replace: true } });
      },
    });
  };

  return (
    <Drawer isOpen={isOpen} header={<OneLinedTitle title="설정" />} onClose={onClose}>
      <DrawerContainer>
        {CALENDAR_SETTING_DRAWER.map(({ id, link, title, subTitle }) => (
          <LinkButton
            key={id}
            fontWeight={600}
            onClick={() => goToSetting(link)}
            showArrow={false}
            showBorder={false}
            padding={{ left: 0, right: 0 }}>
            <Typography size={17} weight={600}>
              {title}
            </Typography>
            <Typography size={14} textColor="gray2">
              {subTitle}
            </Typography>
          </LinkButton>
        ))}
      </DrawerContainer>
    </Drawer>
  );
};

const DrawerContainer = styled.div`
  ${theme.flex('column', '', '', 8)};

  .link-button-contents-wrapper {
    ${theme.flex('column', '', '', 2)};
  }
`;

export default CalendarSettingDrawer;
