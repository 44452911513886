import { PERMISSION } from 'constants/permission';
import useDeleteMessageMobile from 'hooks/service/mutations/useDeleteMessageMobile';
import usePostMessageMobileCancel from 'hooks/service/mutations/usePostMessageMobileCancel';
import usePopup from 'hooks/usePopup';
import MessageMenuButton from 'pages/MoreDetails/Message/components/MessageMenuButton';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import { MobileDetailType, MobileTargetType } from '../type';
import { convertMobileFormData } from '../utils';
import MobileTargetLoading from './MobileTargetLoading';

type Props = {
  message: MobileDetailType;
};
const MobileMenuButton = ({ message }: Props) => {
  /**
   * 즉시 발송, 예약취소문자 는 삭제만 가능
   * 예약문자는 수정, 취소 가능
   */
  const isDeletable = message.is_sent || !!message.canceled_at;
  const permissionNeeded = isDeletable ? PERMISSION.message.sms.delete : PERMISSION.message.sms.edit;

  const { mutate: deleteMutate } = useDeleteMessageMobile(message.id);
  const { mutate: cancelMutate } = usePostMessageMobileCancel(message.id);

  const navigate = useNavigate();
  const { setPopup } = usePopup();
  const editMessage = useCallback(() => {
    setPopup(
      <MobileTargetLoading
        id={message.id}
        onLoaded={(targets: MobileTargetType[]) => {
          navigate(`/more-details/message/mobile/form`, { state: convertMobileFormData(message, targets) });
          setPopup(null);
        }}
      />,
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [message]);

  const count = message.status.failed_count + message.status.success_count + message.status.processing_count;
  return (
    <MessageMenuButton
      isDeletable={isDeletable}
      permissionNeeded={permissionNeeded}
      deleteMutate={deleteMutate}
      cancelMutate={cancelMutate}
      editMessage={editMessage}
      message={{ count, sendOn: message.send_on, point: message.used_points }}
    />
  );
};

export default MobileMenuButton;
