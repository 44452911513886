import { NavigateOptions, To } from 'react-router-dom';
import { atom } from 'recoil';
import localStorageEffect from 'utils/localStorageEffect';

export const staffIdAtom = atom({
  key: 'staffId',
  default: 0,
  effects: [localStorageEffect('staff_id')],
});

export const studioIdAtom = atom({
  key: 'studioId',
  default: 0,
  effects: [localStorageEffect('studio_id')],
});

export const accessTokenAtom = atom<string | null>({
  key: 'accessToken',
  default: null,
  effects: [localStorageEffect('access_token')],
});

/** 계정통합 시 사용 */
export const accessTokenTempAtom = atom<string | null>({
  key: 'accessTokenTempAtom',
  default: null,
});

export const customBackHandlersAtom = atom<{ handler: () => void; key: string }[]>({
  key: 'customBackHandlersAtom',
  default: [],
});

export const errorBackStepAtom = atom<number | { to: To; options?: NavigateOptions } | null>({
  key: 'errorBackStepAtom',
  default: null,
});

export const navigationStackAtom = atom<string[]>({
  key: 'navigationStackAtom',
  default: [],
});
