import { useQuery } from '@tanstack/react-query';

import useQueryFnWithCommonParams from '../common/useQueryFnWithCommonParams';

const useGetReportPointDashboard = (params: ReportPointDashboardParams) => {
  const { queryFn } = useQueryFnWithCommonParams<ReportPointDashboardResponse, ReportPointDashboardParams>({
    url: `/api/report/point/dashboard`,
    params: { ...params },
  });

  return useQuery({
    queryKey: [`report/point/dashboard`, { ...params }],
    queryFn,
    select: data => data.data,
  });
};

export default useGetReportPointDashboard;

type PointInfo = {
  /** 횟수 */
  count: number;
  /** 포인트 */
  point: number;
};

export type ReportPointDashboardParams = {
  /** 검색 시작일 */
  start_date: string;
  /** 검색 종료일 */
  end_date: string;
};

export type ReportPointDashboardResponse = {
  /** 적립 */
  reward: PointInfo;
  /** 차감 */
  deduct: PointInfo;
  /** 소멸 */
  destruction: PointInfo;
  refund: {
    /** 횟수 */
    count: number;
    /** 환불 포인트 */
    paid_point: number;
    /** 회수 포인트 */
    reward_point: number;
  };
  /** 현재 잔여 포인트 */
  current: {
    point: number;
  };
};
