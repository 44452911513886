import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import Divider from 'components/Divider';
import { PRODUCT_TYPE_TEXT } from 'constants/text';
import dayjs from 'dayjs';
import { TicketEditFormType, UserTicketProps } from 'pages/TicketDetail/types';
import { useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import MainLayout from 'sharedComponents/MainLayout';
import TicketCard from 'sharedComponents/TicketCard';
import filters from 'utils/filters';
import formatSmallUserTicketCard from 'utils/formatSmallUserTicketCard';

import TicketEditForm from './TicketEditForm';

type Props = UserTicketProps & {
  currentStaffId: number;
};

const TicketEdit = ({ userTicket, currentStaffId }: Props) => {
  const {
    studio_id,
    user_id,
    max_coupon,
    remaining_cancel,
    remaining_coupon,
    availability_start_at,
    expire_at,
    is_shared,
    staffs,
  } = userTicket;

  const methods = useForm<TicketEditFormType>({
    defaultValues: {
      count: {
        max_coupon,
        remaining_cancel,
        remaining_coupon,
      },
      period: {
        availability_start_at: filters.dateDash(availability_start_at),
        expire_at: filters.dateDash(expire_at),
      },
      originAvailablePeriod: userTicket.ticket.available_period ?? 0,
      detail: {
        staffs,
        tempStaffs: staffs,
        is_shared,
        user_id,
        studio_id,
        staff_id: currentStaffId,
      },
    },
  });

  const isExpired = dayjs(expire_at).isBefore(dayjs());

  const ticket = useMemo(() => {
    const { bottomOptions, ...rest } = formatSmallUserTicketCard(userTicket);
    const expiredBottomOptions = bottomOptions.slice(1);
    if (isExpired) {
      return {
        ...rest,
        bottomOptions: expiredBottomOptions,
      };
    }
    return formatSmallUserTicketCard(userTicket);
  }, [isExpired, userTicket]);

  return (
    <MainLayout header={{ title: `${PRODUCT_TYPE_TEXT[userTicket.ticket.available_class_type]} 수정` }}>
      <FormProvider {...methods}>
        <Container>
          <TicketCard ticket={ticket} />
          <Divider />
          <TicketEditForm userTicket={userTicket} />
        </Container>
      </FormProvider>
    </MainLayout>
  );
};

export default TicketEdit;

const Container = styled.div`
  ${theme.flex('column', '', '')};
  min-height: 100%;

  form {
    flex: 1;
  }

  .ticket-card {
    padding-left: 20px;
    padding-right: 20px;
  }

  .staff-link-button {
    border-bottom: none;
  }
`;
