import { useSuspenseInfiniteQuery } from '@tanstack/react-query';
import { MobileTargetType } from 'pages/MoreDetails/Message/Mobile/Detail/type';

import useInfiniteQueryFnWithCommonParams from '../common/useInfiniteQueryFnWithCommonParams';

const useInfinityMessageMobileTargets = (id: number) => {
  const { queryFn } = useInfiniteQueryFnWithCommonParams<MessageMobileTargetsResponse, MessageMobileTargetsParams>({
    url: `/api/message/mobile/${id}/targets`,
    params: {
      paginate_type: 'detail',
    },
  });

  return useSuspenseInfiniteQuery({
    queryKey: [`message/mobile`, id, `targets`],
    queryFn,
    initialPageParam: 1,
    getNextPageParam: lastPage => {
      if (!lastPage.data.links.next) return null;
      return lastPage.data.meta.current_page + 1;
    },
    select: data => ({
      targets: data.pages.flatMap(page => page.data.data),
      total: data.pages[0].data.meta.total,
    }),
  });
};

export default useInfinityMessageMobileTargets;

type MessageMobileTargetsResponse = MobileTargetType;

type MessageMobileTargetsParams = {
  paginate_type: 'detail' | 'simple';
};
