import styled from '@emotion/styled';
import { Datepicker, localeKo, MbscDatepickerOptions } from '@mobiscroll/react';
import { upperCase } from 'lodash';
import { CommonWheelStyles } from 'sharedComponents/mobiscroll/CommonWheelStyles';

type Props = MbscDatepickerOptions & {
  rows?: number;
  gap?: number;
};

const DatePicker = ({ rows = 3, gap = 12, ...props }: Props) => {
  const hasDate = !props.dateFormat || upperCase(props.dateFormat)?.includes('D');

  return (
    <Container className="date-picker" hasDate={hasDate} gap={gap}>
      <Datepicker
        theme="none"
        controls={['date']}
        locale={localeKo}
        display="inline"
        touchUi={true}
        rows={rows}
        itemHeight={34}
        {...props}
      />
    </Container>
  );
};

const Container = styled(CommonWheelStyles)<{ hasDate: boolean; gap: number }>`
  background-color: white;
  width: 100%;

  .mbsc-scroller-wheel-line {
    height: 32px !important;
  }

  .mbsc-scroller-wheel-group {
    gap: ${({ gap }) => `${gap}px`};
  }

  .mbsc-scroller-wheel-wrapper {
    min-width: unset;
    flex: 1;
  }
  .mbsc-datetime-year-wheel .mbsc-scroller-wheel-item {
    margin-left: auto;
    width: 78px;
  }
  .mbsc-scroller-wheel-item {
    width: 47px;
  }

  ${({ hasDate }) =>
    hasDate &&
    `
    .mbsc-datetime-month-wheel {
      flex: unset;
    }
    `}
`;

export default DatePicker;
