import useGetLectureCourse from 'hooks/service/queries/useGetLectureCourse';
import useGetLectureDetail from 'hooks/service/queries/useGetLectureDetail';
import useCustomBookingForm from 'hooks/useCustomBookingForm';
import { convertBookingGroupUpdateAllFormState } from 'pages/Booking/utils';

import { BookingGroupCreateFormType } from '../../types';
import GroupCommonCreateForm from '../components/GroupCommonCreateForm';
import GroupFormMainLayout from '../components/GroupFormMainLayout';

type Props = {
  currentLectureId: number;
};

const FormMain = ({ currentLectureId }: Props) => {
  const { data: lectureData } = useGetLectureDetail(currentLectureId);
  const { data: courseData } = useGetLectureCourse(lectureData.course_id);

  const methods = useCustomBookingForm<BookingGroupCreateFormType>({
    defaultValues: { ...convertBookingGroupUpdateAllFormState({ lectureData, courseData }), showDescriptionErrorUpdateAll: true },
  });

  return (
    <GroupFormMainLayout {...methods} pageMode="copy">
      <GroupCommonCreateForm pageMode="copy" />
    </GroupFormMainLayout>
  );
};

export default FormMain;
