import usePostMember from 'hooks/service/mutations/usePostMember';
import { useMemberForm } from 'hooks/useMemberForm';

import CommonMemberForm from '../components/CommonMemberForm';

type Props = {
  isUserGrade: boolean;
};

const MemberCreateForm = ({ isUserGrade }: Props) => {
  const { mutate: createMemberMutate } = usePostMember();
  const { onSubmit, isUploadingImage } = useMemberForm({ pageMode: 'create', mutationFunc: createMemberMutate });

  return <CommonMemberForm pageMode="create" onSubmit={onSubmit} isUserGrade={isUserGrade} isUploadingImage={isUploadingImage} />;
};

export default MemberCreateForm;
