import { useQuery } from '@tanstack/react-query';
import { MobileTargetType } from 'pages/MoreDetails/Message/Mobile/Detail/type';

import useGetAllQueryFnWithCommonParams from '../common/useGetAllQueryFnWithCommonParams';

const useGetAllMessageMobileTargets = (id: number, enabled: boolean) => {
  const { queryFn } = useGetAllQueryFnWithCommonParams<AllMessageMobileTargetsResponse, AllMessageMobileTargetsParams>({
    url: `/api/message/mobile/${id}/targets`,
    params: { limit: 50 },
  });

  return useQuery({
    queryKey: [`message/mobile`, id, `targets`, 'all', enabled],
    queryFn,
    enabled,
    select: data => data.data,
    gcTime: 0,
  });
};

export default useGetAllMessageMobileTargets;

type AllMessageMobileTargetsResponse = MobileTargetType[];

type AllMessageMobileTargetsParams = {
  limit: number;
};
