import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import Typography from 'components/Typography';
import { forwardRef, ReactNode } from 'react';

import Textarea, { TextareaProps } from '.';

export type Props = Omit<TextareaProps, 'height' | 'maxHeight' | 'currentLength' | 'size' | 'label' | 'hasSuffix'> & {
  /** 왼쪽 아이콘 등, Textfield와 통일시킴 */
  left?: ReactNode;
  /** 아래 설명, Textfield와 통일시킴 */
  description?: string[];
  /** 하단 에러 메세지, 글자수 노출 여부 */
  showBottom?: boolean;
};

/** UnderlinedTextfield와 유사한 Textarea */
const UnderlinedTextarea = forwardRef<HTMLTextAreaElement, Props>(
  ({ left, description, autoHeight = true, rows = 1, showBottom = true, ...props }, ref) => {
    const { readOnly, disabled } = props;

    return (
      <Container className="underline-textarea" disabled={disabled} readOnly={readOnly}>
        <TextareaWrapper showBottom={showBottom}>
          {left && left}
          <StyledTextarea ref={ref} {...props} rows={rows} autoHeight={autoHeight} suffixMarginRight={20} hasSuffix />
        </TextareaWrapper>

        {description && (
          <Descriptions>
            {description?.map(text => (
              <Typography key={text} textColor="gray2" size={14}>
                {text}
              </Typography>
            ))}
          </Descriptions>
        )}
      </Container>
    );
  },
);

UnderlinedTextarea.displayName = 'UnderlinedTextarea';
export default UnderlinedTextarea;

const Container = styled.div<Pick<Props, 'readOnly' | 'disabled'>>`
  padding: 16px 0px 16px 20px;
  border-bottom: solid 1px ${theme.color.gray8};
  svg {
    flex: 0 0 auto;
  }
  ${({ disabled }) =>
    disabled &&
    ` 
      background-color: ${theme.color.gray8};
      textarea {
        background-color: ${theme.color.gray8} !important;
      }
      svg {
        color: ${theme.color.gray4} !important;
      }
    `}
  ${({ readOnly }) =>
    readOnly &&
    ` svg {
        color: ${theme.color.gray3} !important;
      }`}
`;

const TextareaWrapper = styled.div<Pick<Props, 'showBottom'>>(
  css`
    ${theme.flex('row', 'start', 'space-between')};
  `,

  ({ showBottom }) =>
    !showBottom &&
    css`
      padding-bottom: 0;
      .textarea-wrapper {
        padding-bottom: 0;
      }

      .textarea-bottom-wrapper,
      .count-wrapper {
        display: none;
      }
    `,
);

const StyledTextarea = styled(Textarea)<Pick<Props, 'readOnly' | 'disabled'>>`
  width: 100%;

  .textarea-wrapper {
    ${theme.flex('row', 'start', '')};
    height: unset;
  }
  textarea {
    padding: 1px 10px 0;
    border-radius: 0;
    min-height: 24px;
  }
  textarea::placeholder {
    color: ${theme.color.gray3};
  }
  textarea + div {
    border: none;
  }
  textarea:read-only {
    color: ${theme.color.gray3};
    background-color: ${theme.color.white};
    pointer-events: none;

    & + div {
      background-color: ${theme.color.white};
      border-color: ${theme.color.white};
    }
  }
`;

const Descriptions = styled.div`
  padding: 8px 20px 0 34px;
`;
