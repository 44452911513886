import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import Button from 'components/Button';
import IconButton from 'components/Button/IconButton';
import Icon from 'components/Icon';
import { PERMISSION } from 'constants/permission';
import useGetPermissionDoHavePermission from 'hooks/service/queries/useGetPermissionDoHavePermission';
import useGetStudio from 'hooks/service/queries/useGetStudio';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { studioIdAtom } from 'recoil/common';
import { headerDatePickerOpenAtom } from 'recoil/schedule';

import CalendarSettingDrawer from './drawer/CalendarSettingDrawer';
import StudioSelectDrawer from './drawer/StudioSelectDrawer';

const ScheduleHeader = () => {
  const navigate = useNavigate();
  const { checkPermission } = useGetPermissionDoHavePermission();
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [isSettingDrawerOpen, setIsSettingDrawerOpen] = useState(false);

  const currentStudioId = useRecoilValue(studioIdAtom);
  const setIsDatePickerOpen = useSetRecoilState(headerDatePickerOpenAtom);

  const {
    data: { studios },
  } = useGetStudio();

  const currentStudioName = studios.find(studio => studio.id === currentStudioId)?.name;

  const openStudioSelectDrawer = () => {
    setIsDatePickerOpen(false);
    setIsDrawerOpen(true);
  };

  const openSettingDrawer = () => {
    checkPermission(PERMISSION.facility.edit.id, {
      onSuccess: () => setIsSettingDrawerOpen(true),
      onError: () => {
        navigate('/schedule/calendar-setting');
        return true;
      },
    });
  };

  return (
    <>
      <Container>
        <Button
          fontSize={15}
          fontWeight={600}
          textColor="white"
          rightIcon={<Icon name="arrowBottomFill" fillColor={theme.color.white} size={16} />}
          onClick={openStudioSelectDrawer}>
          {currentStudioName}
        </Button>

        <div className="setting-button">
          <IconButton onClick={openSettingDrawer}>
            <Icon name="headerSetting" color="white" />
          </IconButton>
        </div>
      </Container>

      <StudioSelectDrawer isOpen={isDrawerOpen} onClose={() => setIsDrawerOpen(false)} studios={studios} />
      <CalendarSettingDrawer isOpen={isSettingDrawerOpen} onClose={() => setIsSettingDrawerOpen(false)} />
    </>
  );
};

export default ScheduleHeader;

const Container = styled.section`
  position: sticky;
  top: 0;
  ${theme.flex()};
  padding: 17px 20px;

  .setting-button {
    position: absolute;
    right: 20px;
  }
`;
