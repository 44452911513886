import { useIsMutating } from '@tanstack/react-query';
import { useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import FooterButton from 'sharedComponents/FooterButton';

import { MEMBER_FORM_TEXT } from '../constants';
import { MemberFormType } from '../types';
import isDirtyMemberForm from '../utils/isDirtyMemberForm';
import isEqualUserTickets from '../utils/isEqualUserTickets';

type Props = {
  pageMode: 'create' | 'update';
  disabled?: boolean;
};

const SubmitButton = ({ pageMode, disabled }: Props) => {
  const {
    formState: { dirtyFields },
    watch,
  } = useFormContext<MemberFormType>();
  const {
    profile: { name },
    tickets,
    userTickets,
    initialUserTickets,
  } = watch();
  const isDirty = isDirtyMemberForm(dirtyFields);
  const isMutating = useIsMutating() > 0;

  const buttonDisabled = useMemo(() => {
    if (!name || disabled || isMutating) return true;
    if (pageMode === 'create' && !isDirty && !!name) return false; // 상담 상세에서 회원 등록으로 넘어온 경우
    if (!isDirty && !tickets.length && isEqualUserTickets(userTickets, initialUserTickets)) return true;
    return false;
  }, [initialUserTickets, isDirty, isMutating, name, pageMode, tickets.length, userTickets, disabled]);

  return (
    <FooterButton type="submit" disabled={buttonDisabled}>
      {MEMBER_FORM_TEXT.footerButton[pageMode]}
    </FooterButton>
  );
};

export default SubmitButton;
