import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { color, theme } from 'assets/styles';
import Avatar from 'components/Avatar';
import AvatarGroup from 'components/Avatar/AvatarGroup';
import Icon from 'components/Icon';
import Typography from 'components/Typography';
import { EtcScheduleDetailResponse } from 'hooks/service/queries/useGetEtcScheduleEtcScheduleTime';
import { useMemo } from 'react';
import { useRecoilValue } from 'recoil';
import { staffIdAtom } from 'recoil/common';
import convertTargetsText from 'utils/convertTargetsText';
import filters from 'utils/filters';
import getImageUrl from 'utils/getImageUrl';

type Props = {
  isBulk: boolean;
  bgColor: keyof typeof color;
  contrastColor: keyof typeof color;
  canViewEtcSchedule: boolean;
  currentEtcSchedule: EtcScheduleDetailResponse;
};

/** 기타일정 상세 페이지 상단 컬러 영역  */
const ColoredDetailInfo = ({ isBulk, currentEtcSchedule, bgColor, contrastColor, canViewEtcSchedule }: Props) => {
  const { is_private, title, is_all_day, start_on, end_on, register, staffs } = currentEtcSchedule;
  const currentStaffId = useRecoilValue(staffIdAtom);
  const date = isBulk ? filters.periodWithWeekday(start_on, end_on) : filters.dateWithWeekday(start_on);
  const time = isBulk || is_all_day ? null : filters.timePeriod(start_on, end_on);

  const staffsWithRegister = useMemo(() => {
    const allStaffs = [register, ...staffs];

    // '현재 로그인 한 강사명 외 N명' 으로 표기
    if (register.id !== currentStaffId) {
      const currentStaffIndex = allStaffs.findIndex(staff => staff.id === currentStaffId);
      if (currentStaffIndex !== -1) {
        const [item] = allStaffs.splice(currentStaffIndex, 1);
        allStaffs.unshift(item);
      }
    }
    return allStaffs;
  }, [currentStaffId, register, staffs]);

  return (
    <Container className="lecture-detail-colored-wrapper" bgColor={bgColor}>
      <TitleWrapper>
        {is_private && <Icon name="lockRegular" color={contrastColor} />}
        <Typography size={21} weight={700} textColor={contrastColor}>
          {title}
        </Typography>
      </TitleWrapper>
      <Typography weight={500} textColor={contrastColor} opacity={0.8}>
        {date} {time}
      </Typography>

      <StaffsWrapper>
        <AvatarGroup AvatarSize={16} oneAvatarSize={16}>
          {staffsWithRegister.map(staff => (
            <Avatar size={16} key={staff.id} imgUrl={staff.image ? getImageUrl(staff.image) : undefined} />
          ))}
        </AvatarGroup>
        <Typography textColor={contrastColor} weight={500}>
          {convertTargetsText(staffsWithRegister, '', false)}
        </Typography>
      </StaffsWrapper>
    </Container>
  );
};

export default ColoredDetailInfo;

const Container = styled.div<{ bgColor: keyof typeof color }>(
  ({ bgColor }) => css`
    background-color: ${theme.color[bgColor]};
    padding: 8px 20px 16px;
  `,
);

const TitleWrapper = styled.div`
  ${theme.flex('row', 'flex-start', 'flex-start', 4)};
  padding-bottom: 2px;
`;

const StaffsWrapper = styled.div`
  margin-top: 32px;
  ${theme.flex('row', 'center', 'flex-start', 4)};
`;
