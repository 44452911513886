import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import useGetReportSaleDashboard, { ReportSaleDashboardParams } from 'hooks/service/queries/useGetReportSaleDashboard';
import { useRecoilValue } from 'recoil';
import { reportSalePeriodAtom } from 'recoil/report/atom';
import filters from 'utils/filters';

import SummaryListCard from './SummaryListCard';
import SummaryTotalCard from './SummaryTotalCard';

const ReportSaleSummaryList = () => {
  const reportSalePeriod = useRecoilValue(reportSalePeriodAtom);

  const saleParam: ReportSaleDashboardParams = {
    start_date: reportSalePeriod.period?.start || filters.dateDash(),
    end_date: reportSalePeriod.period?.end || filters.dateDash(),
  };

  const { data: reportSaleData } = useGetReportSaleDashboard(saleParam);

  return (
    <Container>
      <TotalSection>
        <SummaryTotalCard reportSaleData={reportSaleData} />
      </TotalSection>
      <SalesSection>
        <SummaryListCard emoji="📂" isSellInfo={true} saleData={reportSaleData?.sale} />
      </SalesSection>
      <RefundSection>
        <SummaryListCard emoji="⛔️" isSellInfo={false} saleData={reportSaleData?.refund} />
      </RefundSection>
    </Container>
  );
};

export default ReportSaleSummaryList;

const Container = styled.div`
  padding: 20px;
  background: ${theme.color.gray6};
`;

const TotalSection = styled.section`
  padding-bottom: 16px;
`;
const SalesSection = styled.section`
  padding-bottom: 16px;
`;
const RefundSection = styled.section``;
