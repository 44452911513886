import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import Typography from 'components/Typography';

const NoEvents = () => {
  return (
    <Container>
      <Typography size={15} textColor="gray2">
        등록된 일정이 없습니다.
      </Typography>
    </Container>
  );
};

export default NoEvents;

const Container = styled.div`
  ${theme.flex()};
  height: 264px;
`;
