import { useRecoilValue } from 'recoil';
import { studioIdAtom } from 'recoil/common';
import ApiBoundary from 'sharedComponents/Boundaries/ApiBoundary';
import StudioException from 'sharedComponents/StudioException';
import localStorage from 'utils/localStorage';

import MemberList from './components/MemberList';
import MemberSkeleton from './components/MemberSkeleton';
import NoConnected from './components/NoConnected';

const Member = () => {
  const isStudioSelected = useRecoilValue(studioIdAtom) !== 0 && !!localStorage.get('access_token');
  if (!isStudioSelected)
    return (
      <ApiBoundary>
        <StudioException noConnectedBackground={<NoConnected />} />
      </ApiBoundary>
    );

  return (
    <ApiBoundary fallback={<MemberSkeleton />}>
      <MemberList />
    </ApiBoundary>
  );
};

export default Member;
