import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import Accordion from 'components/Accordion';
import Button from 'components/Button';
import IconButton from 'components/Button/IconButton';
import Divider from 'components/Divider';
import Icon from 'components/Icon';
import Typography from 'components/Typography';
import { ReportSaleDashboardResponse } from 'hooks/service/queries/useGetReportSaleDashboard';
import { useState } from 'react';
import filters from 'utils/filters';

import ReportSaleTotalDialog from './ReportSaleTotalDialog';

type Props = { reportSaleData?: ReportSaleDashboardResponse };

const SummaryTotalCard = ({ reportSaleData }: Props) => {
  const [showDetailInfo, setShowDetailInfo] = useState(false);
  const [isOpenTooltip, setIsOpenTooltip] = useState(false);

  if (!reportSaleData) {
    return null;
  }

  const totalAmountTicket = reportSaleData.sale.G.amount + reportSaleData.sale.P.amount;
  const totalAmountSale = reportSaleData.sale.S.amount + reportSaleData.sale.R.amount;
  const totalSaleCommission =
    reportSaleData.sale.G.commission_amount +
    reportSaleData.sale.P.commission_amount +
    reportSaleData.sale.S.commission_amount +
    reportSaleData.sale.R.commission_amount;
  const totalRefundCommission =
    reportSaleData.refund.G.commission_amount +
    reportSaleData.refund.P.commission_amount +
    reportSaleData.refund.S.commission_amount +
    reportSaleData.refund.R.commission_amount;

  const totalAmountRefund =
    reportSaleData.refund.G.amount +
    reportSaleData.refund.P.amount +
    reportSaleData.refund.S.amount +
    reportSaleData.refund.R.amount;
  const totalCount =
    reportSaleData.sale.G.count +
    reportSaleData.sale.P.count +
    reportSaleData.sale.S.count +
    reportSaleData.sale.R.count +
    reportSaleData.refund.G.count +
    reportSaleData.refund.P.count +
    reportSaleData.refund.S.count +
    reportSaleData.refund.R.count;

  const openDetailInfo = () => {
    setShowDetailInfo(!showDetailInfo);
  };

  return (
    <Container>
      <HeaderSection>
        <div className="total-count-wrapper">
          <Typography size={14} weight={500} textColor="white" opacity={0.8}>{`합계(${totalCount}건)`}</Typography>
          <IconButton widthSize={16} onClick={() => setIsOpenTooltip(prev => !prev)}>
            <StyledIcon name="information" size={14} fillColor="white" />
          </IconButton>
        </div>
        <Typography span size={24} weight={700} textColor={'white'}>
          {`${filters.numberComma(totalAmountTicket + totalAmountSale + totalSaleCommission + totalRefundCommission - totalAmountRefund)}`}
        </Typography>
        <Typography span size={21} weight={700} textColor={'white'}>
          원
        </Typography>
      </HeaderSection>
      <Divider className="divider" thin />
      <Accordion isOpen={showDetailInfo} showArrow={false} size="none">
        <BodySection>
          <div className="report-info-text-wrapper">
            <Typography size={15} weight={500} textColor="white" opacity={0.72}>
              수강권
            </Typography>
            <Typography size={15} weight={600} textColor="white">
              {`${filters.numberComma(totalAmountTicket)}원`}
            </Typography>
          </div>
          <div className="report-info-text-wrapper">
            <Typography size={15} weight={500} textColor="white" opacity={0.72}>
              상품
            </Typography>
            <Typography size={15} weight={600} textColor="white">
              {`${filters.numberComma(totalAmountSale)}원`}
            </Typography>
          </div>
          <div className="report-info-text-wrapper">
            <Typography size={15} weight={500} textColor="white" opacity={0.72}>
              환불
            </Typography>
            <Typography size={15} weight={600} textColor="white">
              {`${totalAmountRefund ? '-' : ''} ${filters.numberComma(totalAmountRefund)}원`}
            </Typography>
          </div>
        </BodySection>
      </Accordion>
      {showDetailInfo && <Divider className="divider" thin />}
      <FooterSection>
        <Button
          onClick={openDetailInfo}
          rightIcon={
            <Icon
              className="report-sale-arrow"
              name={!showDetailInfo ? 'arrowBottomBold' : 'arrowTopBold'}
              size={16}
              color="white"
            />
          }>
          <Typography textColor="white" size={13} weight={500} opacity={0.72}>
            {!showDetailInfo ? '자세히 보기' : '접기'}
          </Typography>
        </Button>
      </FooterSection>
      {isOpenTooltip && <ReportSaleTotalDialog onClose={() => setIsOpenTooltip(prev => !prev)} />}
    </Container>
  );
};

export default SummaryTotalCard;

const Container = styled.div`
  background-color: ${theme.color.point};
  border-radius: 20px;

  .divider {
    background-color: ${theme.color.white};
    opacity: 0.08;
  }
`;

const HeaderSection = styled.section`
  padding: 24px 20px 16px 20px;
  .total-count-wrapper {
    ${theme.flex('', 'center', '', 4)}
    padding-bottom: 6px;
  }
`;

const BodySection = styled.section`
  padding: 20px;
  .report-info-text-wrapper {
    ${theme.flex('', '', 'space-between')}
    :not(:last-of-type) {
      padding-bottom: 10px;
    }
  }
`;

const FooterSection = styled.section`
  ${theme.flex('', 'center', 'center')};
  padding: 16px 0px;

  .report-sale-arrow {
    opacity: 0.48;
  }
`;

const StyledIcon = styled(Icon)`
  opacity: 0.8;
`;
