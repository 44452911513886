import Textarea from 'components/Textarea';
import OutlinedTextFieldController from 'components/TextField/OutlinedTextFieldController';
import { Controller, useFormContext } from 'react-hook-form';
import { smsByteCheck } from 'utils/smsByteCheck';

import MessageFormLabel from '../../../components/MessageFormLabel';
import { MOBILE_FORM_TEXT } from '../constants';
import { MobileFormType } from '../types';

type Props = {
  hasTitleTooltip?: boolean;
  titlePlaceholder?: string;
  messagePlaceholder?: string;
  maxLength?: number;
};

const TitleAndMessage = ({ hasTitleTooltip = true, titlePlaceholder, messagePlaceholder, maxLength }: Props) => {
  const { control, setValue } = useFormContext<MobileFormType>();

  return (
    <>
      <MessageFormLabel
        labelText={MOBILE_FORM_TEXT.mobileTitle}
        hasTooltip={hasTitleTooltip}
        tooltipMessages={MOBILE_FORM_TEXT.titleTooltip}
      />
      <OutlinedTextFieldController
        controllerProps={{ control, name: 'title' }}
        placeholder={titlePlaceholder || MOBILE_FORM_TEXT.titlePlaceholder}
        className="title"
        onKeyDown={e => {
          if (e.key === 'Enter') {
            e.preventDefault();
          }
        }}
        onClear={() => setValue('title', '', { shouldValidate: true })}
      />
      <MessageFormLabel labelText={MOBILE_FORM_TEXT.message} className="message-label" />
      <Controller
        name="message"
        render={({ field, fieldState }) => (
          <Textarea
            {...field}
            placeholder={messagePlaceholder || MOBILE_FORM_TEXT.messagePlaceholder}
            height={210}
            maxLength={maxLength}
            currentLength={smsByteCheck(field.value)}
            errorMessage={fieldState.error?.message}
          />
        )}
      />
    </>
  );
};

export default TitleAndMessage;
