import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import Button from 'components/Button';
import IconButton from 'components/Button/IconButton';
import DatePicker from 'components/DatePicker';
import Icon from 'components/Icon';
import Label from 'components/Label';
import Tooltip from 'components/Tooltip';
import dayjs from 'dayjs';
import useGetFilterStaffs from 'hooks/service/queries/useGetFilterStaffs';
import { HolidayTargetResponse } from 'hooks/service/queries/useGetHolidayTarget';
import usePopup from 'hooks/usePopup';
import { isEqual } from 'lodash';
import { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import {
  calendarViewTypeAtom,
  currentDateAtom,
  headerDatePickerOpenAtom,
  listCalendarTypeAtom,
  selectedFiltersAtom,
} from 'recoil/schedule';
import ApiBoundary from 'sharedComponents/Boundaries/ApiBoundary';
import filters from 'utils/filters';
import localStorage from 'utils/localStorage';

import { TOOLTIP_MESSAGE } from '../constants';
import { CurrentPeriodTyps } from '../types';
import ViewTypeSelectDropdown from './dropdown/ViewTypeSelectDropdown';
import FilterSettings from './popupPage/FilterSettings';

type Props = {
  holidayTargets: HolidayTargetResponse;
  currentPeriod: CurrentPeriodTyps;
  setCurrentPeriod: Dispatch<SetStateAction<CurrentPeriodTyps>>;
};

const CustomCalendarHeader = ({ holidayTargets, currentPeriod, setCurrentPeriod }: Props) => {
  const [isDatePickerOpen, setIsDatePickerOpen] = useRecoilState(headerDatePickerOpenAtom);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isTooltipOpen, setIsTooltipOpen] = useState(localStorage.get('showListTypeTooltip') !== 'hide');

  const calendarViewType = useRecoilValue(calendarViewTypeAtom);
  const selectedFilters = useRecoilValue(selectedFiltersAtom);
  const [currentDate, setCurrentDate] = useRecoilState(currentDateAtom);
  const [listCalendarType, setListCalendarType] = useRecoilState(listCalendarTypeAtom);

  const { setPopup } = usePopup();

  const { data: allStaffs, isLoading } = useGetFilterStaffs();

  useEffect(() => {
    if (!localStorage.get('showListTypeTooltip')) {
      setIsTooltipOpen(true);
    } else {
      setIsTooltipOpen(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const changeDate = (e: { value: Date }) => setCurrentDate(e.value);

  const openFilterPopupPage = () => {
    setPopup(
      <ApiBoundary>
        <FilterSettings allStaffs={allStaffs} setCurrentPeriod={setCurrentPeriod} />
      </ApiBoundary>,
    );
  };

  const headerDateText = useMemo(() => {
    const format = calendarViewType === 'day' ? 'MM.DD (ddd)' : 'YYYY.MM';
    return dayjs(currentDate).format(format);
  }, [calendarViewType, currentDate]);

  const isApplyFilter = useMemo(() => {
    const isSchedulesDirty = !isEqual(selectedFilters.schedules, ['all']);
    const isStaffsDirty = !isEqual(selectedFilters.staffs, ['all']);
    return isSchedulesDirty || isStaffsDirty;
  }, [selectedFilters]);

  const isShowHolidayLabel = useMemo(() => {
    /** 주간, 월간은 헤더가 아니라 캘린더 자체에 표기 */
    if (calendarViewType !== 'day') return false;
    return holidayTargets[0] === filters.dateDash(currentDate);
  }, [calendarViewType, currentDate, holidayTargets]);

  const isToday = useMemo(() => {
    if (calendarViewType === 'week' || (calendarViewType === 'list' && listCalendarType === 'week')) {
      const isTodaySameWeek = currentPeriod
        ? dayjs().isBetween(currentPeriod.startDate, currentPeriod.endDate, 'day', '[]')
        : false;
      return isTodaySameWeek;
    }
    return filters.dateDash() === filters.dateDash(currentDate);
  }, [calendarViewType, currentDate, currentPeriod, listCalendarType]);

  return (
    <Container>
      <div className="header-wrapper">
        <div className="header-days-controls">
          <Button
            fontSize={19}
            fontWeight={700}
            textColor="gray1"
            rightIcon={<Icon name={isDatePickerOpen ? 'arrowTopBold' : 'arrowBottomBold'} size={16} />}
            onClick={() => setIsDatePickerOpen(!isDatePickerOpen)}>
            {headerDateText}
          </Button>

          {calendarViewType === 'list' && (
            <Button
              onClick={() => {
                setListCalendarType(listCalendarType === 'week' ? 'month' : 'week');
              }}>
              <Label className="label-radius" size="regular" minWidth={32} textColor="gray2" borderColor="gray5">
                {listCalendarType === 'week' ? '월' : '주'}
              </Label>
            </Button>
          )}

          {isShowHolidayLabel && (
            <Label size="small" minWidth={32} textColor="secondary3" color="secondary3" opacity={0.16}>
              휴일
            </Label>
          )}
        </div>

        <div className="icons-wrapper">
          {!isToday && (
            <Button
              onClick={() => {
                setCurrentDate(new Date());
                setCurrentPeriod({ startDate: new Date(), endDate: new Date() });
              }}>
              <Label className="label-radius" size="regular" minWidth={40} textColor="gray2" color="gray6">
                오늘
              </Label>
            </Button>
          )}

          <IconButton
            className={isApplyFilter ? 'apply-filter' : undefined}
            onClick={isLoading ? undefined : openFilterPopupPage}>
            <Icon name="headerFilter" />
          </IconButton>
          <StyledTooltip
            position="right"
            isOpen={isTooltipOpen}
            message={TOOLTIP_MESSAGE.filterSettings}
            onClose={() => {
              setIsTooltipOpen(false);
              localStorage.set('showListTypeTooltip', 'hide');
            }}
            showCloseButton>
            <ViewTypeSelectDropdown isOpen={isDropdownOpen} setIsOpen={setIsDropdownOpen} />
          </StyledTooltip>
        </div>
      </div>

      {isDatePickerOpen && <DatePicker dateFormat={'YYYY/MM/DD'} value={currentDate} onChange={changeDate} />}
    </Container>
  );
};

export const Container = styled.div`
  width: 100%;

  .header-wrapper {
    ${theme.flex('row', 'center', 'space-between')}
    margin: 0 20px;
    padding: 20px 0;
    background-color: ${theme.color.white};

    .header-days-controls {
      ${theme.flex('row', 'center', '', 8)};
    }
  }

  .label-radius {
    border-radius: 999px !important;
  }

  .icons-wrapper {
    ${theme.flex('', '', '', 12)};

    .apply-filter {
      position: relative;

      &::after {
        content: '';
        position: absolute;
        right: 0;
        top: 0;
        width: 4px;
        height: 4px;
        background-color: ${theme.color.secondary3};
        border-radius: 50%;
      }
    }
  }

  .date-picker {
    position: absolute;
    width: 100%;
    left: 0;
  }

  .mbsc-datepicker-tab-wrapper .mbsc-datepicker-tab {
    padding: 16px 0;
    border-bottom: 1px solid ${theme.color.gray5};
  }
`;

const StyledTooltip = styled(Tooltip)`
  .tooltip-contents-container {
    right: -33px;

    .tooltip-arrow-icon-wrapper {
      right: 16px;
    }
  }
`;

export default CustomCalendarHeader;
