import useGetAllMessageMobileTargets from 'hooks/service/queries/useGetAllMessageMobileTargets';
import { useEffect } from 'react';
import FullScreenLoading from 'sharedComponents/FullScreenLoading';

import { MobileTargetType } from '../type';

// 대상회원을 모두 불러와서 수정폼에 전달하기 위함
type Props = {
  id: number;
  onLoaded: (targets: MobileTargetType[]) => void;
};

const MobileTargetLoading = ({ id, onLoaded }: Props) => {
  const { data, isLoading } = useGetAllMessageMobileTargets(id, true);

  useEffect(() => {
    if (!isLoading && data) {
      onLoaded(data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading]);

  return <FullScreenLoading loop={isLoading} />;
};

export default MobileTargetLoading;
