import styled from '@emotion/styled';
import arrowImg from 'assets/images/tutorial4_arrow.svg';
import calendarImg from 'assets/images/tutorial4_calendar.svg';
import systemPickerImg from 'assets/images/tutorial4_system_picker.webp';
import Typography from 'components/Typography';

const TutorialFourth = () => {
  return (
    <Container>
      <ContentWrapper>
        <CalendarWrapper>
          <img src={calendarImg} alt="달력이미지설명" />
        </CalendarWrapper>
        <SystemPickerWrapper>
          <img src={systemPickerImg} alt="주간,일간,월간선택설명" />
        </SystemPickerWrapper>
        <ArrowWrapper>
          <img src={arrowImg} alt="화살표이미지" />
        </ArrowWrapper>
        <TextWrapper>
          <span className="highlight-color">주, 일, 월간, 목록 달력</span>
          <Typography span textColor="white" size={19} weight={600}>
            으로
          </Typography>
          <Typography textColor="white" size={19} weight={600}>
            전환하여 일정을
          </Typography>
          <Typography textColor="white" size={19} weight={600}>
            확인할 수 있습니다.
          </Typography>
        </TextWrapper>
      </ContentWrapper>
    </Container>
  );
};

export default TutorialFourth;

const Container = styled.div`
  width: 100%;
  height: 100%;
`;

const ContentWrapper = styled.div`
  position: absolute;
  top: 6.5rem;
  right: 1rem;
`;

const CalendarWrapper = styled.div`
  img {
    width: 100%;
  }
`;

const SystemPickerWrapper = styled.div`
  position: absolute;
  margin-top: 11px;
  right: 1rem;
  img {
    width: 150px;
  }
`;

const ArrowWrapper = styled.div`
  position: absolute;
  margin-top: 11px;
  top: 22.5rem;
  right: 5rem;
  img {
    height: auto;
  }
`;

const TextWrapper = styled.div`
  width: 20rem;
  position: absolute;
  margin-top: 12px;
  top: 30rem;
  right: 3rem;
  text-align: center;

  .highlight-color {
    font-size: 19px;
    font-weight: 600;
    color: #2cead7;
  }
`;
