import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import Button from 'components/Button';
import IconButton from 'components/Button/IconButton';
import Divider from 'components/Divider';
import Icon from 'components/Icon';
import dayjs from 'dayjs';
import useGetPermissionDoHavePermission from 'hooks/service/queries/useGetPermissionDoHavePermission';
import { StaffResponse } from 'hooks/service/queries/useInfinityStaff';
import usePermission from 'hooks/usePermission';
import usePopup from 'hooks/usePopup';
import useToast from 'hooks/useToast';
import { debounce, isEqual, sortBy } from 'lodash';
import { Dispatch, SetStateAction, useMemo, useState } from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { currentPermissionsAtom } from 'recoil/calendarSettings';
import { staffIdAtom } from 'recoil/common';
import {
  calendarViewTypeAtom,
  currentDateAtom,
  selectedFiltersAtom,
  selectedTempSchedulesAtom,
  tempApplyStaffsAtom,
  tempCheckedStaffsAtom,
} from 'recoil/schedule';
import FilterResetDialog from 'sharedComponents/Filters/FilterResetDialog';
import MainLayout from 'sharedComponents/MainLayout';

import { CurrentPeriodTyps } from '../../types';
import FilterStaffsDrawer from '../drawer/FilterStaffsDrawer';
import ApplyButton from './components/ApplyButton';
import FilterSchedules from './components/FilterScheduleItems';
import FilterTitle from './components/FilterTitle';
import { FILTER_POPUP_HEADER, TOAST_MESSAGE } from './constants';

type Props = {
  allStaffs: StaffResponse[];
  setCurrentPeriod: Dispatch<SetStateAction<CurrentPeriodTyps>>;
};

const FilterSettings = ({ allStaffs, setCurrentPeriod }: Props) => {
  const staffId = useRecoilValue(staffIdAtom);

  const [isApplyLoading, setIsApplyLoading] = useState(false);
  const [selectedFilters, setSelectedFilters] = useRecoilState(selectedFiltersAtom);
  const setTempCheckedStaffs = useSetRecoilState(tempCheckedStaffsAtom);

  /** 일정(schedules) 필터 임시값 */
  const [tempSchedules, setTempSchedules] = useRecoilState(selectedTempSchedulesAtom);
  /** 강사(staffs) 필터 임시값(UI 구조가 이중 레이어라서 임시값 2개 필요) */
  const [tempApplyStaffs, setTempApplyStaffs] = useRecoilState(tempApplyStaffsAtom);
  const [isStaffsDrawerOpen, setIsStaffsDrawerOpen] = useState(false);
  const [isOpenDialog, setIsOpenDialog] = useState(false);
  const calendarViewType = useRecoilValue(calendarViewTypeAtom);
  const currentDate = useRecoilValue(currentDateAtom);

  const { checkPermission } = useGetPermissionDoHavePermission();
  const { everyPermissions } = usePermission();
  const currentPermissions = useRecoilValue(currentPermissionsAtom);
  const isEveryPermission = everyPermissions(currentPermissions);

  const { setPopup } = usePopup();
  const { setToast } = useToast();

  const closePopup = () => {
    setTempSchedules(selectedFilters.schedules);
    setTempCheckedStaffs(selectedFilters.staffs);
    setTempApplyStaffs(selectedFilters.staffs);
    setPopup(null);
  };

  const applySelectedFilters = debounce(async () => {
    const permissionIds = currentPermissions.map(({ id }) => id);
    const isOnlyMe = tempApplyStaffs.length === 1 && tempApplyStaffs[0] === staffId;
    setIsApplyLoading(true);

    try {
      if (permissionIds.length && !isOnlyMe && !isEveryPermission && tempSchedules[0] !== 'all') {
        await checkPermission(permissionIds, {
          onError: error => {
            throw error;
          },
        });
      }

      if (calendarViewType === 'list') {
        setCurrentPeriod({
          startDate: dayjs(currentDate).startOf('month').toDate(),
          endDate: dayjs(currentDate).endOf('month').toDate(),
        });
      }

      setSelectedFilters({ schedules: tempSchedules, staffs: tempApplyStaffs });
      setToast({ type: 'success', message: TOAST_MESSAGE.filterSuccess, bottom: 76 });
      setPopup(null);
    } catch (error: unknown) {
      setToast({ type: 'error', message: TOAST_MESSAGE.notPermission, bottom: 92 });
    } finally {
      setIsApplyLoading(false);
    }
  }, 200);

  const openResetDialog = () => {
    setIsOpenDialog(true);
  };

  const closeResetDialog = () => {
    setIsOpenDialog(false);
  };

  const resetFilters = debounce(async () => {
    setTempSchedules(['all']);
    setTempCheckedStaffs(['all']);
    setTempApplyStaffs(['all']);
    setSelectedFilters({ schedules: ['all'], staffs: ['all'] });
    closeResetDialog();
    setToast({ type: 'success', message: '모든 필터가 초기화되었습니다.', bottom: 92 });
  }, 300);

  const isDirty = useMemo(() => {
    // 여기에서 sortBy는 단순히 배열 인덱스를 같게 맞추기 위함으로, 특정 조건이 따로 있진 않음
    const isSchedulesDirty = !isEqual(sortBy(selectedFilters.schedules), sortBy(tempSchedules));
    const isStaffsDirty = !isEqual(selectedFilters.staffs, tempApplyStaffs);
    return isSchedulesDirty || isStaffsDirty;
  }, [selectedFilters, tempSchedules, tempApplyStaffs]);

  return (
    <>
      <MainLayout
        header={{
          title: FILTER_POPUP_HEADER.title,
          leftButton: (
            <IconButton onClick={closePopup}>
              <Icon name="headerClose" size={24} />
            </IconButton>
          ),
          rightButton: (
            <Button fontSize={15} fontWeight={600} textColor="gray3" onClick={openResetDialog}>
              초기화
            </Button>
          ),
        }}>
        <Container>
          <section className="filter-section schedule">
            <FilterTitle title={FILTER_POPUP_HEADER.subTitle.schedule} />
            <FilterSchedules tempSchedules={tempSchedules} setTempSchedules={setTempSchedules} />
          </section>

          <Divider />

          <section className="filter-section">
            <FilterTitle title={FILTER_POPUP_HEADER.subTitle.staff} />

            <FilterStaffsDrawer isOpen={isStaffsDrawerOpen} staffs={allStaffs} setIsOpen={setIsStaffsDrawerOpen} />
          </section>

          <ApplyButton
            text="필터 적용"
            onClick={applySelectedFilters}
            disabled={!tempApplyStaffs.length || !isDirty || isApplyLoading}
          />
        </Container>
      </MainLayout>

      {isOpenDialog && <FilterResetDialog onClose={closeResetDialog} onReset={resetFilters} />}
    </>
  );
};

export default FilterSettings;

const Container = styled.div`
  ${theme.flex('column', 'flex-start', 'center', 24)};
  margin-top: 16px;

  hr {
    width: 100%;
  }

  .filter-section {
    padding: 0 20px;
    width: 100%;

    &.schedule {
      .input-select-base-input-wrapper {
        display: none;
      }
    }

    .select-drawer {
      width: 100%;
    }
  }

  .apply-button {
    padding: 0 20px 10px;
    width: 100%;
  }
`;
