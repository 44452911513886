import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import Typography from 'components/Typography';
import useInfinityHistory from 'hooks/service/queries/useInfinityHistory';
import { CurrentMemberIdProps, LectureCardNavigateParamsType } from 'pages/MemberDetail/types';
import { useNavigate } from 'react-router-dom';
import InfiniteScroll from 'sharedComponents/InfiniteScroll';
import NodataTypography from 'sharedComponents/NodataTypography';
import filters from 'utils/filters';

import LectureCard from '../LectureCard';

const BookedClass = ({ currentMemberId }: CurrentMemberIdProps) => {
  const { data, hasNextPage, fetchNextPage, isFetchingNextPage } = useInfinityHistory({
    member_id: currentMemberId,
    status: 'booked_list',
    order_by: 'asc',
  });

  const navigate = useNavigate();
  const goToLectureDetail = ({ lectureId }: LectureCardNavigateParamsType) => {
    navigate(`/booking/detail/${lectureId}`);
  };

  return (
    <Container>
      <Typography className="title" size={17} weight={700}>
        예약된 수업
      </Typography>

      <>
        {!data.length ? (
          <NodataTypography>예약된 수업이 없습니다.</NodataTypography>
        ) : (
          <InfiniteScroll showMoreButton gap={12} isLoading={isFetchingNextPage} hasMore={hasNextPage} loadMore={fetchNextPage}>
            {data.map(history => {
              const {
                id: bookingId,
                status,
                lecture: {
                  id: lectureId,
                  title,
                  type,
                  start_on,
                  deleted_at,
                  staff: { id, name, staff_avatar },
                },
                updated_at,
                user_ticket: { ticket },
              } = history;

              const lecture = {
                bookingId,
                lectureId,
                title,
                status,
                staff: { id, name, avatar: staff_avatar },
                userTicketTitle: ticket.title,
                classStartTime: filters.dateTimeWithWeekday(start_on),
                classType: type,
                bookingCompletedTime: filters.dateTimeWithWeekday(updated_at),
                waitBookingCount: 1,
                isDeletedLecture: !!deleted_at,
              };

              return <LectureCard key={bookingId} data={lecture} onClickNavigateBookingDetail={goToLectureDetail} />;
            })}
          </InfiniteScroll>
        )}
      </>
    </Container>
  );
};

export default BookedClass;

const Container = styled.div`
  padding: 32px 20px 48px;
  flex: 1;
  background-color: ${theme.color.gray6};

  .title {
    margin-bottom: 20px;
  }
`;
