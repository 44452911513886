import errorNotFound from 'assets/images/error_not_found.webp';
import TitleWithSubText from 'designedComponents/Drawers/DrawerTitle/TitleWithSubText';
import { useNavigate } from 'react-router-dom';
import ErrorDrawer from 'sharedComponents/Boundaries/components/ErrorDrawer';

const PageNotFound = () => {
  const navigate = useNavigate();

  return (
    <ErrorDrawer
      content={{
        header: <TitleWithSubText title="페이지를 찾을 수 없습니다." subTitle="페이지의 사용이 일시중단 또는 삭제되었습니다." />,
        image: errorNotFound,
        buttonText: '이전 화면으로',
        buttonType: 3,
        buttonClick: () => navigate(-1),
        isShowContact: true,
      }}
    />
  );
};

export default PageNotFound;
