import { useQuery } from '@tanstack/react-query';

import useQueryFnWithCommonParams from '../common/useQueryFnWithCommonParams';

const useGetMySchedule = ({ start_date, end_date }: MyScheduleParams) => {
  const { queryFn } = useQueryFnWithCommonParams<MyScheduleResponse, MyScheduleParams>({
    url: `/api/schedule/my-schedule`,
    params: {
      start_date,
      end_date,
    },
  });

  return useQuery({
    queryKey: ['mySchedule', start_date, end_date],
    queryFn,
    select: data => data.data,
  });
};

export default useGetMySchedule;

type MyScheduleParams = {
  start_date: string;
  end_date: string;
};

type MyScheduleResponse = string[];
