import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import { PRODUCT_TYPE_TEXT } from 'constants/text';
import useGetPermissionDoHavePermission from 'hooks/service/queries/useGetPermissionDoHavePermission';
import useInfinityUserTicketProduct, { UserTicketParams } from 'hooks/service/queries/useInfinityUserTicketProduct';
import { MEMBER_PERMISSION } from 'pages/MemberDetail/constants';
import { CurrentMemberIdProps } from 'pages/MemberDetail/types';
import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import NodataTypography from 'sharedComponents/NodataTypography';
import { StyledSelectInfiniteScroll } from 'sharedComponents/StyledComponents';
import filters from 'utils/filters';
import formatSmallUserTicketCard from 'utils/formatSmallUserTicketCard';
import formatUserTicketCard from 'utils/formatUserTicketCard';

import ExpiredTicketCard from './ExpiredTicketCard';

type Props = CurrentMemberIdProps & {
  productType: UserTicketParams['product_type'];
  status: UserTicketParams['status'];
};

const ExpiredTicketList = ({ currentMemberId, productType, status }: Props) => {
  const navigate = useNavigate();
  const { checkPermission } = useGetPermissionDoHavePermission();

  const { data, hasNextPage, isFetchingNextPage, fetchNextPage } = useInfinityUserTicketProduct({
    user_id: currentMemberId,
    product_type: productType,
    status,
  });

  const userTickets = useMemo(() => {
    return data.map(userTicket => {
      const { bottomOptions, isFavorite, ticketColor, ...rest } = formatSmallUserTicketCard(userTicket);
      const { ticketStatus } = formatUserTicketCard(userTicket);
      const { user_ticket_status } = userTicket;

      return {
        ...rest,
        isFavorite: false,
        ticketColor: userTicket.ticket.type === 'S' ? [theme.color.gray3, theme.color.gray3] : ticketColor,
        bottomOptions:
          user_ticket_status === 'expired' ? [filters.period(userTicket.availability_start_at, userTicket.expire_at)] : [],
        ticketStatus,
      };
    });
  }, [data]);

  const goToDetail = (ticketId: string) => {
    checkPermission(MEMBER_PERMISSION.memberTicketEdit.id, {
      onSuccess: () => {
        navigate(`/product/detail/${ticketId}?memberId=${currentMemberId}`);
      },
      onError: () => {
        return true;
      },
    });
  };

  if (!userTickets.length) {
    return <NodataTypography>{PRODUCT_TYPE_TEXT[productType]}이 없습니다.</NodataTypography>;
  }

  return (
    <InfiniteScrollContainer
      showMoreButton
      moreButtonColor="gray7"
      hasMore={hasNextPage}
      isLoading={isFetchingNextPage}
      loadMore={fetchNextPage}>
      <ul>
        {userTickets.map(ticket => (
          <li key={ticket.id}>
            <ExpiredTicketCard ticket={ticket} onClick={() => goToDetail(ticket.id)} />
          </li>
        ))}
      </ul>
    </InfiniteScrollContainer>
  );
};

export default ExpiredTicketList;

const InfiniteScrollContainer = styled(StyledSelectInfiniteScroll)`
  padding-bottom: 48px;

  li {
    padding: 14px 0;
  }

  .more-button {
    width: calc(100% - 40px);
    margin: 20px auto 0;
  }
`;
