import { MobileDetailType } from '../../Mobile/Detail/type';
import { AppPushFormType } from '../Form/types';
import { AppPushDetailType, AppPushTargetType } from './types';

export const convertAppPushFormData = (pushDetail: AppPushDetailType, targets: AppPushTargetType[]): AppPushFormType => {
  const { id, title, message, send_on, filter } = pushDetail;

  return {
    type: 'push',
    mode: 'update',
    isReserve: !!send_on,
    reservationDate: send_on,
    title,
    message,
    target: 'member',
    users: targets,
    counseling_logs: [],
    originalMessage: {
      id,
    },
    filter,
  };
};

export const convertAppPushStatusCount = ({
  all_count,
  success_count,
  ready_count,
  failed_count,
  status,
}: Pick<
  AppPushDetailType,
  'all_count' | 'success_count' | 'ready_count' | 'failed_count' | 'status'
>): MobileDetailType['status'] => {
  /**
   * 예약취소일 때는 모두 실패카운트로 표시
   * 백엔드에서 처리하기 힘들어서 프론트에서 표기만 변경하도록
   */
  if (status === 'canceled') {
    return {
      success_count: 0,
      failed_count: all_count,
      processing_count: 0,
    };
  }
  return {
    success_count: success_count,
    failed_count: failed_count,
    processing_count: ready_count,
  };
};
