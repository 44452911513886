import useInfinityMessagePushTargets from 'hooks/service/queries/useInfinityMessagePushTargets';
import TargetsDetailList from 'pages/MoreDetails/Message/Mobile/Detail/components/TargetsDetailList';
import InfiniteScroll from 'sharedComponents/InfiniteScroll';

type Props = {
  id: number;
};

const AppPushTargetsDetailDrawerContents = ({ id }: Props) => {
  const {
    data: { targets, total },
    fetchNextPage,
    isFetchingNextPage,
    hasNextPage,
  } = useInfinityMessagePushTargets(id);

  return (
    <>
      <InfiniteScroll loadMore={fetchNextPage} isLoading={isFetchingNextPage} hasMore={hasNextPage}>
        <TargetsDetailList targets={targets} total={total} />
      </InfiniteScroll>
    </>
  );
};

export default AppPushTargetsDetailDrawerContents;
