import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import Button from 'components/Button';
import Icon from 'components/Icon';
import usePopup from 'hooks/usePopup';
import { CurrentMemberIdProps } from 'pages/MemberDetail/types';
import { FormProvider, useForm } from 'react-hook-form';
import ApiBoundary from 'sharedComponents/Boundaries/ApiBoundary';
import MemoForm from 'sharedComponents/MemoForm';
import MemoList, { MemoListContainer } from 'sharedComponents/MemoForm/MemoList';
import Sorting from 'sharedComponents/MemoForm/Sorting';
import { MemoFormType, SortFormType } from 'sharedComponents/MemoForm/types';

const Memo = ({ currentMemberId }: CurrentMemberIdProps) => {
  const { setPopup } = usePopup();
  // const { hasPermission } = usePermission();
  // const { checkPermission } = useGetPermissionDoHavePermission();
  // const canCreateMemo = hasPermission(PERMISSION.member.edit);

  const formValues: MemoFormType = {
    ref_type: 'member',
    ref_id: currentMemberId,
    memo: '',
    imgUrls: [],
    files: [],
  };

  const methods = useForm<MemoFormType & SortFormType>({
    defaultValues: {
      ...formValues,
      sort: 'desc',
    },
  });

  const clickCreateMemo = () => {
    setPopup(<MemoForm formValues={formValues} />);
  };

  return (
    <FormProvider {...methods}>
      <StyledWrapper canCreateMemo={true}>
        <Button
          fullWidth
          variant="outlined"
          size="medium48"
          leftIcon={<Icon name="plusBold" size={16} fillColor={theme.color.gray3} />}
          textColor="gray2"
          fontWeight={600}
          gap={2}
          onClick={clickCreateMemo}>
          메모추가
        </Button>
        <Sorting />
        <ApiBoundary>
          <MemoList />
        </ApiBoundary>
      </StyledWrapper>
    </FormProvider>
  );
};

export default Memo;

const StyledWrapper = styled(MemoListContainer)<{ canCreateMemo: boolean }>`
  .sort-button {
    margin: ${({ canCreateMemo }) => (canCreateMemo ? '24px 0 16px auto' : '4px 0 16px auto')};
  }
`;
