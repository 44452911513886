import styled from '@emotion/styled';
import { MbscCalendarEventData } from '@mobiscroll/react';
import { theme } from 'assets/styles';
import Icon from 'components/Icon';
import Typography from 'components/Typography';
import dayjs from 'dayjs';
import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { staffIdAtom } from 'recoil/common';
import filters from 'utils/filters';

import { EVENT_CARD_TYPE } from '../../constants';
import getEventUrl from '../../utils/getEventUrl';
import { DIVISION_LABEL } from './constants';

type Props = {
  event: MbscCalendarEventData;
};

const ListTypeAgendaCard = ({ event }: Props) => {
  const { title, original, startDate, endDate } = event;

  const navigate = useNavigate();
  const staffId = useRecoilValue(staffIdAtom);

  const isShowTraineeText = original?.type === 'G' || original?.type === 'P';
  const isShowPrivateIcon = (original?.type === 'E' || original?.type === 'AE') && original?.isPrivate;
  /** 지난 수업 색상 변경 트리거 */
  const isPastLecture = dayjs(endDate).isBefore(dayjs());
  /** 내 일정이면 백그라운드 변경 */
  const isMyLecture = useMemo(() => {
    switch (original?.type) {
      default:
        return false;
      case 'G':
      case 'P':
      case 'C': {
        if (staffId === original?.staffId) return true;
        return false;
      }
      case 'E':
      case 'AE': {
        const staffList = [original.register.id, ...original.staffs.map(({ id }: { id: number }) => id)];
        return staffList.includes(staffId);
      }
    }
  }, [staffId, original]);

  const currentTitle = useMemo(() => {
    switch (original?.type) {
      case 'C':
        return `${title} 님`;
      case 'G':
      case 'P': {
        if (original?.isBookingClosed) return `[예약마감] ${title}`;
        else return title;
      }
      default:
        return title;
    }
  }, [original?.isBookingClosed, original?.type, title]);

  const staffName = useMemo(() => {
    if (original?.type !== 'E' && original?.type !== 'AE') {
      return `${original?.staffName} 강사`;
    }

    /** 기타 일정일 때 */
    if (!original?.staffs?.length) return `${original?.register?.name}`;
    const staffList = [original.register.name, ...original.staffs];
    return `${staffList[0]} 외 ${original.staffs.length}명`;
  }, [original]);

  const lectureTime = useMemo(() => {
    if (original?.type === 'AE') return '종일';
    return filters.timePeriod(startDate, endDate).replaceAll(' ', '');
  }, [original?.type, startDate, endDate]);

  const clickEvent = (event: MbscCalendarEventData) => {
    switch (original?.type) {
      case EVENT_CARD_TYPE.counsel:
        navigate(`/counsel/detail/${event.id}`);
        break;
      case EVENT_CARD_TYPE.etcSchedule:
        navigate(`/schedule/etc/detail/${event.id}`);
        break;
      case EVENT_CARD_TYPE.allDayEtcSchedule: {
        const baseUrl = getEventUrl(original?.isBulkAllDay);
        navigate(`${baseUrl}/${event.id}`);
        break;
      }
      default:
        navigate(`/booking/detail/${event.id}`);
    }
  };

  const isShowStaffName = useMemo(() => {
    if (original?.type === 'E' || original?.type === 'AE') {
      return true;
    }
    if (!isMyLecture) return true;
    return false;
  }, [isMyLecture, original?.type]);

  return (
    <Container isMyLecture={isMyLecture} isPastLecture={isPastLecture} onClick={() => clickEvent(event)}>
      {original?.type && (
        <div className="division-label">
          <Typography size={11} weight={500} textColor="white">
            {DIVISION_LABEL[original.type as keyof typeof DIVISION_LABEL]}
          </Typography>
        </div>
      )}

      <div className="event-info">
        <div className="top-wrapper">
          <div className="title-wrapper">
            {isShowPrivateIcon && <Icon name="lockBold" size={16} fillColor={theme.color.gray2} />}
            <Typography className="title" size={15} textColor="gray2" ellipsis={1}>
              {currentTitle}
            </Typography>
          </div>
          {isShowStaffName && (
            <Typography className="staff-name" size={15} textColor="gray2" opacity={0.8} ellipsis={1}>
              {staffName}
            </Typography>
          )}
        </div>
        <div className="bottom-wrapper">
          <Typography className="lecture-time" size={15} weight={600} opacity={0.8}>
            {lectureTime}
          </Typography>

          {original?.room && (
            <Typography className="room-name" size={15} textColor="gray2" opacity={0.8} ellipsis={1}>
              {original.room.name} 룸
            </Typography>
          )}

          {original && isShowTraineeText && (
            <Typography className="trainee" size={15} weight={500} textColor="gray2" opacity={0.8}>
              예약 {original.currentTraineeCount}/{original.maxTrainee}
            </Typography>
          )}
        </div>
      </div>
    </Container>
  );
};

export default ListTypeAgendaCard;

const Container = styled.div<{ isMyLecture: boolean; isPastLecture: boolean }>`
  ${theme.flex('row', 'center', 'flex-start', 12)};
  padding: 14px 20px;
  width: 100%;
  border-bottom: 1px solid rgba(229, 232, 237, 0.8);
  ${({ isMyLecture }) => isMyLecture && 'background-color: rgba(109, 150, 255, 0.08)'};

  .division-label {
    ${theme.flex()};
    min-width: 26px;
    width: 26px;
    height: 24px;
    border-radius: 8px;
    background-color: rgba(97, 98, 100, 0.64);
  }

  .event-info {
    flex: 1;
    opacity: ${({ isPastLecture }) => (isPastLecture ? 0.56 : 1)};

    .top-wrapper,
    .bottom-wrapper {
      ${theme.flex('row', 'center', 'space-between', 10)};
    }

    .title-wrapper {
      ${theme.flex('row', 'center', 'flex-start', 2)};
      width: 100%;
      max-width: 50%;
    }

    .lecture-time,
    .trainee {
      max-width: 30%;
      min-width: max-content;
    }
  }
`;
