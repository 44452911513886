import { useEffect } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import localStorage from 'utils/localStorage';

export default function PrivateRoute({ children }: { children: React.ReactNode }) {
  const accessToken = localStorage.get('access_token');
  const navigate = useNavigate();

  useEffect(() => {
    // 온보딩이 완료되지 않은 경우 온보딩 페이지로 이동
    if (!localStorage.get('onboardingShow')) {
      navigate('/onboarding', { replace: true });
      return;
    }

    // 토큰이 없는 경우 로그인 페이지로 이동
    if (!accessToken) {
      navigate('/intro', { replace: true });
    }
  }, [accessToken, navigate]);

  // 토큰이 있는 경우 접근 허용
  if (accessToken) {
    // 토큰이 있어도 계정통합이 안되어 있으면 로그인 페이지로 이동
    if (!localStorage.get('isUnifiedAccount')) {
      localStorage.remove('access_token');
      return <Navigate to="/login" replace />;
    }

    return <>{children}</>;
  }

  return <Navigate to="/intro" replace />;
}
