import dayjs from 'dayjs';
import { StaffResponse } from 'hooks/service/queries/useInfinityStaff';
import { COUNSEL_FUNNEL_TEXT } from 'pages/Member/constants';
import { MemberGradeType } from 'pages/Member/types';

import { MessageDetailType } from '../../types';
import { MobileFormType } from '../Form/types';
import { STUDIO_WEB_FILTER_LABEL } from './constants';
import { MobileDetailType, MobileFormTargetsType, MobileTargetType } from './type';

// 발송당시에 보낸 필터데이터 기준 해시태그로 변환
// 시설웹/관리자웹 검색필터가 다르기 때문에 옵셔널 체이닝으로 접근
export const convertHashTagsForMember = (messageFilters: MessageDetailType['filter'], grades?: MemberGradeType[]) => {
  const keywordTag = messageFilters?.keyword ? `# ${messageFilters?.keyword}` : undefined;
  const typeTag = messageFilters?.type ? `# ${STUDIO_WEB_FILTER_LABEL.type[messageFilters?.type]}` : '# 전체회원';
  const courseTag = messageFilters?.course_type
    ? `# ${messageFilters?.course_type === 'G' ? '그룹' : '프라이빗'} 수강권`
    : '# 수강권 전체';
  const gradeTag = `# ${grades?.find(grade => grade.id === messageFilters?.user_grade)?.name ?? '회원등급 전체'}`;

  const tags = [keywordTag, typeTag, courseTag, gradeTag];

  if (messageFilters?.remaining_start_date) {
    const ticketTag = messageFilters?.remaining_coupon
      ? `# 기간 내 ${messageFilters?.remaining_coupon}회 이하로 남은 회원`
      : '# 기간 내 만료되는 회원';
    tags.push(ticketTag);
  }
  if (messageFilters?.last_attendance_day) {
    const lastAttendanceTag = `# ${messageFilters?.last_attendance_day}일 이상 미방문 회원`;
    tags.push(lastAttendanceTag);
  }

  if (messageFilters?.attendance_count_start_date) {
    const attendanceCountTag = `기간내 출석한 회원`;
    tags.push(attendanceCountTag);
  }

  // 시설웹 필터 케이스
  if (!messageFilters?.remaining_start_date && messageFilters?.remaining_coupon) {
    tags.push(`# 잔여횟수 ${messageFilters?.remaining_coupon}회 이하`);
  }
  if (messageFilters?.remaining_day) {
    tags.push(`# 잔여기간 ${messageFilters?.remaining_day}일 이하`);
  }

  return tags.filter(tag => tag); // undefined 제거
};

// 마찬가지로 시설웹/관리자웹 검색필터가 다르기 때문에 옵셔널 체이닝으로 접근
export const convertHashTagsForCounsel = (messageFilters: MessageDetailType['filter'], staffs?: StaffResponse[]) => {
  let periodTag = undefined;
  if (messageFilters?.start_on && messageFilters?.end_on) {
    const today = dayjs();
    const isEndOnToday = dayjs(messageFilters.end_on).isSame(today, 'day');
    const isStartOn1Month = dayjs(messageFilters.start_on).isSame(today.subtract(1, 'month'));
    const isStartOn3Month = dayjs(messageFilters.start_on).isSame(today.subtract(3, 'month'));
    const isStartOn6Month = dayjs(messageFilters.start_on).isSame(today.subtract(6, 'month'));

    if (isEndOnToday && isStartOn1Month) {
      periodTag = '# 최근 1개월';
    } else if (isEndOnToday && isStartOn3Month) {
      periodTag = '# 최근 3개월';
    } else if (isEndOnToday && isStartOn6Month) {
      periodTag = '# 최근 6개월';
    } else {
      periodTag = `# ${messageFilters.start_on} ~ ${messageFilters.end_on}`;
    }
  }

  const registerTag = messageFilters?.register_type
    ? `# ${STUDIO_WEB_FILTER_LABEL.register_type[messageFilters.register_type]}`
    : '# 회원등록 여부 전체';

  const channelTag = messageFilters?.channel ? `# ${STUDIO_WEB_FILTER_LABEL.channel[messageFilters.channel]}` : '# 상담유형 전체';
  const funnelTag = messageFilters?.funnel ? `# ${COUNSEL_FUNNEL_TEXT[messageFilters.funnel]}` : '# 유입경로 전체';
  const staffTag = `# ${staffs?.find(staff => staff.id === messageFilters?.staff_id)?.name ?? '담당자 전체'}`;
  return [periodTag, registerTag, channelTag, funnelTag, staffTag].filter(tag => tag);
};

export const convertMobileFormData = (messageDetail: MobileDetailType, targets: MobileTargetType[]): MobileFormType => {
  const { id, title, message, send_on, message_type, used_points, filter } = messageDetail;
  const targetType = targets.length ? (targets.some(target => target.type === 'studio_user') ? 'member' : 'counsel') : undefined;
  const { users, counseling_logs } = convertFormTargets(targets);

  return {
    type: 'mobile',
    mode: 'update',
    isReserve: !!send_on,
    reservationDate: send_on,
    title,
    message,
    target: targetType,
    users: targetType === 'member' ? users : [],
    counseling_logs: targetType === 'counsel' ? counseling_logs : [],
    originalMessage: {
      id,
      messageType: message_type,
      count: targets.length,
      usedPoints: Math.abs(used_points),
    },
    filter,
  };
};

/**
 * 예약 문자 메세지 대상에서 회원과 상담고객을 분리 및 id 교체
 * type을 기준으로 분리, studio_user_id, counseling_log_id가 없는 경우도 무시
 */
const convertFormTargets = (targets: MobileTargetType[]): MobileFormTargetsType => {
  return targets.reduce<MobileFormTargetsType>(
    (acc, { type, studio_user_id, counseling_log_id, name, mobile, avatar }) => {
      if (type === 'studio_user' && studio_user_id) {
        acc.users.push({ id: studio_user_id, name, mobile, avatar });
      } else if (type === 'counseling_log' && counseling_log_id) {
        acc.counseling_logs.push({ id: counseling_log_id, name: name, mobile: mobile, avatar });
      }
      return acc;
    },
    { users: [], counseling_logs: [] },
  );
};
