import useGetAllMessagePushTargets from 'hooks/service/queries/useGetAllMessagePushTargets';
import { useEffect } from 'react';
import FullScreenLoading from 'sharedComponents/FullScreenLoading';

import { AppPushTargetType } from '../types';

type Props = {
  id: number;
  onLoaded: (targets: AppPushTargetType[]) => void;
};

const AppPushTargetLoading = ({ id, onLoaded }: Props) => {
  const { data, isLoading } = useGetAllMessagePushTargets(id, true);

  useEffect(() => {
    if (!isLoading && data) {
      onLoaded(data);
    }
  }, [data, isLoading, onLoaded]);

  return <FullScreenLoading loop={isLoading} />;
};

export default AppPushTargetLoading;
