import { useQueryClient } from '@tanstack/react-query';
import Typography from 'components/Typography';
import { resetRecoilState } from 'constants/resetRecoilState';
import OneLinedTitle from 'designedComponents/Drawers/DrawerTitle/OneLinedTitle';
import DoubleButtonDrawer from 'designedComponents/Drawers/DrawerWithButton/DoubleButtonDrawer';
import useDeleteAuthWithdraw from 'hooks/service/mutations/useDeleteAuthWithdraw';
import useErrorDialog from 'hooks/useErrorDialog';
import usePopup from 'hooks/usePopup';
import useResetAllRecoilState from 'hooks/useResetAllRecoilState';
import { useNavigate } from 'react-router-dom';
import { accessTokenAtom, staffIdAtom, studioIdAtom } from 'recoil/common';
import CompletePopupContents from 'sharedComponents/CompletePopupContents';
import { CustomError } from 'types/errorTypes';
import { postMessageToApp } from 'utils/communicateWithNative';
import localStorage from 'utils/localStorage';

import { WITHDRAW_TEXT } from '../constants';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  password: string;
};

const WithdrawFinalDrawer = ({ isOpen, onClose, password }: Props) => {
  const { mutate: deleteAuthWithdraw } = useDeleteAuthWithdraw();

  const errorDialog = useErrorDialog();
  const { setPopup } = usePopup();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const resetRecoilStates = useResetAllRecoilState([
    studioIdAtom,
    staffIdAtom,
    accessTokenAtom,
    ...resetRecoilState, // 검색어, 필터 등 시설에 따라 달라져야 하는 recoil 상태
  ]);

  const deleteAccount = () => {
    const params = { password };
    deleteAuthWithdraw(params, {
      onSuccess: () => {
        localStorage.reset();
        queryClient.clear();
        resetRecoilStates();
        postMessageToApp('DELETE_FCM_TOKEN');
        setPopup(
          <CompletePopupContents
            type="withdraw"
            onClose={() => {
              setPopup(null);
              navigate('/intro');
            }}
          />,
        );
      },
      onError: (error: CustomError) => {
        errorDialog.open(error);
      },
      onSettled: onClose,
    });
  };
  return (
    <DoubleButtonDrawer
      type={1}
      isOpen={isOpen}
      header={<OneLinedTitle title={WITHDRAW_TEXT.finalTitle} />}
      onClose={onClose}
      leftButton={{
        text: WITHDRAW_TEXT.finalCancel,
        onClick: onClose,
      }}
      rightButton={{
        text: WITHDRAW_TEXT.finalSubmit,
        onClick: deleteAccount,
      }}>
      <Typography size={15}>{WITHDRAW_TEXT.finalDescription[0]}</Typography>
      <Typography size={15} span>
        {WITHDRAW_TEXT.finalDescription[1]}
      </Typography>
      <Typography size={15} weight={600} span>
        {WITHDRAW_TEXT.finalDescription[2]}
      </Typography>
    </DoubleButtonDrawer>
  );
};

export default WithdrawFinalDrawer;
