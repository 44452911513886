import imageCompression, { Options } from 'browser-image-compression';

const defaultOption: Options = {
  maxSizeMB: 2, // 2MB 이하로 압축
  useWebWorker: true,
};

export async function compressImage(file: File, options?: Options): Promise<File> {
  return await imageCompression(file, defaultOption);
}
