import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import Typography from 'components/Typography';
import { BookingCommonFormType } from 'pages/Booking/types';

type Props = {
  label?: string;
  roomId: BookingCommonFormType['roomId'];
  showRoomText: boolean;
};

const RoomName = ({ label, roomId, showRoomText }: Props) => {
  return (
    <Container>
      <Typography className="name" size={15} weight={500} ellipsis={1} textColor={roomId ? 'gray1' : 'gray3'}>
        {label}
      </Typography>
      {showRoomText && (
        <Typography span size={15} weight={500}>
          룸
        </Typography>
      )}
    </Container>
  );
};

export default RoomName;

const Container = styled.div`
  ${theme.flex('', 'center', 'flex-start', 3)};
`;
