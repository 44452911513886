import { useEffect } from 'react';
import { useLocation, useNavigationType } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { navigationStackAtom } from 'recoil/common';

/**
 * React-router-dom의 useHistory를 대체
 * navigate 이동 시 path 기록
 *
 * @returns path[] - 브라우저 히스토리 스택
 */

const useHistory = () => {
  /**
   * 예외 케이스로, 수동으로 stack을 삭제해야하는 경우를 위해 전역화
   * POP일 때, -2가 되어야 할 때에도 무조건 -1을 하게되서 발생하는 상황
   */
  const [navigationStack, setStack] = useRecoilState(navigationStackAtom);

  const type = useNavigationType();
  const { pathname, search } = useLocation();
  const stack = `${pathname}${search}`;

  useEffect(() => {
    if (type === 'PUSH') {
      setStack(prev => [...prev, stack]);
    } else if (type === 'POP') {
      setStack(prev => prev.slice(0, -1));
    } else {
      // REPLACE
      setStack(prev => [...prev.slice(0, -1), stack]);
    }
  }, [stack, type, setStack]);

  return navigationStack;
};

export default useHistory;
