import useChangeStudio from 'hooks/useChangeStudio';
import { useLocation } from 'react-router-dom';
import ApiBoundary from 'sharedComponents/Boundaries/ApiBoundary';

import NotificationHeader from './NotificationHeader';
import NotificationList from './NotificationList';
import SkeletonHeader from './SkeletonHeader';
import SkeletonList from './SkeletonList';

const NotificationPage = () => {
  const location = useLocation();
  const { changeStudio } = useChangeStudio();

  const targetStudio = location.state?.studio;
  if (targetStudio) {
    changeStudio(targetStudio);
  }
  return (
    <>
      <ApiBoundary fallback={<SkeletonHeader />}>
        <NotificationHeader />
      </ApiBoundary>
      <ApiBoundary fallback={<SkeletonList />}>
        <NotificationList />
      </ApiBoundary>
    </>
  );
};

export default NotificationPage;
