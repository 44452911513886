import styled from '@emotion/styled';
import { useQueryClient } from '@tanstack/react-query';
import { ReactComponent as Logo } from 'assets/images/logo_type_bottom-text.svg';
import { theme } from 'assets/styles';
import Button, { ButtonProps } from 'components/Button';
import ButtonGroup from 'components/Button/ButtonGroup';
import { resetRecoilState } from 'constants/resetRecoilState';
import useResetAllRecoilState from 'hooks/useResetAllRecoilState';
import useStatusBarColor from 'hooks/useStatusBarColor';
import { useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import localStorage from 'utils/localStorage';

const Intro = () => {
  useStatusBarColor(theme.color.white);

  const navigate = useNavigate();

  const resetAllRecoilState = useResetAllRecoilState(resetRecoilState);
  const queryClient = useQueryClient();

  useEffect(() => {
    localStorage.clear();
    resetAllRecoilState();
    queryClient.clear();
    queryClient.removeQueries();
  }, [resetAllRecoilState, queryClient]);

  const goLink = (url: string) => {
    navigate(url);
  };

  const commonButtonProps: Omit<ButtonProps, 'children'> = useMemo(() => {
    return {
      variant: 'contained',
      fontSize: 15,
      size: 'large56',
      fullWidth: true,
    };
  }, []);

  return (
    <Container>
      <section className="logo-wrapper">
        <Logo />
      </section>
      <section className="bottom-wrapper">
        <ButtonGroup>
          <Button color="gray" onClick={() => goLink('/join?step=0')} {...commonButtonProps}>
            회원가입
          </Button>
          <Button color="point" onClick={() => goLink('/login')} {...commonButtonProps}>
            로그인
          </Button>
        </ButtonGroup>

        <Button widthSize={150} fontSize={15} textUnderline onClick={() => navigate('/lounge-temp')}>
          로그인 없이 둘러보기
        </Button>
      </section>
    </Container>
  );
};

export default Intro;

const Container = styled.div`
  ${theme.flex('column')}
  padding: 0 20px 24px;
  height: 100vh;

  .logo-wrapper {
    ${theme.flex()}
    flex: 1;
  }

  .bottom-wrapper {
    ${theme.flex('column', 'center', '', 24)}
    width: 100%;

    .button-group {
      width: 100%;
    }
  }
`;
