import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import Button from 'components/Button';
import Icon from 'components/Icon';
import Typography from 'components/Typography';
import CheckRadioFilterContents from 'designedComponents/Drawers/CheckRadioDrawer/CheckRadioFilterContents';
import useQueryString from 'hooks/useQueryString';
import { REPEAT_CUSTOM_SETTING_WEEKDAY, REPEAT_SETTING_CHECK_RADIO_DATA } from 'pages/Booking/constants';
import { BookingCommonFormType, RepeatCustomWeekdayType, RepeatSettingType } from 'pages/Booking/types';
import { useEffect, useState } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import ChangeRepeatDialog from './ChangeRepeatDialog';

const RepeatSetting = ({ showChangeDialog, initCustomArray, initRepeat }: RepeatSettingType) => {
  const [isOpenDialog, setIsOpenDialog] = useState(false);
  const { control, setValue, getValues } = useFormContext<BookingCommonFormType>();
  const isChangedRepeat = getValues('isChangedRepeat');
  const repeat = useWatch({ control, name: 'repeat' });
  const isCheckedCustom = repeat === 'custom';
  const repeatCustomWeekday = getValues('repeatCustomWeekday');
  const customLabel = repeatCustomWeekday.map(weekday => weekday.value).join(', ');

  const navigate = useNavigate();
  const { setSearchParams, getAllSearchParams } = useQueryString();

  /** 맞춤 설정 클릭한 경우 맞춤설정 페이지로 이동 */
  const goRepeatCustomPage = () => {
    setValue('repeat', 'custom', { shouldDirty: true });
    setSearchParams({
      ...getAllSearchParams(),
      setting: 'custom',
    });
  };

  const clickCheckItem = () => {
    if (showChangeDialog) {
      setIsOpenDialog(true);
    } else {
      navigate(-1);
    }
  };

  /**
   * 반복설정 값에 따라 맞춤 설정 요일 체크되도록
   * 예) 반복설정에 매일에 체크되어 있다면 맞춤 설정에 모든 요일에 자동 체크
   */
  useEffect(() => {
    if (isCheckedCustom || !repeat) return;

    let weekdayArray: RepeatCustomWeekdayType[] = REPEAT_CUSTOM_SETTING_WEEKDAY;

    switch (repeat) {
      case 'everyMonday':
        weekdayArray = REPEAT_CUSTOM_SETTING_WEEKDAY.filter(item => item.id === 'mon');
        break;
      case 'weekday':
        weekdayArray = REPEAT_CUSTOM_SETTING_WEEKDAY.filter(item => item.id !== 'sat' && item.id !== 'sun');
        break;
    }

    setValue('repeatCustomWeekday', weekdayArray);
    setValue('tempRepeatCustomWeekday', weekdayArray);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [repeat]);

  return (
    <>
      <Container>
        {isCheckedCustom && (
          <StyledCustomTypography size={17} textColor="primary" weight={isCheckedCustom ? 600 : 400}>
            {customLabel}
            <Icon name="selectCheck" color="primary" />
          </StyledCustomTypography>
        )}
        <CheckRadioFilterContents
          control={control}
          name="repeat"
          data={REPEAT_SETTING_CHECK_RADIO_DATA}
          onClick={clickCheckItem}
        />
        <Button className="custom-button" fullWidth size="medium48" onClick={goRepeatCustomPage} fontSize={17} fontWeight={400}>
          맞춤 설정
        </Button>
      </Container>

      {!isChangedRepeat && isOpenDialog && (
        <ChangeRepeatDialog
          onClose={() => setIsOpenDialog(false)}
          initCustomArray={initCustomArray}
          initRepeat={initRepeat}
          onClick={() => navigate(-1)}
        />
      )}
    </>
  );
};

export default RepeatSetting;

const Container = styled.div`
  .check-radio-filter-contents {
    padding: 0 20px;
    gap: 0;

    li {
      height: 48px;
    }
  }

  .custom-button {
    padding: 0 20px;
    justify-content: space-between;
  }
`;

const StyledCustomTypography = styled(Typography)`
  padding: 0 20px;
  height: 48px;
  ${theme.flex('', 'center', 'space-between')};
`;
