import { useQuery } from '@tanstack/react-query';

import useGetAllQueryFnWithCommonParams from '../common/useGetAllQueryFnWithCommonParams';
import { EtcScheduleParams, EtcScheduleType } from './useGetAllScheduleEtcSchedule';
import { PeriodDateType } from './useGetAllScheduleLecture';

export type Props = {
  start_date: PeriodDateType;
  end_date: PeriodDateType;
  /**
   * 빈 배열일 경우 staff 전체 필터 적용
   */
  staff_ids: string;
  enabled: boolean;
  studioId: number;
};

const useGetAllScheduleAllDayEtcSchedule = ({ enabled, studioId, ...params }: Props) => {
  const { queryFn } = useGetAllQueryFnWithCommonParams<AllDayEtcScheduleResponse, EtcScheduleParams>({
    url: '/api/schedule/allDayEtcSchedule',
    params: {
      ...params,
    },
  });

  return useQuery({
    queryKey: ['schedule/allDayEtcSchedule', { ...params, enabled, studioId }],
    queryFn,
    select: data => data.data,
    enabled,
  });
};

export default useGetAllScheduleAllDayEtcSchedule;

export type AllDayEtcScheduleResponse = Array<EtcScheduleType & { is_bulk_all_day: boolean }>;
