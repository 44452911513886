import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import Typography from 'components/Typography';
import useGetAllStaff from 'hooks/service/queries/useGetAllStaff';
import useGetMemberGrade from 'hooks/service/queries/useGetMemberGrade';
import useInfinityMessageMobileTargets from 'hooks/service/queries/useInfinityMessageMobileTargets';
import InfiniteScroll from 'sharedComponents/InfiniteScroll';

import { MOBILE_DETAIL_TEXT } from '../constants';
import { MobileDetailType } from '../type';
import { convertHashTagsForCounsel, convertHashTagsForMember } from '../utils';
import TargetsDetailList from './TargetsDetailList';

type Props = {
  id: number;
  total: number;
  messageFilters: MobileDetailType['filter'];
};

const TargetsDetailDrawerContents = ({ id, messageFilters, total }: Props) => {
  const { data, fetchNextPage, isFetchingNextPage, hasNextPage } = useInfinityMessageMobileTargets(id);
  const { targets } = data;
  const isMember = targets.some(target => target.studio_user_id);
  const isGradesNeeded = isMember && !!messageFilters?.user_grade; // 회원에서 회원등급 id가 있을 때만 이름을 알기 위해 api 요청 (이름을 같이 저장하면 좋았을 텐데 시설웹에서 id만 보내서 관리자웹에서도 통일함)
  const isAllStaffsNeeded = !isMember && !!messageFilters?.staff_id; // 상담에서 담당자 id가 있을 때만 이름을 알기 위해 api 요청 (이름을 같이 저장하면 좋았을 텐데 시설웹에서 id만 보내서 관리자웹에서도 통일함)

  const { data: gradeResponse } = useGetMemberGrade(isGradesNeeded);
  const { data: staffs } = useGetAllStaff({}, isAllStaffsNeeded);

  const hashTags = isMember
    ? convertHashTagsForMember(messageFilters, gradeResponse?.is_use_user_grade ? gradeResponse.grades : undefined) // api 요청 필요없거나 시설에서 사용하지 않을 때는 undefined로 넘김
    : convertHashTagsForCounsel(messageFilters, staffs); // api 요청 필요없을 때는 undefined로 넘김

  return (
    <>
      <Typography size={17} weight={700}>
        {MOBILE_DETAIL_TEXT.targetDrawer.condition}
      </Typography>
      <HashTagList>
        {hashTags.map(hashTag => (
          <HashTag key={hashTag} textColor="primary" weight={500}>
            {hashTag}
          </HashTag>
        ))}
      </HashTagList>
      <InfiniteScroll loadMore={fetchNextPage} isLoading={isFetchingNextPage} hasMore={hasNextPage}>
        <TargetsDetailList targets={targets} total={total} />
      </InfiniteScroll>
    </>
  );
};

export default TargetsDetailDrawerContents;

const HashTagList = styled.div`
  ${theme.flex('', 'center', 'start')}
  flex-wrap: wrap;
  gap: 10px;
  margin: 12px 0 40px;
`;

const HashTag = styled(Typography)`
  padding: 8px 12px;
  border-radius: 999px;
  background-color: rgba(108, 133, 251, 0.08);
`;
