import { ETC_SCHEDULE_PERMISSION } from 'pages/EtcSchedule/constants';
import { useRecoilValue } from 'recoil';
import { staffIdAtom } from 'recoil/common';

import { EtcScheduleDetailResponse } from './service/queries/useGetEtcScheduleEtcScheduleTime';
import usePermission from './usePermission';

/**
 * 기타 일정 상세 페이지에서 등록자의 일정 수정/복사/삭제 권한 체크
 *  - 수정/복사/삭제는 등록자만 가능
 * @param register 기타 일정을 등록한 강사
 */
const useCheckPermissionEtcDetail = (register: EtcScheduleDetailResponse['register']) => {
  const { hasPermission } = usePermission();
  const currentStaffId = useRecoilValue(staffIdAtom);
  const isRegister = register.id === currentStaffId;

  return {
    canViewEtcSchedule: true,
    canCreateEtcSchedule: !isRegister ? false : hasPermission(ETC_SCHEDULE_PERMISSION.mine.create),
    canUpdateEtcSchedule: !isRegister ? false : hasPermission(ETC_SCHEDULE_PERMISSION.mine.update),
    canDeleteEtcSchedule: !isRegister ? false : hasPermission(ETC_SCHEDULE_PERMISSION.mine.delete),
  };
};

export default useCheckPermissionEtcDetail;
