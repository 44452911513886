import instance from 'api/axios';
import axios, { AxiosResponse } from 'axios';
import useToast from 'hooks/useToast';
import { useCallback, useState } from 'react';
import { compressImage } from 'utils/compressImage';
import { recordErrorWithSentry } from 'utils/sentry';

const usePostFileUploadAvatarPresignedUrl = () => {
  const mutationFn = async (data: AvatarPresignedUrlParams): Promise<AxiosResponse<AvatarPresignedUrlResponse>> => {
    return await instance({
      method: `post`,
      url: `/api/fileupload/avatar/presigned-url`,
      data,
    });
  };

  const [isPending, setIsPending] = useState(false); // 강사 아바타 변경시 로딩용
  const { setToast } = useToast();

  // 실패시 false, 성공시 경로 반환 (파일없으면 undefined)
  const getAvatarImage = useCallback(
    async (file?: File, toastPosition?: number) => {
      if (!file) return undefined;

      try {
        setIsPending(true);
        const fileType = file.type.split('/')[1]; // jpg, jpeg, png
        const compressedFile = await compressImage(file);

        const { data } = await mutationFn({ fileType }); // 임시링크, DB id 미리 받아오기
        await axios.put(data['presigned-url'], compressedFile); // S3에 개별 이미지 업로드
        setIsPending(false);
        return data.image;
      } catch (e) {
        recordErrorWithSentry(e);
        setToast({ type: 'error', message: '사진 업로드에 실패했습니다.\n다시 시도해 주세요.', bottom: toastPosition });
        return false;
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  return { getAvatarImage, isPending };
};

export default usePostFileUploadAvatarPresignedUrl;

type AvatarPresignedUrlParams = {
  fileType: string; // S3 파일 뒤에 붙는 확장자, MIME 타입 모두 가능
};

type AvatarPresignedUrlResponse = {
  'presigned-url': string;
  timeout: string;
  image: string; // 아바타 이미지 정보
};
