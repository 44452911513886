import styled from '@emotion/styled';
import Button from 'components/Button';
import Icon from 'components/Icon';
import CheckRadioDrawer from 'designedComponents/Drawers/CheckRadioDrawer';
import useQueryString from 'hooks/useQueryString';
import { PUSH_FILTERS } from 'pages/Notification/constants';
import { PushFormType } from 'pages/Notification/types';
import { useState } from 'react';
import { useForm } from 'react-hook-form';

import { getPushFilter, getPushFilterLabel } from './utils';

const PushFilterButton = () => {
  const [isOpen, setIsOpen] = useState(false);
  const { getSearchParams, setSearchParams } = useQueryString();
  const currentFilter = getPushFilter(getSearchParams('filter') ?? '', false);

  const { control } = useForm<PushFormType>({
    defaultValues: {
      filter: currentFilter,
    },
  });

  return (
    <>
      <StyledButton
        fullWidth
        variant="outlined"
        size="medium40"
        fontSize={14}
        fontWeight={500}
        textColor={'gray1'}
        rightIcon={<Icon name="arrowBottomBold" color="gray2" size={16} />}
        borderRadius={6}
        gap={4}
        outlineColor="gray5"
        onClick={() => setIsOpen(true)}
        isActive={isOpen}>
        {getPushFilterLabel(currentFilter)}
      </StyledButton>
      <CheckRadioDrawer
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        name="filter"
        data={Object.values(PUSH_FILTERS)}
        control={control}
        onClick={e => setSearchParams({ filter: e.currentTarget.value }, { replace: true })}
      />
    </>
  );
};

export default PushFilterButton;

const StyledButton = styled(Button)<{ isActive: boolean }>`
  margin: 16px 20px 0;
  width: calc(100% - 40px);
  padding: 8px 12px 8px 16px;
  justify-content: space-between;

  ${({ isActive }) => isActive && `svg { transform: rotate(180deg); }`}
`;
