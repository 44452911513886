import { useSuspenseQuery } from '@tanstack/react-query';
import axios from 'axios';
import { CustomAxiosError, ErrorBehavior } from 'sharedComponents/Boundaries/type';

import useQueryFnWithCommonParams from '../common/useQueryFnWithCommonParams';
import { UserTicketResponse } from './useInfinityUserTicketProduct';

const useGetUserTicket = ({ ticket_id, user_id, errorBehavior = 'navigate-back' }: UserTicketDetailParams) => {
  const { queryFn } = useQueryFnWithCommonParams<{ data: UserTicketResponse }, Pick<UserTicketDetailParams, 'user_id'>>({
    url: `/api/userTicket/${ticket_id}`,
    params: {
      user_id,
    },
  });

  return useSuspenseQuery({
    queryKey: ['user-ticket', `${ticket_id}`],
    queryFn: async () => {
      try {
        return await queryFn();
      } catch (error) {
        if (axios.isAxiosError(error)) {
          (error as CustomAxiosError).meta = { errorBehavior };
        }
        throw error;
      }
    },
    select: data => data.data.data,
  });
};

export default useGetUserTicket;

type UserTicketDetailParams = {
  ticket_id: number;
  user_id: number;
  errorBehavior?: ErrorBehavior | undefined;
};
