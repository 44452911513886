import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import dayjs from 'dayjs';
import { EVENT_CARD_TYPE } from 'pages/Schedule/ScheduleMain/constants';
import { StyledLectureCardType } from 'pages/Schedule/ScheduleMain/types';
import { useMemo } from 'react';
import { useRecoilValue } from 'recoil';
import { calendarSettingsAtom } from 'recoil/calendarSettings';
import { calendarViewTypeAtom } from 'recoil/schedule';
import { Color } from 'utils/getColor';
import getContrastTextColor from 'utils/getContrastTextColor';

import GroupTitle from './components/GroupTitle';
import PrivateTitle from './components/PrivateTitle';

type Props = {
  lectureType: 'G' | 'P';
  startTime?: string;
  endTime?: string;
  sameTimeEvents: number;
  title: string;
  currentTraineeCount: number;
  maxTrainee: number;
  cardColor: string;
  /**
   * 예약 마감 여부
   */
  isBookingClosed: boolean;
};

const EventLectureCard = ({ ...props }: Props) => {
  const { lectureType, startTime, endTime, sameTimeEvents, title, currentTraineeCount, maxTrainee, cardColor, isBookingClosed } =
    props;

  const calendarViewType = useRecoilValue(calendarViewTypeAtom);
  const { closedClassBackground } = useRecoilValue(calendarSettingsAtom);

  const isWeekView = useMemo(() => calendarViewType === 'week', [calendarViewType]);

  const traineeText = useMemo(() => {
    return `${currentTraineeCount}/${maxTrainee}`;
  }, [currentTraineeCount, maxTrainee]);

  /** 캘린더 설정 > 마감시 배경 채우기 설정 여부 */
  const isClosedBackground = useMemo(() => {
    const isIncludes = closedClassBackground.includes(lectureType);
    if (isIncludes) return true;
    return false;
  }, [lectureType, closedClassBackground]);

  const offsetWidth = (document.querySelector('.mbsc-schedule-event') as HTMLElement)?.offsetWidth;

  const currentTitle = useMemo(() => {
    const beforeTitle = !isWeekView && isBookingClosed ? `[예약마감] ${title}` : title;
    if (offsetWidth >= 40 || !isWeekView || sameTimeEvents !== 2) return beforeTitle;
    return beforeTitle.replaceAll('', '\n').trim();
  }, [offsetWidth, isWeekView, sameTimeEvents, title, isBookingClosed]);

  const isDifferenceTooSmall = useMemo(() => {
    const start = dayjs(startTime, 'H:mm');
    const end = dayjs(endTime, 'H:mm');
    return end.diff(start, 'minute') <= 10;
  }, [startTime, endTime]);

  if (!startTime || !endTime) return <></>;

  const isCardBackground = isClosedBackground && (isBookingClosed || currentTraineeCount === maxTrainee);

  const currentContrastColor = getContrastTextColor(cardColor) as Color;

  const commonProps = {
    isCardBackground,
    currentContrastColor,
    startTime,
    title: currentTitle,
    sameTimeEvents,
    isDayView: calendarViewType === 'day',
  };

  return (
    <Container
      isWeekView={isWeekView}
      isPrivateCard={lectureType === EVENT_CARD_TYPE.private}
      isCardBackground={isCardBackground}
      cardColor={cardColor}
      sameTimeEvents={sameTimeEvents}
      isDifferenceTooSmall={isDifferenceTooSmall}
      offsetWidth={offsetWidth}>
      {isDifferenceTooSmall ? (
        <></>
      ) : lectureType === EVENT_CARD_TYPE.group ? (
        <GroupTitle {...commonProps} traineeText={traineeText} />
      ) : (
        <PrivateTitle {...commonProps} />
      )}
    </Container>
  );
};

export default EventLectureCard;

const Container = styled.div<StyledLectureCardType & { isDifferenceTooSmall: boolean; offsetWidth: number }>(
  /** 기본 그룹/프라이빗 수업 카드 */
  ({ cardColor, isDifferenceTooSmall }) => css`
    ${theme.flex('column', 'flex-start', 'flex-start', 2)};
    ${!isDifferenceTooSmall && 'padding: 5px 8px 0'};
    height: 100%;
    border: 1.8px solid ${cardColor};
    border-radius: 4px;
    background-color: ${theme.color.white};
    overflow: hidden;

    .trainee-text,
    .title {
      min-width: max-content;
    }
  `,

  /** 주간(week) 수업 카드 */
  ({ isWeekView, isDifferenceTooSmall, offsetWidth }) =>
    isWeekView &&
    css`
      justify-content: flex-start;
      gap: 4px;
      margin-right: 3px;
      ${!isDifferenceTooSmall && 'padding: 4px 2px'};
      border-radius: 2px;

      .title-wrapper {
        position: relative;
        ${theme.flex('column', 'flex-start', 'flex-start', 4)};
        height: 100%;
      }

      .trainee-text {
        line-height: 12px;
      }

      .title {
        min-width: unset;
        white-space: ${offsetWidth < 40 ? 'pre-wrap' : 'normal'};
      }
    `,

  /** 캘린더 설정이 켜져있고, 예약 마감이거나 정원이 찼을 때 */
  ({ isCardBackground, cardColor }) =>
    isCardBackground &&
    css`
      background-color: ${cardColor};
    `,
);
