import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import Label from 'components/Label';
import Typography from 'components/Typography';
import { useMemo } from 'react';
import { ArrangeTypographyWrapper } from 'sharedComponents/StyledComponents';

import { USER_TICKET_STATUS } from './constants';
import { UserTicketCardType } from './types';

export type Props = {
  ticket: UserTicketCardType;
};

const UserTicketCard = ({ ticket }: Props) => {
  const {
    title,
    ticketStatus,
    isShared,
    isExperience,
    classType,
    convertTicketType,
    maxTrainee,
    limitText,
    remainingCoupon,
    usableCoupon,
    remainingCancel,
    dailyBookingChangeLimit,
    ticketColor,
    period,
    remainingDaysWithText,
  } = ticket;

  const showLabelColor = useMemo(
    () =>
      ticketStatus === 'expired' ||
      ticketStatus === 'holding' ||
      ticketStatus === 'not_usable' ||
      ticketStatus === 'refund' ||
      ticketStatus === 'transfer',
    [ticketStatus],
  );

  const labelTextColor = useMemo(() => {
    switch (ticketStatus) {
      case 'expired':
      case 'holding':
        return 'gray2';
      case 'not_usable':
      case 'refund':
      case 'transfer':
        return 'secondary3';
      default:
        return 'primary';
    }
  }, [ticketStatus]);

  const topInfo = [classType, convertTicketType, maxTrainee, limitText];
  const bottomInfo = [remainingCoupon, usableCoupon, remainingCancel, dailyBookingChangeLimit];

  return (
    <Container className="user-ticket-card" ticketColor={ticketColor} showLabelColor={showLabelColor}>
      <TopWrapper>
        <div className="left-info-wrapper">
          <Typography className="title" size={19} weight={700} textColor="white" ellipsis={2}>
            {title}
          </Typography>

          <TicketDetailInfoWrapper bgColor="white">
            {topInfo.map(
              item =>
                item && (
                  <Typography key={item} span size={13} weight={500} textColor="white" opacity={0.72}>
                    {item}
                  </Typography>
                ),
            )}
          </TicketDetailInfoWrapper>
        </div>
        <div className="label-wrapper">
          <Label className="status-label" size="regular" minWidth={48} color="white" textColor={labelTextColor}>
            {USER_TICKET_STATUS[ticketStatus]}
          </Label>
          {isShared && (
            <Label size="regular" minWidth={48} color="transparent" textColor="white" borderColor="white" padding={2}>
              다중예약
            </Label>
          )}
          {isExperience && (
            <Label size="regular" minWidth={48} color="transparent" textColor="white" borderColor="white" padding={2}>
              체험권
            </Label>
          )}
        </div>
      </TopWrapper>

      <BottomWrapper>
        <TicketDetailInfoWrapper className="bottom-info" bgColor="white">
          {bottomInfo.map(
            item =>
              item && (
                <Typography key={item} span weight={500} textColor="white">
                  {item}
                </Typography>
              ),
          )}
        </TicketDetailInfoWrapper>
        <div className="period-wrapper">
          <Typography size={13} textColor="white" opacity={0.72}>
            {period} {remainingDaysWithText && `(${remainingDaysWithText})`}
          </Typography>
        </div>
      </BottomWrapper>
    </Container>
  );
};

export default UserTicketCard;

const Container = styled.div<Pick<UserTicketCardType, 'ticketColor'> & { showLabelColor: boolean }>(
  ({ ticketColor }) => css`
    padding: 16px 20px 20px;
    border-radius: 12px;
    box-shadow: 8px 8px 16px 0px rgba(0, 0, 0, 0.16);
    background: linear-gradient(90deg, ${ticketColor[0]} 0%, ${ticketColor[1]} 100%);

    ${theme.flex('column', 'initial', 'space-between')};
    width: 311px;
    height: 188px;

    .status-label {
      margin-top: 2px;
    }
  `,

  ({ ticketColor, showLabelColor }) =>
    !showLabelColor &&
    css`
      .status-label {
        .typography {
          color: ${ticketColor[1]};
        }
      }
    `,
);

const TopWrapper = styled.div`
  ${theme.flex('', 'flex-start', 'space-between', 12)};

  .left-info-wrapper {
    flex: 1;

    .title {
      margin-bottom: 6px;
    }
  }

  .label-wrapper {
    ${theme.flex('column', '', '', 5)};
  }
`;

const BottomWrapper = styled.div`
  .period-wrapper {
    margin-top: 6px;
  }
`;

const TicketDetailInfoWrapper = styled(ArrangeTypographyWrapper)`
  &.bottom-info {
    .typography {
      &:before {
        top: 4px;
        margin-top: 0px;
      }
    }
  }
  .typography {
    &:before {
      opacity: 0.32;
    }
  }
`;
