import { useSetRecoilState } from 'recoil';
import { accessTokenAtom } from 'recoil/common';
import { postMessageToApp } from 'utils/communicateWithNative';
import localStorage from 'utils/localStorage';

import useGetAuthMeInstanceTokenCheck from './service/queries/useGetAuthMeInstanceTokenCheck';

const useInitializeAfterLogin = () => {
  const { data: fcmResponse } = useGetAuthMeInstanceTokenCheck();
  const setAccessToken = useSetRecoilState(accessTokenAtom);

  const initializeAfterLogin = (accessToken: string) => {
    /** 로컬스토리지에 직접 등록해야 다음 페이지에서 인식되어서 헷갈리지 않게 둘 다 등록 */
    localStorage.set('access_token', accessToken);
    setAccessToken(accessToken);

    postMessageToApp('REQUEST_NOTIFICATION_PERMISSION');
    if (!fcmResponse?.validate) {
      postMessageToApp('REQUEST_FCM_TOKEN');
    }
  };

  return { initializeAfterLogin };
};

export default useInitializeAfterLogin;
