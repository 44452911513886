import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import CheckBox from 'components/CheckBox';
import Form from 'components/Form';
import { REPEAT_CUSTOM_SETTING_WEEKDAY } from 'pages/Booking/constants';
import { BookingCommonFormType, RepeatSettingType } from 'pages/Booking/types';
import syncRepeatSetting from 'pages/Booking/utils/syncRepeatSetting';
import { useState } from 'react';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import FooterButton from 'sharedComponents/FooterButton';

import ChangeRepeatDialog from './ChangeRepeatDialog';

const CustomRepeatSetting = ({ showChangeDialog, initCustomArray, initRepeat }: RepeatSettingType) => {
  const { control, setValue, getValues } = useFormContext<BookingCommonFormType>();
  const tempRepeatCustomWeekday = useWatch({ control, name: 'tempRepeatCustomWeekday' });
  const disabled = !tempRepeatCustomWeekday.length;
  const [isOpenDialog, setIsOpenDialog] = useState(false);
  const isChangedRepeat = getValues('isChangedRepeat');

  const navigate = useNavigate();

  /**
   * 맞춤설정에서 체크한 요일과 반복설정 값이 동일하면 반복설정 값에 자동으로 체크
   * 예) 맞춤설정에서 월요일만 체크했다면 반복설정에 매주 월요일 자동 체크
   */
  const saveRepeat = () => {
    const value = syncRepeatSetting(tempRepeatCustomWeekday);
    setValue('repeat', value);
  };

  /** 월,화,수,목,금,토,일로 표시되도록 정렬 */
  const saveCustomWeekday = () => {
    const sortWeekday = tempRepeatCustomWeekday.sort((a, b) => {
      const aIndex = REPEAT_CUSTOM_SETTING_WEEKDAY.findIndex(({ id }) => id === a.id);
      const bIndex = REPEAT_CUSTOM_SETTING_WEEKDAY.findIndex(({ id }) => id === b.id);
      return aIndex - bIndex;
    });
    setValue('repeatCustomWeekday', sortWeekday, { shouldDirty: true });
  };

  const save = () => {
    saveRepeat();
    saveCustomWeekday();
    navigate(-2);
  };

  const clickSave = () => {
    if (showChangeDialog) {
      setIsOpenDialog(true);
    } else {
      save();
    }
  };

  return (
    <>
      <Form>
        <Container>
          {REPEAT_CUSTOM_SETTING_WEEKDAY.map(item => (
            <Controller
              key={item.id}
              control={control}
              name="tempRepeatCustomWeekday"
              render={({ field: { value, onChange } }) => {
                const checked = value.some(field => field.id === item.id);

                return (
                  <CheckBox
                    id={item.id}
                    label={item.label}
                    gap={12}
                    labelFontWeight={500}
                    checked={checked}
                    onChange={e => {
                      if (e.target.checked) {
                        onChange([...value, item]);
                      } else {
                        onChange([...value.filter(weekday => weekday.id !== item.id)]);
                      }
                    }}
                  />
                );
              }}
            />
          ))}
        </Container>
        <FooterButton onClick={clickSave} disabled={disabled}>
          완료
        </FooterButton>
      </Form>

      {!isChangedRepeat && isOpenDialog && (
        <ChangeRepeatDialog
          onClose={() => setIsOpenDialog(false)}
          initCustomArray={initCustomArray}
          initRepeat={initRepeat}
          onClick={save}
        />
      )}
    </>
  );
};

export default CustomRepeatSetting;

const Container = styled.div`
  padding: 16px 20px;
  ${theme.flex('column', 'flex-start', 'center', 24)}
`;
