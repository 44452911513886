import { useIsFetching } from '@tanstack/react-query';
import Header from 'components/Header';
import { useRecoilValue } from 'recoil';
import { studioIdAtom } from 'recoil/common';
import ApiBoundary from 'sharedComponents/Boundaries/ApiBoundary';
import FullScreenLoading from 'sharedComponents/FullScreenLoading';
import MainLayout from 'sharedComponents/MainLayout';
import StudioException from 'sharedComponents/StudioException';
import localStorage from 'utils/localStorage';

import EmptyList from './components/EmptyList';
import NotificationPage from './components/NotificationPage';
import { NOTIFICATION_TEXT } from './constants';

const Notification = () => {
  const header = {
    title: NOTIFICATION_TEXT.title,
    leftAlign: true,
    noUnderline: true,
  };

  const isFetching = useIsFetching() > 0;
  const isStudioSelected = useRecoilValue(studioIdAtom) !== 0 && !!localStorage.get('access_token');
  if (!isStudioSelected) {
    return (
      <ApiBoundary>
        <StudioException
          noConnectedBackground={
            <>
              <Header {...header} />
              <EmptyList />
            </>
          }
        />
      </ApiBoundary>
    );
  }
  return (
    <MainLayout bottomNav header={header}>
      <ApiBoundary fallback={<FullScreenLoading loop={isFetching} />}>
        <NotificationPage />
      </ApiBoundary>
    </MainLayout>
  );
};

export default Notification;
