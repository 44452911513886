import { PERMISSION } from 'constants/permission';
import useDeleteMessagePush from 'hooks/service/mutations/useDeleteMessagePush';
import usePostMessagePushCancel from 'hooks/service/mutations/usePostMessagePushCancel';
import usePopup from 'hooks/usePopup';
import MessageMenuButton from 'pages/MoreDetails/Message/components/MessageMenuButton';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import { AppPushDetailType } from '../types';
import { convertAppPushFormData } from '../utils';
import AppPushTargetLoading from './AppPushTargetLoading';

type Props = {
  push: AppPushDetailType;
};

const AppPushMenuButton = ({ push }: Props) => {
  /**
   * 즉시 발송, 예약취소 앱푸시는 삭제만 가능
   * 예약 앱푸시는 수정, 취소 가능
   */
  const isDeletable = push.status === 'sended' || push.status === 'canceled';
  const permissionNeeded = isDeletable ? PERMISSION.message.push.delete : PERMISSION.message.push.edit;

  const { mutate: deleteMutate } = useDeleteMessagePush(push.id);
  const { mutate: cancelMutate } = usePostMessagePushCancel(push.id);

  const navigate = useNavigate();
  const { setPopup } = usePopup();
  const editMessage = useCallback(() => {
    setPopup(
      <AppPushTargetLoading
        id={push.id}
        onLoaded={targets => {
          navigate(`/more-details/message/push/form`, { state: convertAppPushFormData(push, targets) });
          setPopup(null);
        }}
      />,
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [push]);

  return (
    <MessageMenuButton
      isDeletable={isDeletable}
      permissionNeeded={permissionNeeded}
      deleteMutate={deleteMutate}
      cancelMutate={cancelMutate}
      editMessage={editMessage}
      type="push"
      message={{ count: push.all_count, sendOn: push.send_on }}
    />
  );
};

export default AppPushMenuButton;
