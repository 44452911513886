export const CALENDAR_VIEW_TYPES = [
  { value: 'week', title: '주간' },
  { value: 'day', title: '일간' },
  { value: 'month', title: '월간' },
  { value: 'list', title: '목록' },
];

export const EVENT_CARD_TYPE = {
  group: 'G',
  private: 'P',
  counsel: 'C',
  etcSchedule: 'E',
  allDayEtcSchedule: 'AE',
};

export const TOOLTIP_MESSAGE = {
  filterSettings: ['캘린더 목록 형태가', '추가되었어요!'],
};
