import { useQueryClient } from '@tanstack/react-query';
import axios, { isAxiosError } from 'axios';
import useErrorDialog from 'hooks/useErrorDialog';
import { useEffect } from 'react';
import { type FallbackProps } from 'react-error-boundary';
import { useNavigate } from 'react-router-dom';
import { recordErrorWithSentry } from 'utils/sentry';

import { CustomAxiosError } from '../type';

const ApiErrorFallback = ({ error, resetErrorBoundary }: FallbackProps) => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const errorDialog = useErrorDialog();

  if (!axios.isAxiosError(error)) {
    throw error;
  }
  const customError = error as CustomAxiosError;

  useEffect(() => {
    const getNavigationBehavior = () => {
      if (customError.meta?.errorBehavior === 'stay') {
        return undefined;
      }
      return () => navigate(-1);
    };

    if (error) {
      recordErrorWithSentry(error);

      const isErrorDrawerCase =
        isAxiosError(error) &&
        (error?.message === 'Network Error' || error?.response?.status === 401 || error?.response?.status === 403);

      if (isErrorDrawerCase) {
        const type = error.message === 'Network Error' ? 'not-connected' : error.response?.status;
        navigate(`/error?type=${type}`, { replace: true });
        queryClient.clear(); // 에러 발생 후 기존 쿼리 재호출 방지
      } else if (error?.response?.status === 400 || error?.response?.status === 422) {
        errorDialog.open(error);
      } else {
        const buttonText = customError.meta?.errorBehavior === 'stay' ? '확인' : '이전 화면으로';

        errorDialog.open(error, getNavigationBehavior(), buttonText);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customError.meta?.errorBehavior, error, navigate, queryClient, resetErrorBoundary]);

  return null;
};

export default ApiErrorFallback;
