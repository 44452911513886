import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { color, theme } from 'assets/styles';

const Divider = styled.hr<{
  thin?: boolean;
  thinColor?: keyof typeof color;
}>`
  ${({ thin, thinColor }) =>
    thin
      ? css`
          height: 1px;
          background-color: ${thinColor ? theme.color[thinColor] : theme.color.gray7};
        `
      : css`
          height: 8px;
          background-color: ${theme.color.gray7};
          border-top: 1px solid ${theme.color.gray5};
        `}
`;

export default Divider;
