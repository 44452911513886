import dayjs from 'dayjs';
import isoWeek from 'dayjs/plugin/isoWeek';
import weekday from 'dayjs/plugin/weekday';
import filters from 'utils/filters';

import { PeriodLabel, ReportCommonType, SaleCardTotalParam } from './type';

dayjs.extend(isoWeek);
dayjs.extend(weekday);

export const getWeekOfMonth = (date: dayjs.Dayjs) => {
  // 날짜 객체 생성
  const inputDate = dayjs(date);

  // 기준 날짜를 기준으로 주의 마지막날짜 구하기
  const endOfWeek = inputDate.add(6, 'days');

  // 주차 계산 (달의 첫 번째 날을 기준으로)
  return Math.ceil(endOfWeek.date() / 7);
};

export const getLabel = ({ date, type }: PeriodLabel) => {
  const startDate = dayjs(date?.[0]);
  const endDate = dayjs(date?.[1]);
  const currentDateYearMonth = filters.dateYearMonthKor(startDate);

  if (type === 'isoWeek') {
    const weekOfMonth = getWeekOfMonth(startDate);
    return {
      dateKor: `${filters.dateYearMonthKorCompact(endDate)} ${weekOfMonth}주`,
      dateNumber: `(${filters.dateMonthDay(startDate)} ~ ${filters.dateMonthDay(endDate)})`,
    };
  }
  if (type === 'month') {
    return currentDateYearMonth;
  }
  if (type === 'custom') {
    return `${filters.date(date?.[0])} ~ ${filters.date(date?.[1])}`;
  }

  return filters.dateYearMonthDayKor(date?.[0]);
};

// 버튼 라벨 생성
export const getCalendarButtonLabel = ({ date, type }: PeriodLabel) => {
  const startDate = dayjs(date?.[0]);
  const endDate = dayjs(date?.[1]);

  if (type === 'isoWeek') {
    return `${filters.date(startDate)} ~ ${filters.date(endDate)}`;
  }
  if (type === 'month') {
    return filters.dateYearMonth(startDate);
  }

  return filters.date(date?.[0]);
};

// 데이터를 날짜로 그룹핑
export const getGroupByDate = <T extends ReportCommonType>(datas: T[], dateKey: keyof T): T[][] => {
  const newDatas: T[][] = [];

  datas.forEach(data => {
    const startDate = (data[dateKey] as string).split(' ')[0];
    const findDuplicateIndex = newDatas.findIndex(newData => (newData[0][dateKey] as string).split(' ')[0] === startDate);
    if (findDuplicateIndex === -1) {
      newDatas.push([data]);
    } else {
      newDatas[findDuplicateIndex].push(data);
    }
  });

  return newDatas;
};

// 포이인트 탭 분류 라벨
export const getPointStatusLabel = (status: string): string => {
  switch (status) {
    case 'change':
      return '변경';
    case 'refund':
      return '환불';
    case 'upgrade':
      return '업그레이드';
    case 'transfer':
      return '양도';
    case 'installment_payment':
      return '미수금';
    default:
      return '발급';
  }
};

// 결제수단에 따른 total 금액 구분
export const getTotalPrice = ({ label, payment }: SaleCardTotalParam): string => {
  if (label === 'commission') {
    return `${filters.numberComma(payment.amount)}원`;
  }
  return `${filters.numberComma(payment.commission_amount)}원`;
};

/**
 * 객체에서 필터링 대상이 되는 키들을 선택하여 유효한 값을 가진 파라미터만 필터링합니다.
 * @param params 필터링할 객체
 * @param includeKeys 필터링 대상 키 배열 (기본값: 빈 배열)
 * @returns 유효한 값을 가진 파라미터 객체
 */
export const filterDefinedParams = <T extends object>(params: T, includeKeys: string[] = []): Partial<T> => {
  return Object.fromEntries(
    Object.entries(params).filter(([key, value]) => {
      // includeKeys가 비어있으면 모든 키 대상
      const shouldInclude = includeKeys.length === 0 || includeKeys.includes(key);

      return (
        shouldInclude &&
        value !== undefined &&
        value !== null &&
        // 배열이면 길이 확인
        ((Array.isArray(value) && value.length > 0) ||
          // 배열 아니면 항상 true
          !Array.isArray(value))
      );
    }),
  ) as Partial<T>;
};
