import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { color, theme } from 'assets/styles';
import Avatar from 'components/Avatar';
import Button from 'components/Button';
import Icon from 'components/Icon';
import Typography from 'components/Typography';
import { PERMISSION } from 'constants/permission';
import { LectureBookingListResponse } from 'hooks/service/queries/useInfinityBookingList';
import usePermission from 'hooks/usePermission';
import { BOOKING_STATUS_LABEL } from 'pages/Booking/constants';
import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { ArrangeTypographyWrapper } from 'sharedComponents/StyledComponents';
import { ColorType } from 'types/colorTypes';
import filters from 'utils/filters';
import getDaysDiff from 'utils/getDaysDiff';
import getEllipsisByLength from 'utils/getEllipsisByLength';
import getImageUrl from 'utils/getImageUrl';
import getRemainingDaysText from 'utils/getRemainingDaysText';
import getTicketInfoByType from 'utils/getTicketInfoByType';

type Props = {
  booking: LectureBookingListResponse;
  waitingOrder?: number;
  canCancelBooking: boolean;
  canChangeBooking: boolean;
  onClickStatusChange: (booking: LectureBookingListResponse) => void;
};

const MemberCard = ({ booking, waitingOrder, canCancelBooking, canChangeBooking, onClickStatusChange }: Props) => {
  const { user_ticket, member, status, is_assigned } = booking;
  const ticketTitle = getEllipsisByLength({
    text: user_ticket.ticket.title,
    max: 12,
  });
  const { showCoupon, showPeriod } = getTicketInfoByType(booking.user_ticket.ticket.type);
  const daysRemainingText = getRemainingDaysText(user_ticket.expire_at, filters.dateDash(), 'expired');
  const hasRemainingDays = getDaysDiff(user_ticket.expire_at, filters.dateDash()) >= 0;
  const disabled =
    (!canCancelBooking && !canChangeBooking) || (!canCancelBooking && canChangeBooking && booking.status === 'booked');

  const { hasPermission } = usePermission();
  const navigate = useNavigate();
  const goToMemberDetail = () => {
    if (!is_assigned && !hasPermission(PERMISSION.member.view)) return;
    navigate(`/member/detail/${member.id}`);
  };

  const text = useMemo(() => {
    if (hasRemainingDays && !!user_ticket.remaining_coupon) {
      return showCoupon ? `${user_ticket.usable_coupon}회ㆍ${daysRemainingText}` : daysRemainingText;
    } else if (!user_ticket.remaining_coupon) {
      return '횟수 만료';
    } else {
      return '기간 만료';
    }
  }, [daysRemainingText, hasRemainingDays, showCoupon, user_ticket.remaining_coupon, user_ticket.usable_coupon]);

  return (
    <Container>
      <div onClick={goToMemberDetail}>
        <Avatar size={32} imgUrl={getImageUrl(member.image)} />
      </div>
      <div className="info-wrapper">
        <div className="name-wrapper" onClick={goToMemberDetail}>
          <Typography className="name" size={15} weight={600} ellipsis={1}>
            {member.name}
          </Typography>
          {member.mobile && (
            <Typography className="mobile" textColor="gray2">
              {filters.mobile(member.mobile)}
            </Typography>
          )}
        </div>
        <TicketInfoWrapper>
          <Typography span size={13} textColor="gray2" opacity={0.72}>
            {ticketTitle}
          </Typography>
          {(showCoupon || showPeriod) && (
            <Typography span size={13} textColor="gray2" opacity={0.72}>
              {text}
            </Typography>
          )}
        </TicketInfoWrapper>
      </div>
      <StyledButton
        className="status-change-button"
        variant="outlined"
        textColor={BOOKING_STATUS_LABEL[status].color as keyof typeof color}
        heightSize={26}
        borderRadius={4}
        padding={{ left: 6, right: 4 }}
        fontSize={12}
        fontWeight={600}
        disabled={disabled}
        rightIcon={disabled ? undefined : <Icon name="arrowBottomBold" size={16} color="gray3" />}
        onClick={() => onClickStatusChange(booking)}>
        {BOOKING_STATUS_LABEL[status].text} {waitingOrder ? `${waitingOrder}` : ''}
      </StyledButton>
    </Container>
  );
};

export default MemberCard;

const StyledButton = styled(Button)(
  ({ textColor }) => css`
    min-width: 68px;
    position: absolute;
    margin-top: 3px;
    top: 15px;
    right: 0;
    justify-content: space-between;

    &:disabled {
      border-color: ${theme.color.gray5};
      justify-content: center;
      padding-left: 4px;

      &:active {
        background-color: transparent;
      }

      .typography {
        color: ${theme.color[(textColor as ColorType) || 'primary']};
      }
    }
  `,
);

const Container = styled.div`
  ${theme.flex('', '', 'flex-start', 10)};
  padding: 15px 0;
  position: relative;

  .info-wrapper {
    flex: 1;
    padding-right: 74px;
  }

  .avatar {
    flex: 0 0 auto;
    margin-top: 2px;
  }

  .name {
    max-width: 205px;
  }
`;

const TicketInfoWrapper = styled(ArrangeTypographyWrapper)`
  margin-top: 2px;
  ${theme.textEllipsis(1)};

  .typography:before {
    top: 3px;
    margin-top: 0;
  }
`;
