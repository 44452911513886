import { NOTICE_TEXT } from '../constants';

export const NOTICE_FORM_TEXT = {
  endDate: '게시 종료일',
  titlePlaceholder: '제목 입력',
  contentsPlaceholder: '내용 입력',
  addImage: '사진 추가',
  imageDescription: '최대 3장 (jpg, jpeg, png)',
  preview: '미리보기',
  previewDescription: '미리보기와 실제 화면은 기기마다 다를 수 있습니다.',
  previewLeft: '다시보지 않기',
  previewRight: '닫기',
  noTargetError: '공지대상을 선택해 주세요.',
  noLimitedPeriodError: '게시기간을 선택해 주세요.',
  endOnError: ['게시 종료일을 다시 선택해 주세요.', '오늘보다 과거 날짜는 선택할 수 없습니다.'],
  previewTitleDescription: '새로운 공지가 등록되었어요.',
  ok: '확인',
};

export const NOTICE_TYPE_TEXT = {
  edit: {
    title: '공지사항 수정',
    submit: '수정 완료',
    successMessage: '공지사항이 수정되었습니다.',
  },
  create: {
    title: '공지사항 등록',
    submit: '등록',
    successMessage: '공지사항이 등록되었습니다.',
  },
};

export const PERIOD_RADIO_OPTIONS = [
  { value: 'limited', label: NOTICE_TEXT.periodLimited },
  { value: 'unset', label: NOTICE_TEXT.periodNone },
  { value: 'unlimited', label: NOTICE_TEXT.periodUnlimited },
];
