import { BOOKING_STATUS_CHANGE_PERMISSION } from 'pages/Booking/Detail/constants';
import { BookingType } from 'pages/Booking/types';
import { useRecoilValue } from 'recoil';
import { staffIdAtom } from 'recoil/common';

import usePermission from './usePermission';

export type BookingDetailPermissionKeyType = 'view' | 'create' | 'update' | 'change' | 'cancel' | 'delete';

/**
 * 수업 상세 페이지에서 강사별 수업 수정/삭제 및 예약 상태 변경 권한 체크
 * @param lectureStaffId 수업하는 강사 아이디
 */
const useCheckPermissionBookingDetail = (lectureStaffId: number, bookingType: BookingType) => {
  const currentStaffId = useRecoilValue(staffIdAtom);

  const { hasPermission } = usePermission();

  const initialValue: Partial<Record<'view', boolean>> & Record<BookingDetailPermissionKeyType, boolean> = {
    view: true,
    create: false,
    update: false,
    change: false,
    cancel: false,
    delete: false,
  };

  const staffType = currentStaffId === lectureStaffId ? 'mine' : 'others';

  const {
    view: canViewLecture,
    create: canCreateLecture,
    update: canUpdateLecture,
    change: canChangeBooking,
    cancel: canCancelBooking,
    delete: canDeleteLecture,
  } = Object.entries(BOOKING_STATUS_CHANGE_PERMISSION[staffType][bookingType]).reduce((acc, [key, value]) => {
    acc[key as BookingDetailPermissionKeyType] = hasPermission(value);
    return acc;
  }, initialValue);

  return { canViewLecture, canCreateLecture, canUpdateLecture, canChangeBooking, canCancelBooking, canDeleteLecture };
};

export default useCheckPermissionBookingDetail;
