import styled from '@emotion/styled';
import { theme } from 'assets/styles';
import Form from 'components/Form';
import useQueryString from 'hooks/useQueryString';
import { ComponentProps } from 'react';
import { useRecoilValue } from 'recoil';
import { isKeyboardShowAtom } from 'recoil/keyboard';
import ApiBoundary from 'sharedComponents/Boundaries/ApiBoundary';
import FormFieldLinkButton from 'sharedComponents/FormField/FormFieldLinkButton';
import { Birthday, Gender, Mobile, Profile, RegisteredDate } from 'sharedComponents/ProfileField';

import Tickets from '../components/Tickets';
import UserGrade from '../components/UserGrade';
import Vaccination from '../components/Vaccination';
import { MEMBER_FORM_TEXT } from '../constants';
import Address from './Address';
import SubmitButton from './SubmitButton';
import UserTickets from './UserTickets';

type Props = Omit<ComponentProps<typeof Form>, 'children'> & {
  isUserGrade: boolean;
  canViewMemberMobileNumber?: boolean;
  pageMode: 'create' | 'update';
  isUploadingImage: boolean;
};

const CommonMemberForm = ({ pageMode, isUserGrade, canViewMemberMobileNumber, isUploadingImage, ...props }: Props) => {
  const { setSearchParams } = useQueryString();
  const isKeyboardShow = useRecoilValue(isKeyboardShowAtom);

  return (
    <MemberFormContainer {...props} isKeyboardShow={isKeyboardShow}>
      <Profile onClick={() => setSearchParams({ type: 'profile' })} />
      <Mobile canViewMemberMobileNumber={canViewMemberMobileNumber} desc={MEMBER_FORM_TEXT.mobile.description} />
      <RegisteredDate />
      {isUserGrade && <UserGrade />}
      <Gender />
      <Birthday />
      <Address />
      <ApiBoundary
        fallback={
          <FormFieldLinkButton iconName="ticket" disabled>
            수강권/상품
          </FormFieldLinkButton>
        }>
        <Tickets />
      </ApiBoundary>
      {pageMode === 'update' && (
        <ApiBoundary>
          <UserTickets />
        </ApiBoundary>
      )}
      <Vaccination />
      <SubmitButton pageMode={pageMode} disabled={isUploadingImage} />
    </MemberFormContainer>
  );
};

export default CommonMemberForm;

export const MemberFormContainer = styled(Form)<{ isKeyboardShow: boolean }>`
  width: 100%;
  height: 100%;
  overflow-y: auto;
  padding-bottom: ${({ isKeyboardShow }) => (isKeyboardShow ? '0px' : '116px')};

  .accordion {
    border-bottom: 1px solid ${theme.color.gray8};
  }

  .switch {
    border-top: 1px solid ${theme.color.gray8};
    width: 100%;
    padding: 0 20px;
    height: 56px;

    .switch-contents-wrapper {
      height: 100%;
    }
  }

  .ticket-list {
    padding-top: 8px;
    padding-bottom: 4px;

    + .switch {
      margin-top: 16px;
    }
  }

  footer {
    margin-top: ${({ isKeyboardShow }) => (isKeyboardShow ? '40px' : '0')};
  }
`;
