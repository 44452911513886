import useCustomMutation from '../common/useCustomMutation';
import useMutationFnWithCommonParams from '../common/useMutationFnWithCommonParams';

const usePostLogout = () => {
  const { mutationFn } = useMutationFnWithCommonParams({
    method: `post`,
    url: `/api/auth/logout`,
  });

  return useCustomMutation({
    mutationFn,
  });
};

export default usePostLogout;
