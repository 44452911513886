import { keepPreviousData, useQuery } from '@tanstack/react-query';

import useQueryFnWithCommonParams from '../common/useQueryFnWithCommonParams';

const useGetReportSaleDashboard = (params: ReportSaleDashboardParams) => {
  const { queryFn } = useQueryFnWithCommonParams<ReportSaleDashboardResponse, ReportSaleDashboardParams>({
    url: `/api/report/sale/dashboard`,
    params: { ...params },
  });

  return useQuery({
    queryKey: [`report/sale/dashboard`, { ...params }],
    queryFn,
    select: data => data.data,
    placeholderData: keepPreviousData,
  });
};

export default useGetReportSaleDashboard;

type SaleInfo = {
  /** 횟수 */
  count: number;
  /** 금액 */
  amount: number;
  /** 수수료 */
  commission_amount: number;
};

export type SaleType = {
  /** 프라이빗 */
  P: SaleInfo;
  /** 그룹 */
  G: SaleInfo;
  /** 판매 */
  S: SaleInfo;
  /** 렌탈 */
  R: SaleInfo;
};

export type ReportSaleDashboardParams = {
  /** 검색 시작일 */
  start_date: string;
  /** 검색 종료일 */
  end_date: string;
};

export type ReportSaleDashboardResponse = {
  /** 매출 */
  sale: SaleType;

  /** 환불 */
  refund: SaleType;
};
